// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #fff;
  border: 3px solid #000;
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/app/section-header/section-header.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,4BAA4B;AAC9B","sourcesContent":[".circle {\n  border-radius: 50%;\n  width: 34px;\n  height: 34px;\n  padding: 10px;\n  background: #fff;\n  border: 3px solid #000;\n  color: #000;\n  text-align: center;\n  font: 32px Arial, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
