import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { IOrderList, ITicketList } from './saved.service';
import { TimeRangeService } from './time-range.service';
// import { IDropDownLink, IDropDownStyle } from '@pjd-development/pjd-dsc-lib';

@Injectable({
  providedIn: 'root'
})
export class HeaderBarService {

  /**
   *
   */
  dropDownLinksThree: unknown; // IDropDownLink;

  /**
   *
   */
  dropDownStylesThree: unknown; // IDropDownStyle;

  /**
   * for orders or schedule
   */
  savedTicketList: ITicketList | IOrderList;


  /**
   *
   */
  beginEdit: () => void;

  /**
   *
   */
  beginCreate: () => void;

  /**
   *
   */
  hidden = true;

  hideEditFilters = false;

  _isOrders = false;

  title: unknown;

  /**
   * @ignore
   */
  private _beginCreateReport;

  /**
   * @ignore
   */
  private _beginCreateGraph: unknown;

  private _beginCreateSchedule;

  /**
   * @ignore
   */
  private _content;

  /**
   * @ignore
   * for schedules and orders (via edit schedule modal)
   */
  private listCb;

  /**
   * @ignore
   */
  private _showCreate;

  private _tl;

  /**
   * @ignore
   */
  constructor(
    private time: TimeRangeService,
    private _zone: NgZone,
    private router: Router,
  ) {
  }

  /**
   *
   */
  get currentTicketList() {
    return this._tl;
  }

  /**
   *
   */
  get showCreate() {
    return this._showCreate;
  }

  /**
   *
   */
  get content() {
    return this._content;
  }

  get startDate() {
    const t = _.get(this.currentTicketList, 'timeRange');

    if (!t) {
      return 0;
    }

    const ts = this.time.computeTimestamp(t);

    return ts && ts[0];
  }

  get beginCreateReport() {
    return this._beginCreateReport;
  }

  get beginCreateGraph() {
    return this._beginCreateGraph;
  }

  get beginCreateSchedule() {
    return this._beginCreateSchedule;
  }

  set beginCreateSchedule(x) {
    if (x) {
      //     this.clearAll();
    }
    this._beginCreateSchedule = x;
  }

  set beginCreateGraph(x) {
    if (x) {
      this.clearAll();
    }
    this._beginCreateGraph = x;
  }

  set beginCreateReport(x) {
    if (x) {
      this.clearAll();
    }
    this._beginCreateReport = x;
  }

  set currentTicketList(tl) {
    this._tl = tl;
    this.content = {};
  }

  /**
   *
   */
  set content(c) {
    if (c) {
      this.clearAll();
      this._content = c;

      // NOTE: prevent changed after checked
      _.defer(() => {
        this.hidden = false;
      });
    }
  }

  /**
   *
   */
  set showCreate(b) {
    if (b) {
      this.clearAll();
    }
    this._showCreate = b;
  }

  /**
   * handles close button for summary report and order detail pages.
   */
  handleCloseNav() {
    if (this.savedTicketList) {
      if (this._isOrderList(this.savedTicketList)) {
        // fall through
      } else {
        return this._zone.run(() => {
          this.router.navigate(['/schedules/standard']);
        });
      }
    }

    return this._zone.run(() => {
      this.router.navigate(['/orders/standard']);
    });
  }

  /**
   * Removes all header bars.
   */
  clearAll(saveState?: boolean) {
    if (saveState) {
      this.savedTicketList = this.currentTicketList;
    } else {
      this.savedTicketList = null;
    }

    this.hidden = true;
    this.dropDownStylesThree = null;
    this.showCreate = false;
    this.beginCreateReport = null;
    this.hideEditFilters = false;
    this._beginCreateGraph = null;
    this.title = null;
  }

  /**
   *
   */
  clearListCallback() {
    this.listCb = _.noop;
  }

  /**
   *
   */
  notifyListCallback() {
    this.listCb();
  }

  /**
   *
   */
  registerListCallback(cb: (...args: unknown[]) => unknown) {
    // NOTE: prevent double triggers
    this.listCb = _.debounce(cb, 150);
  }

  /**
   * returns the saved ticket list, if any
   */
  popSavedTicketList() {
    const saved = this.savedTicketList;

    if (saved) {
      this.savedTicketList = null;
      this.currentTicketList = saved;
    }

    return saved;
  }

  /**
   * @ignore
   */
  private _isOrderList(_tl: unknown) {
    return this._isOrders;
  }
}
