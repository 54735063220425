import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatetimeQuestion } from '@pjd-development/pjd-dsc-lib';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent {
  @Input() form: FormGroup;
  @Input() datetimeQuestion: DatetimeQuestion;

  get isInvalid() {
    const c = this.control;

    return !c.valid && (c.dirty || c.touched);
  }

  get control() {
    return this.form.controls[this.datetimeQuestion.key];
  }
}
