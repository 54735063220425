import { Injectable, isDevMode } from '@angular/core';
import * as _ from 'lodash-es';
import { environment } from '../environments/environment';
import { BackendService } from './backend.service';

/**
 * @ignore
 */
const root = () => isDevMode() ? '' : environment.backendRoot;

/**
 * @ignore
 */
const pdfsEndpoint = () => root() + '/api/pdfs';

/**
 * @ignore
 */
const picturesEndpoint = () => root() + '/api/pictures';

/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class MediaService {

  /**
   * @ignore
   */
  constructor(
    private backend: BackendService,
  ) {
  }

  /**
   *
   */
  async findPdf(plant: string, ticketNumber: string) {
    const data = this._makeParams(plant, ticketNumber);

    return this.backend.genericGet(pdfsEndpoint(), data) as Promise<string>;
  }

  /**
   *
   */
  async findPictures(plant: string, ticketNumber: string) {
    const data = this._makeParams(plant, ticketNumber);

    return this.backend.genericGet(picturesEndpoint(), data) as Promise<string>;
  }

  /**
   * converts base64 string into a blob
   */
  async b64toBlob(base64: string, type = 'application/octet-stream') {
    const res = await fetch(`data:${type};base64,${base64}`);

    return await res.blob();
  }

  /**
   * @ignore
   */
  private _makeParams(p: string, tn: string) {
    const data = {
      prefix: `${p}_${tn}`,
    };

    return data;
  }
}
