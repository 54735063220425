import { Injectable } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BackendService } from './backend.service';
import { UserService } from './user.service';

import _ from 'lodash-es';
import { IOption } from '@pjd-development/pjd-dsc-lib';

const root = '';
const adminEndpoint = root + '/api/admin';
const adminSetUserPassword = root + '/api/adminSetUserPassword';
const adminListUserAuthEvents = root + '/api/adminListUserAuthEvents';

interface IUserData {
  Attributes: { Name: string, Value: string }[];
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private backend: BackendService,
    private user: UserService,
  ) { }

  /**
   * resets user password
   */
  async adminSetUserPassword(cognitoUser: CognitoUser, email: string, password: string) {
    const token = await this.user.getAccessToken(cognitoUser);

    return await this.backend.genericPost(adminSetUserPassword, {
      email,
      password,
      /* eslint-disable  @typescript-eslint/naming-convention */
      AccessToken: token
      /* eslint-enable  @typescript-eslint/naming-convention */
    }, true);
  }

  /**
   * create a new user
   */
  create(cognitoUser: CognitoUser, json: Record<string, unknown>) {
    return this._createOrUpdate(cognitoUser, this.backend.genericPost, json);
  }

  /**
   * returns last login history
   */
  async getUserAuthEvents(cognitoUser: CognitoUser, email: string) {
    const token = await this.user.getAccessToken(cognitoUser);
    const x = await this.backend.genericPost(adminListUserAuthEvents, {
      email,
      /* eslint-disable  @typescript-eslint/naming-convention */
      AccessToken: token
      /* eslint-enable  @typescript-eslint/naming-convention */
    }, true);
    const ae = _.get(x, 'AuthEvents');

    return ae;
  }

  /**
   * get list of all users
   */
  async list(cognitoUser: CognitoUser, pageToken?: Record<string, string>) {
    const params = pageToken || {} as Record<string, string>;
    const token = await this.user.getAccessToken(cognitoUser);

    params.AccessToken = token;

    try {
      let x = await this.backend.genericGet(adminEndpoint,
        params, true) as {
          PaginationToken?: string;
          Users?: IUserData[];
          _token: Record<string, string>;
          users: IUserData[];
        };
      const pt = _.get(x, 'PaginationToken');
      const users = _.get(x, 'Users');
      const _token = {
        /* eslint-disable  @typescript-eslint/naming-convention */
        PaginationToken: encodeURIComponent(pt),
        AccessToken: token,
        /* eslint-enable  @typescript-eslint/naming-convention */
      };

      x = {
        users,
        _token,
      };

      _.each(x.users, (z) => {
        const zz = z as unknown as Record<string, string>;
        const za = z as unknown as Record<string, IOption[]>;

        _.each(z.Attributes, y => {
          const _name = y.Name;
          const value = y.Value;
          const name = _.last(_name.split(':'));

          z[name] = value;
        });
        if (zz.admin === 'true') {
          za.jobs = [];
          za.companies = [];
          zz.viewOrders = 'Yes';
        } else {
          if (zz.admin === 'lindy') {
            zz.viewOrders = zz.view_orders === 'true' ? 'Yes' : 'No';
          } else {
            zz.viewOrders = 'No';
          }
          za.jobs = zz.jobs ? this.user._unpack(zz.jobs) : [];
          za.companies = zz.companies ? this.user._unpack(zz.companies) : [];
        }
        zz.name = zz.given_name + ' ' + zz.family_name;
        zz.account = _.toUpper(this._lookUpAccountType(zz.admin)) + '\n'
          + zz.email;
      });

      return x as Record<string, unknown>;
    } catch (_e) {

    }
  }

  /**
   * remove user by email
   */
  async remove(cognitoUser: CognitoUser, email: string) {
    const token = await this.user.getAccessToken(cognitoUser);

    return await this.backend.genericDelete(adminEndpoint, {
      email,
      /* eslint-disable  @typescript-eslint/naming-convention */
      AccessToken: token
      /* eslint-enable  @typescript-eslint/naming-convention */
    }, true);
  }

  /**
   * update existing user
   */
  update(cognitoUser: CognitoUser, json: Record<string, unknown>) {
    return this._createOrUpdate(cognitoUser, this.backend.genericPut, json);
  }

  /**
   * @ignore
   */
  private async _createOrUpdate(
    cognitoUser: CognitoUser,
    method: (a: string, b: Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>) => Promise<unknown>,
    json: Record<string, unknown>,
  ) {
    const token = await this.user.getAccessToken(cognitoUser);
    const payload = this._preparePayload(token, json);
    const res = await method.call(this.backend, adminEndpoint, payload, true);

    try {
      if (_.isString(json.password) && _.isString(json.email)) {
        await this.adminSetUserPassword(cognitoUser, json.email, json.password);
      }
    } catch (_e) {

    }

    return res;
  }

  /**
   * @ignore
   */
  private _lookUpAccountType(t: string) {
    const types = {
      true: 'Admin',
      false: 'External',
      lindy: 'Lindy',
    };

    return types[t];
  }

  /**
   * @ignore
   */
  private _pack(a: Array<Record<string, string>>) {
    const b = _.map(a, x => x.value);

    return _.join(b);
  }

  /**
   * @ignore
   */
  private _preparePayload(token: string, json: Record<string, unknown>) {
    const companies = _.isArray(json.companies) && this._pack(json.companies);
    const jobs = _.isArray(json.jobs) && this._pack(json.jobs);
    const payload = json as Record<string, string>;

    payload.AccessToken = token;
    payload.companies = companies || ',';
    payload.jobs = jobs || ',';

    return payload;
  }
}
