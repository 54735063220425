import {
  HttpClient, HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { UserService } from './user.service';

export type BackendParams = Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>;

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
    private http: HttpClient,
    private user: UserService,
    private _router: Router,
  ) { }

  /**
   *
   */
  async genericGet(endpoint: string, _params: Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>, wrapMeta?: boolean) {
    const params = new HttpParams({ fromObject: _params });
    const headers = await this._generateHeaders();
    const obs = this.http.get(endpoint, { params, headers });

    return this._toPromise(wrapMeta ? obs.pipe(map(x => ({ data: x }))) : obs);
  }

  /**
   *
   */
  async genericPut(endpoint: string, params: Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>, wrapMeta?: boolean) {
    const headers = await this._generateHeaders();
    const obs = this.http.put(endpoint, params, { headers });

    return this._toPromise(wrapMeta ? obs.pipe(map(x => ({ data: x }))) : obs);
  }

  /**
   *
   */
  async genericDelete(endpoint: string, _params: Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>, wrapMeta?: boolean) {
    const params = new HttpParams({ fromObject: _params });
    const headers = await this._generateHeaders();
    const obs = this.http.delete(endpoint, { params, headers });

    return this._toPromise(wrapMeta ? obs.pipe(map(x => ({ data: x }))) : obs);
  }

  /**
   *
   */
  async genericPost(endpoint: string, params: Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>, wrapMeta?: boolean) {
    const headers = await this._generateHeaders();
    const obs = this.http.post(endpoint, params, { headers });

    return this._toPromise(wrapMeta ? obs.pipe(map(x => ({ data: x }))) : obs);
  }

  /**
   * @ignore
   */
  private async _generateHeaders() {
    return {
      /* eslint-disable  @typescript-eslint/naming-convention */
      Authorization: 'Bearer ' + await this.user.refreshToken(this.user.getCurrentUser()),
      /* eslint-enable  @typescript-eslint/naming-convention */
    };
  }

  /**
   * @ignore
   */
  private _toPromise(obs: Observable<unknown>) {
    return firstValueFrom(obs.pipe(
      map((x: { data: unknown }) => {
        const { data } = x;

        return data;
      }),
      catchError((_err: unknown) => {
        const err = _err as Error;

        if (err.name === 'HttpErrorResponse') {
          const httpError = err as HttpErrorResponse;

          if (httpError.status === 401) {
            this._router.navigate(['/logout']);
          }
        }

        return of([]);
      }),
    ));
  }
}
