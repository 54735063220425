import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { PjdDscLibModule } from '@pjd-development/pjd-dsc-lib';

import { AppComponent } from './app.component';
import { TicketsComponent } from './tickets/tickets.component';
import { ReportsComponent } from './reports/reports.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import {
  ForgotPasswordComponent,
} from './forgot-password/forgot-password.component';
import {
  ResetPasswordComponent,
} from './reset-password/reset-password.component';
import { LogoutComponent } from './logout/logout.component';
import { SavedComponent } from './saved/saved.component';
import { HttpClientModule } from '@angular/common/http';
import {
  GlobalErrorHandlerServiceService,
} from './global-error-handler-service.service';
import { DatatableComponent } from './datatable/datatable.component';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  OwlNativeDateTimeModule, OwlDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import {
  ProductCodeJobReportComponent,
} from './product-code-job-report/product-code-job-report.component';
// import { ScrollerComponent } from './scroller/scroller.component';
import { FiltersComponent } from './filters/filters.component';
import { ListModalComponent } from './list-modal/list-modal.component';
import {
  ReportCollapseComponent,
} from './report-collapse/report-collapse.component';
import {
  DatatableHeaderComponent,
} from './datatable-header/datatable-header.component';
import { ResponseCodeComponent } from './response-code/response-code.component';
import { CircleLoaderComponent } from './circle-loader/circle-loader.component';
import { NotifierModule } from 'angular-notifier';
import { GoogleAnalyticsService } from './google-analytics.service';
import { ChartComponent } from './chart/chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GraphsComponent } from './graphs/graphs.component';
import {
  CreateGraphModalComponent
} from './create-graph-modal/create-graph-modal.component';
import {
  SavedDatatableComponent
} from './saved-datatable/saved-datatable.component';
import { LoggerModule } from 'ngx-logger';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './order/order.component';
import { SchedulePageComponent } from './schedule-page/schedule-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  EditScheduleModalComponent
} from './edit-schedule-modal/edit-schedule-modal.component';
import {
  PlantScheduleSummaryPageComponent
} from './plant-schedule-summary-page/plant-schedule-summary-page.component';
import {
  NewOrderPageComponent
} from './new-order-page/new-order-page.component';
import {
  SectionHeaderComponent
} from './section-header/section-header.component';
import {
  CreatePlantScheduleReportModalComponent
} from './create-plant-schedule-report-modal/create-plant-schedule-report-modal.component';

import { environment } from '../environments/environment';
import { EmailComponent } from './email/email.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextboxComponent } from './textbox/textbox.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ErrorSummaryComponent } from './error-summary/error-summary.component';

// set log level
const level = environment.logLevel;

@NgModule({
  declarations: [
    AppComponent,
    TicketsComponent,
    ReportsComponent,
    AdminComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent,
    SavedComponent,
    DatatableComponent,
    ReportModalComponent,
    ProductCodeJobReportComponent,
    //    ScrollerComponent,
    OrdersComponent,
    FiltersComponent,
    ListModalComponent,
    ReportCollapseComponent,
    DatatableHeaderComponent,
    ResponseCodeComponent,
    CircleLoaderComponent,
    ChartComponent,
    GraphsComponent,
    CreateGraphModalComponent,
    SavedDatatableComponent,
    OrderComponent,
    SchedulePageComponent,
    EditScheduleModalComponent,
    PlantScheduleSummaryPageComponent,
    NewOrderPageComponent,
    SectionHeaderComponent,
    CreatePlantScheduleReportModalComponent,
    EmailComponent,
    DropdownComponent,
    TextboxComponent,
    DatePickerComponent,
    ErrorSummaryComponent,
  ],
  imports: [
    BrowserModule,
    NgxChartsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    DragDropModule,
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs',
      level,
      // serverLogLevel: NgxLoggerLevel.ERROR
    }),
    FormsModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    OwlDateTimeModule,
    NgSelectModule,
    OwlNativeDateTimeModule,
    PjdDscLibModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          // distance: 22,
        },
        vertical: {
          position: 'top',
          // distance: '50%' as unknown as number,
          gap: 10,
        }
      },
      theme: 'material',
      behaviour: {
        autoHide: 5000,
        onClick: false,
        onMouseover: 'pauseAutoHide',
        showDismissButton: false,
        stacking: 4
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
        },
        hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
        },
        shift: {
          speed: 300,
          easing: 'ease'
        },
        overlap: 150
      }
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerServiceService },
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(protected _googleAnalyticsService: GoogleAnalyticsService) { }
}
