import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  TextboxQuestion,
  LargeModalComponent,
  IButton,
  DropDownLink,
  DropDownStyle,
  IOption,
} from '@pjd-development/pjd-dsc-lib';
import {
  QuestionBase,
} from '@pjd-development/pjd-dsc-lib/lib/question/question-base';
import * as _ from 'lodash-es';

// local imports
import { CompanyService, ICompany } from '../company.service';
import { PlantService } from '../plant.service';
import { TimeRangeService } from '../time-range.service';
import { HeaderBarService } from '../header-bar.service';
import { UserService } from '../user.service';
import { TicketService } from '../ticket.service';
import {
  IGraph,
  IGraphGroupTypes,
  ISavedItem, ITicketList, SavedService
} from '../saved.service';
import {
  SavedDatatableComponent,
} from '../saved-datatable/saved-datatable.component';
import { SuccessService } from '../success.service';
import { DropdownService } from '../dropdown.service';
import { IActivateEvent } from '../datatable/datatable.component';
import { TableColumn } from '@swimlane/ngx-datatable';
import { of } from 'rxjs';

const WIDTH = 238;

/**
 *
 */
@Component({
  selector: 'app-saved',
  templateUrl: './saved.component.html',
  styleUrls: ['./saved.component.css']
})
export class SavedComponent implements OnInit, AfterViewInit {

  /**
   * @ignore
   */
  @ViewChild('ticketTable', { static: true })
  private ticketTable: SavedDatatableComponent;

  /**
   * @ignore
   */
  @ViewChild('reportTable', { static: true })
  private reportTable: SavedDatatableComponent;

  /**
   * @ignore
   */
  @ViewChild('graphTable', { static: true })
  private graphTable: SavedDatatableComponent;

  /**
   * @ignore
   */
  @ViewChild('detailsModal', { static: true })
  private detailsModal: LargeModalComponent;

  /**
   * Used to show loading state.
   */
  loadingIndicator = true;

  /**
   * Used to dynamically generate form.
   */
  questions: Array<Array<QuestionBase<unknown>>>;

  /**
   *
   */
  buttons: Array<IButton>;

  /**
   * list of applied sorts
   */
  ticketSorts = [];
  reportSorts = [];
  graphSorts = [];

  plants;

  /**
   * Template helper
   */
  reportType: (s: string) => IOption;
  graphType: (s: string) => IOption;
  graphGroupType: (s: number) => IGraphGroupTypes;

  /**
   *
   */
  ticketColumns: Array<unknown>;
  reportColumns: Array<unknown>;
  graphColumns: Array<unknown>;

  /**
   *
   */
  ticketRows = [];
  reportRows = [];
  graphRows = [];

  kind: string;

  reportActions = [];
  ticketActions = [];
  graphActions = [];

  /**
   * @ignore
   */
  private _canUpdateQuestions = false;

  #kinds: Array<string>;
  #TICKETS: string;
  #REPORTS: string;
  #GRAPHS: string;
  #data: Array<ISavedItem>;
  #timeRangeQuestion: TextboxQuestion;
  #companiesQuestion: TextboxQuestion;
  #jobsQuestion: TextboxQuestion;
  #nameQuestion: TextboxQuestion;
  #graphQuestion: TextboxQuestion;
  #plantQuestion: TextboxQuestion;
  #reportQuestion: TextboxQuestion;
  #graphGroupQuestion: TextboxQuestion;
  #productsQuestion: TextboxQuestion;
  #selectedRow: ISavedItem;

  /**
   * @ignore
   */
  constructor(
    private companyService: CompanyService,
    private _dropdownService: DropdownService,
    private headerBar: HeaderBarService,
    private plantService: PlantService,
    private saved: SavedService,
    private router: Router,
    private ticketService: TicketService,
    private timeRangeService: TimeRangeService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private _ngZone: NgZone,
    private success: SuccessService,
  ) {
    const k = this.saved.kinds;

    this.reportType = this.saved.findReportType;
    this.graphType = this.saved.findGraphType;
    this.graphGroupType = this.saved.findGraphGroupType;
    this.#kinds = k;
    this.#TICKETS = k[0];
    this.#GRAPHS = k[1];
    this.#REPORTS = k[2];
  }

  get savedItemType() {
    return _.join(_.kebabCase(this.kind).split('-'), ' ');
  }

  get isTickets() {
    return this.kind === this.#TICKETS;
  }

  get isReports() {
    return this.kind === this.#REPORTS;
  }

  get isGraphs() {
    return this.kind === this.#GRAPHS;
  }


  get dropDownLinksThree() {
    return this.headerBar.dropDownLinksThree as DropDownLink[];
  }

  get dropDownStylesThree() {
    return this.headerBar.dropDownStylesThree as DropDownStyle[];
  }
  /**
   * @ignore
   */
  private get selected() {
    let datatable: SavedDatatableComponent;

    if (this.isGraphs) {
      datatable = this.graphTable;
    }
    if (this.isReports) {
      datatable = this.reportTable;
    }
    if (this.isTickets) {
      datatable = this.ticketTable;
    }

    return datatable.selected;
  }

  /**
   *
   */
  set dropDownLinksThree(d) {
    this.headerBar.dropDownLinksThree = d;
  }

  /**
   *
   */
  set dropDownStylesThree(d) {
    this.headerBar.dropDownStylesThree = d;
  }


  /**
   * Used by companies and jobs columns to lookup objects.
   *
   * @param col ngx-datatable column to lookup.
   * @param s The array of companies or jobs.
   * @returns The observable which contains the data.
   */
  async findCompanyOrJob(col: TableColumn, s: Array<string>) {
    if (col.prop === 'jobs') {

      // job
      const jobs = (await this.ticketService.findJobs(s));

      return jobs;
    }

    // company
    const c = this.companyService.findCompanies(s);

    // idk how c could ever be undefined but sometimes it is...
    return c || of([]);
  }

  /*
  findProducts(col: unknown, s: Array<unknown>) {
    // return this.ticketService.findProducts(s);
  }
*/

  /**
   * Lifecycle hook for Angular.
   */
  ngAfterViewInit() {
    _.defer(() => {
      this.dropDownLinksThree = [
        {
          name: 'Saved Ticket Lists',
          disabled: false,
          onClick: () => {
            this._initType(this.#TICKETS);
          }
        },
        {
          name: 'Saved Graphs',
          disabled: false,
          onClick: () => {
            this._initType(this.#GRAPHS);
          }
        },
        {
          name: 'Saved Reports',
          disabled: false,
          onClick: () => {
            this._initType(this.#REPORTS);
          }
        },
      ] as DropDownLink[];
      this.dropDownStylesThree = [];
      this._initType(this.#TICKETS);
      this._loadData();
    });
  }

  /**
   * Lifecycle hook for Angular.
   */
  ngOnInit() {
    _.defer(() => {
      this.headerBar.clearAll();
    });
    this._initQuestions();
    this._initButtons();
    this._initActions();
    this.ticketColumns = this._getColumns(this.#TICKETS);
    this.reportColumns = this._getColumns(this.#REPORTS);
    this.graphColumns = this._getColumns(this.#GRAPHS);
  }

  /**
   * Event handler for the ngx-datatable
   *
   * @param e The event from the datatable.
   */
  async onActivate(e: IActivateEvent) {
    const ci = _.get(e as unknown, 'cellIndex', -1) as number;

    if (e.type === 'click' && ci !== 0 && ci !== 6) {
      const row = e.row as ITicketList;

      this.#selectedRow = row;
      if (this.saved.isReport(row)) {
        this.#reportQuestion.value = this.reportType(row.reportType).value;
      }

      if (this.saved.isGraph(row)) {
        this.#graphQuestion.value = this.graphType(row.graphType).value;
        this.#graphGroupQuestion.value = this.graphGroupType(row.periodInHours).value;
        this.#productsQuestion.value = _.trim(_.get(row, 'Products'));
      }
      this.#timeRangeQuestion.value = this.time(row.timeRange);
      this.#nameQuestion.value = row.name;

      this.#plantQuestion.value = this.plant(row.plants);
      this.#jobsQuestion.value = 'All';
      this.#companiesQuestion.value = 'All';

      (await this.findCompanyOrJob({ prop: 'jobs' }, row.jobs)).subscribe((x: IOption[]) => {
        this.#jobsQuestion.value = _.map(x, j => j.value).join('\n');
      });
      (await this.findCompanyOrJob(row, row.companies)).subscribe((c: IOption[]) => {
        this.#companiesQuestion.value = _.map(c, x => x.key + ' - ' + x.value).join('\n');
      });
      this.cd.detectChanges();
      this.detailsModal.open();
    }
  }

  /**
   * event handler for ngx-datatable
   */
  onSelect(e: unknown[]) {
    if (this.isTickets) {
      this.ticketActions[0].disabled = (e.length !== 1);
      this.ticketActions[1].disabled = e.length === 0;
    } else {
      if (this.isReports) {
        this.reportActions[0].disabled = e.length === 0;
      } else {
        this.graphActions[0].disabled = e.length === 0;
      }
    }
  }

  /**
   * handler for submit events
   */
  onSubmit(a: ISavedItem) {
    this.#submit(a);
  }

  /**
   * Helper for displaying data in the view.
   *
   * @param p The list plants to look up.
   * @returns The string representing the list of plants.
   */
  plant(p: Array<number>) {
    const result = [];
    const plants = this.plants;

    if (!p || p.length === plants.length) {
      return 'All';
    }
    _.each(p, plant => {
      result.push(this.plantService.findPlant(plant).value);
    });

    return _.join(result, ', ');
  }

  /**
   * Helper function for the view.
   *
   * @param t The time range to lookup.
   * @returns The time range object.
   */
  time(t: string) {
    return this.timeRangeService.findTime(t).value;
  }

  /**
   * @ignore
   */
  private _createDropDownLink(name: string, f: unknown) {
    return {
      name,
      disabled: true,
      active: false,
      onClick: f,
    };
  }

  /**
   * @ignore
   */
  private _getGraphsColumns() {
    const columns: Array<Record<string, unknown>> = [
      { sortable: false },
      // { prop: 'name' },
      { prop: 'graphType' },
      { prop: 'plants' },
      // { prop: 'timestamp' },
      { prop: 'timeRange' },
      { prop: 'Companies' },
      { prop: 'Jobs', },
      //  { prop: 'ticket', name: 'Ticket #' },
      { name: '', sortable: false },
    ];

    _.each(columns, (c) => {
      c.width = WIDTH;
      c.resizeable = false;
    });
    columns[0].cellTemplate = 'checkboxTpl';
    columns[0].width = 55;
    columns[6].width = 135;
    columns[1].cellTemplate = 'graphTypeTmpl';
    columns[2].cellTemplate = 'plantTmpl';
    columns[3].cellTemplate = 'timeTmpl';
    columns[4].cellTemplate = 'hdrTpl';
    columns[5].cellTemplate = 'hdrTpl';
    columns[6].cellTemplate = 'submitTmpl';

    return columns;
  }

  /**
   * @ignore
   */
  private _getReportsColumns() {
    const columns: Array<Record<string, unknown>> = [
      { sortable: false },
      // { prop: 'name' },
      { prop: 'reportType' },
      { prop: 'plants' },
      // { prop: 'timestamp' },
      { prop: 'timeRange' },
      { prop: 'Companies' },
      { prop: 'Jobs', },
      //  { prop: 'ticket', name: 'Ticket #' },
      { name: '', sortable: false },
    ];

    _.each(columns, (c) => {
      c.width = WIDTH;
      c.resizeable = false;
    });
    columns[0].cellTemplate = 'checkboxTpl';
    columns[0].width = 55;
    columns[6].width = 135;
    columns[1].cellTemplate = 'typeTmpl';
    columns[2].cellTemplate = 'plantTmpl';
    columns[3].cellTemplate = 'timeTmpl';
    columns[4].cellTemplate = 'hdrTpl';
    columns[5].cellTemplate = 'hdrTpl';
    columns[6].cellTemplate = 'submitTmpl';

    return columns;
  }

  /**
   * @ignore
   */
  private _getTicketListColumns() {
    const columns: Array<Record<string, unknown>> = [
      { sortable: false },
      { prop: 'name' },
      { prop: 'plants' },
      { prop: 'timeRange' },
      { prop: 'Companies' },
      { prop: 'Jobs', },
      { name: '', sortable: false }, // was Name
    ];

    _.each(columns, (c) => {
      c.width = WIDTH;
    });
    columns[0].cellTemplate = 'checkboxTpl';
    columns[0].width = 55;
    columns[6].width = 135;
    columns[1].cellTemplate = 'nameTmpl';
    columns[2].cellTemplate = 'plantTmpl';
    columns[3].cellTemplate = 'timeTmpl';
    columns[4].cellTemplate = 'hdrTpl';
    columns[5].cellTemplate = 'hdrTpl';
    columns[6].cellTemplate = 'submitTmpl';

    return columns;
  }

  /**
   * @ignore
   */
  private _initActions() {
    this.ticketActions.push(
      this._createDropDownLink('Make Default View', this.#makeDefault));
    this.ticketActions.push(
      this._createDropDownLink('Remove Selected', this.#remove));
    this.ticketActions.push(
      this._createDropDownLink('Reset Default View', this.#createDefault));
    this.ticketActions[2].disabled = false;
    this.reportActions.push(
      this._createDropDownLink('Remove Selected', this.#remove));
    this.graphActions.push(
      this._createDropDownLink('Remove Selected', this.#remove));
  }

  /**
   * @ignore
   */
  private _initButtons() {
    this.buttons = [
      {
        text: 'Submit',
        click: this.#submit,
      }
    ];
  }

  /**
   * @ignore
   */
  private async _initQuestions() {
    this.#timeRangeQuestion = new TextboxQuestion({
      key: 'timeRange',
      label: 'Time Range',
      disabled: true,
      options: this.timeRangeService.timeRanges,
      placeholder: 'Select Timestamp',
      onChange: () => {
        // this.timeRangeChanged();
      },
    });

    // ensure plants are loaded
    await this.plantService.loadedPromise;

    this.plants = this.plantService.plants;

    this.#plantQuestion = new TextboxQuestion({
      key: 'plants',
      label: 'Plants',
      disabled: true,
      options: this.plantService.plants,
      placeholder: 'Select Plants',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
    });
    this.#companiesQuestion = new TextboxQuestion({
      key: 'companies',
      label: 'Companies',
      disabled: true,
      options: [],
      placeholder: 'Select Companies',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        // this.companiesChanged();
      },
    });
    this.#jobsQuestion = new TextboxQuestion({
      key: 'jobs',
      label: 'Jobs',
      options: [],
      disabled: true,
      placeholder: 'Select Jobs',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
    });
    this.#nameQuestion = new TextboxQuestion({
      key: 'name',
      label: 'Name',
      disabled: true,
    });
    this.#reportQuestion = new TextboxQuestion({
      key: 'reportType',
      label: 'Report Type',
      disabled: true,
    });
    this.#graphQuestion = new TextboxQuestion({
      key: 'graph',
      label: 'Graph Type',
      disabled: true,
      options: [],
      placeholder: 'Choose a Graph Type',
      onChange: () => {
        //
      },
      clearable: false,
      compareWith: this._dropdownService.cmpFn,
    });
    this.#graphGroupQuestion = new TextboxQuestion({
      key: 'graphGroup',
      label: 'Group Graph',
      disabled: true,
      options: [],
      placeholder: 'Choose a Grouping Option',
      onChange: () => {
        //
      },
      clearable: false,
      compareWith: this._dropdownService.cmpFn,
    });
    this.#productsQuestion = new TextboxQuestion({
      key: 'Products',
      label: 'Products',
      disabled: true,
      options: [],
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      compareWith: this._dropdownService.cmpFn,
    });

    this._canUpdateQuestions = true;
    this._updateQuestions();
  }

  /**
   * @ignore
   */
  private _updateQuestions() {
    if (!this._canUpdateQuestions) {
      return;
    }
    if (this.isGraphs) {
      this.questions = [
        [
          this.#graphQuestion,
          this.#graphGroupQuestion,
        ],
        [
          this.#plantQuestion,
          this.#timeRangeQuestion,
        ],
        [
          this.#companiesQuestion,
          this.#jobsQuestion,
        ],
        [
          this.#productsQuestion,
        ]
      ];
    } else {
      this.questions = [
        [
          this.isTickets ? this.#nameQuestion : this.#reportQuestion,
        ],
        [
          this.#plantQuestion,
          this.#timeRangeQuestion,
        ],
        [
          this.#companiesQuestion,
          this.#jobsQuestion,
        ],
      ];
    }
  }

  /**
   * @ignore
   */
  private _getIndexForKind(kind: string) {
    return _.findIndex(this.#kinds, (x) => kind === x);
  }

  /**
   * @ignore
   */
  private _getColumns(kind: string) {
    const getColumns = [
      this._getTicketListColumns,
      this._getGraphsColumns,
      this._getReportsColumns,
    ];
    const index = this._getIndexForKind(kind);

    return getColumns[index]();
  }

  /**
   * @ignore
   */
  private _initType(kind: string) {
    const index = this._getIndexForKind(kind);

    // set kind
    this.kind = kind;
    this._updateQuestions();

    // set styles and links
    this._styleType(kind);
    this._setActiveLink(index);

    // reset table selection
    this.onSelect([]);

    // HACK: for safari to let the null state have a chance
    _.defer(() => {
      this.graphRows = [...this.graphRows];
      this.reportRows = [...this.reportRows];
      this.ticketRows = [...this.ticketRows];
    });
  }

  /**
   * @ignore
   */
  private _setActiveLink(index: number) {
    _.each(this.dropDownLinksThree, (ddl, i) => {
      ddl.active = +i === index;
    });
  }

  /**
   * @ignore
   */
  private _filterData() {
    this._ngZone.run(() => {
      this.ticketRows = _.filter(this.#data, x => x.kind === this.#TICKETS);
      this.reportRows = _.filter(this.#data, x => x.kind === this.#REPORTS);
      this.graphRows = _.filter(this.#data, x => x.kind === this.#GRAPHS);
    });
  }

  /**
   * @ignore
   */
  private async _loadData() {
    // set spinner
    this.loadingIndicator = true;

    // get data
    const x = await this.saved.savedForUser(this.userService.getCurrentUser()) as (IGraph[]);

    // remove spinner
    this._ngZone.run(() => {
      this.loadingIndicator = false;
    });

    // data manipulation
    _.each(x, async (preTicketList) => {
      const { companies, jobs, products } = preTicketList;
      const numberOfJobs = _.get(jobs, 'length', 0) as number;
      const numberOfCompanies = _.get(companies, 'length', 0) as number;
      const numberOfProducts = _.get(products, 'length', 0);
      const productsPlural = numberOfProducts > 1 ? 's' : '';

      _.set(preTicketList, 'Jobs',
        !numberOfJobs ? '\nALL' : `\n${numberOfJobs} Jobs`);
      _.set(preTicketList, 'Companies',
        !numberOfCompanies ? '\nALL' : `\n${numberOfCompanies} Companies`);
      _.set(preTicketList, 'Products',
        !numberOfProducts ? '\nAll' : `\n${numberOfProducts} Product` +
          productsPlural);
      if (numberOfJobs === 1) {
        (await this.ticketService.findJobs([jobs[0]])).subscribe(j => {
          const key = _.get(j, '[0].key');
          const desc = _.get(j, '[0].ODescription');

          _.set(preTicketList, 'Jobs', key + '\n' + desc);
        });
      }
      if (numberOfCompanies === 1) {
        this.companyService.findCompany(companies[0]).subscribe((c: ICompany) => {
          _.set(preTicketList, 'Companies', c.key + '\n' + c.value);
        });
      }
    });

    // store data
    this.#data = x;

    // filter data
    this._filterData();

    // load current type
    this._initType(this.kind);
  }

  /**
   * @ignore
   */
  private async _replace(tl: ISavedItem) {
    await this.saved.replace(tl);

    this._loadData();
  }

  /**
   * @ignore
   */
  private _styleFactory(name: string) {
    return {
      type: 'link-base-green',
      size: 'normal',
      name,
      position: 'left'
    };
  }

  /**
   * @ignore
   */
  private _styleType(kind: string) {
    const styles = _.map([
      'Saved Tickets List',
      'Saved Graphs',
      'Saved Reports',
    ], (x: string) => this._styleFactory(x));
    const index = this._getIndexForKind(kind);

    this.dropDownStylesThree = [styles[index]];
  }

  #createDefault = async () => {
    const user = this.userService.getCurrentUser();

    await this.saved.createDefault(user);

    this._loadData();
  };

  #makeDefault = async () => {
    const tl = this.selected[0];
    const user = this.userService.getCurrentUser();

    // nothing to do
    if (!tl || tl.isDefault) {
      return;
    }

    // clear current default
    const defaultTicketList = await this.saved.getDefaultTicketList(user);

    if (defaultTicketList) {
      defaultTicketList.isDefault = false;

      await this.saved.replace(defaultTicketList);
      // ticket list updated

      // set new default
      tl.username =
        this.userService.getUsername();
      tl.isDefault = true;
      this._replace(tl);
    }
  };

  #remove = () => {
    _.each(this.selected, async x => {

      // TODO: add removeAll method
      await this.saved.remove(x);

      this.success.showMessage('', 'Removed from Saved');
      this._loadData();
    });
  };

  #submit = (x?: ISavedItem) => {
    const date = (x && x.createdDate) || this.#selectedRow.createdDate;
    const kind = (x && x.kind) || this.#selectedRow.kind;
    const index = _.findIndex(this.saved.kinds, k => k === kind);
    const route = [
      '/tickets/',
      '/graphs/',
      '/reports/',
    ][index];

    this.detailsModal.close();
    this._ngZone.run(() => this.router.navigate([route + date]));
  };
}
