// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-bar .header-bar__refresh.first {
  padding-right: 20px;
  border-right: 1px solid gray;
}
`, "",{"version":3,"sources":["webpack://./src/app/datatable-header/datatable-header.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,4BAA4B;AAC9B","sourcesContent":[".header-bar .header-bar__refresh.first {\n  padding-right: 20px;\n  border-right: 1px solid gray;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
