import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { DynamodbService } from './dynamodb.service';
import { IOption } from '@pjd-development/pjd-dsc-lib';

const GRAPH = 'Graph';
const REPORT = 'Report';
const TICKET = 'TicketList';

/**
 * interface for saved items
 */
export interface ISavedItem {
  createdDate?: number;
  username: string;
  kind?: string;
}

/**
 * Interface for ticket lists.
 */
export interface ITicketList extends ISavedItem {
  plants?: Array<number>;
  timeRange: string;
  companies?: Array<string>;
  jobs?: Array<string>;
  name?: string;
  isDefault?: boolean;
  ticket?: Array<string>;
}

/**
 * Interface for order lists.
 */
export interface IOrderList extends ITicketList {
  status?: string[];
  shift?: string;
  isDefaultOrder?: boolean;
}

/**
 * Interface for creating plant schedule summary report
 */
export interface ICreateSchedule {
  // TODO: check with new endpoints
  status?: string[];
  shift: string;
  date: string; // yyyy-mm-dd
  plants?: string | string[];
}

/**
 * Interface for reports.
 */
export interface IReport extends ITicketList {
  reportType: string;
  byDay: boolean;
}

/**
 * Interface for reports.
 */
export interface IGraph extends ITicketList {
  graphType: string;
  periodInHours: number;
  products?: Array<string>; // holds key
}

export interface IGraphGroupTypes {
  key?: number;
  value: string;
}

/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class SavedService {

  currentReport: IReport = null;
  currentGraph: IGraph = null;

  #reportTypes = [
    { key: 'at', value: 'Sales by Product Code' },
    { key: 'ay', value: 'Sales by Product Code & Job' },
    { key: 'lt', value: 'Sales Summary Detail' },
  ];

  #graphTypes = [
    { key: 'tsph', value: 'Tons Shipped Per Hour' },
    { key: 'tsphpp', value: 'Tons Shipped Per Hour Per Product' },
  ];

  #graphGroupTypes = [
    { key: 0.25, value: 'Tons Per 15 Mins' },
    { key: 1, value: 'Tons Per Hour' },
    { key: 24, value: 'Tons Per Day' },
  ];

  #kinds = [
    TICKET,
    GRAPH,
    REPORT,
  ];

  /**
   * @ignore
   */
  constructor(
    private _dynamo: DynamodbService,
  ) {
  }

  /**
   * Get the static list of saved item kinds.
   *
   * @returns The list of saved item kinds.
   */
  get kinds() {
    return _.clone(this.#kinds);
  }

  /**
   * Get the static list of reports.
   *
   * @returns The list of reports.
   */
  get reportTypes() {
    return _.clone(this.#reportTypes);
  }

  /**
   * Get the static list of graph types.
   *
   * @returns The list of graph types.
   */
  get graphTypes() {
    return _.clone(this.#graphTypes);
  }

  /**
   * Get the static list of graph group types.
   *
   * @returns The list of graph group types.
   */
  get graphGroupTypes() {
    return _.clone(this.#graphGroupTypes);
  }

  isGraph(saved: ISavedItem): saved is IGraph {
    return saved.kind === GRAPH;
  }

  isTL(saved: ISavedItem): saved is ITicketList {
    return saved.kind === TICKET;
  }

  isReport(saved: ISavedItem): saved is IReport {
    return saved.kind === REPORT;
  }

  /**
   * Create new graph in dynamodb.
   *
   * @param  graph The graph to persist.
   * @returns  Cold observable to perform create.
   */
  async addGraph(graph: IGraph) {
    graph.kind = GRAPH;

    return this._insert(graph);
  }

  /**
   * Create new report in dynamodb.
   *
   * @param  report The report to persist.
   * @returns  Cold observable to perform create.
   */
  async addReport(report: IReport) {
    report.kind = REPORT;
    _.set(report, 'byDay', report.byDay ? 'true' : 'false');

    return this._insert(report);
  }

  /**
   * Create new ticket list in dynamodb.
   *
   * @param tl The ticket list to persist.
   * @returns  Cold observable to perform create.
   */
  async addTicketList(tl: ITicketList) {
    tl.kind = TICKET;
    _.set(tl, 'byDay', 'false');

    return this._insert(tl);
  }

  /**
   *
   */
  async createDefault(user: CognitoUser) {
    if (!user) {
      throw new Error('user is null');
    }

    const currentTicketList = {
      username: user.getUsername(),
      name: 'All/Previous Day',
      isDefault: true,
      timeRange: 'pday',
    };
    const d = await this.getDefaultTicketList(user);

    if (d) {
      d.isDefault = false;

      await this.replace(d);
    } else {

    }

    await this.addTicketList(currentTicketList);

    return currentTicketList;
  }

  /**
   * Finds the object with the given key.
   *
   * @param user The cognito user which is the primary key.
   * @param  createdDate The secondary key.
   * @returns  The cold observable to perform the lookup.
   */
  async find(user: CognitoUser, createdDate: number) {
    if (!user) {
      return null;
    }

    const un = user.getUsername();

    return await this._dynamo.getReports(un, createdDate) as ISavedItem;
  }

  /**
   * Utility function for looking up a report type.
   *
   * @param  typeKey The key for the type.
   * @returns The report type object.
   */
  findReportType =
    (typeKey: string) => _.find(this.reportTypes,
      (p) => typeKey === p.key) || { value: '--' } as IOption;

  /**
   * Utility function for looking up a graph type.
   *
   * @param  typeKey The key for the type.
   * @returns The graph type object.
   */
  findGraphType =
    (typeKey: string) => _.find(this.graphTypes,
      (p) => typeKey === p.key) || { value: '--' } as IOption;

  /**
   * Utility function for looking up a graph type.
   *
   * @param  typeKey The key for the type.
   * @returns The graph type object.
   */
  findGraphGroupType =
    (typeKey: number) => _.find(this.graphGroupTypes,
      (p) => typeKey === p.key) || { value: '--' };

  /**
   * Gets the default ticket list for a user.
   *
   * @param user The cognito user to find the list for.
   * @returns The default list, if exists.
   */
  async getDefaultTicketList(user: CognitoUser) {
    const ticketLists = await this.savedForUser(user);

    return _.find(ticketLists as ITicketList[], tl => tl.isDefault) as ITicketList;
  }

  /**
   * Gets the default orders list for a user.
   *
   * @param _user The cognito user to find the list for.
   * @returns The default list, if exists.
   */
  async getDefaultOrdersList(_user: CognitoUser) {
    const ticketLists = await this.savedForUser(_user);
    const d = _.find(ticketLists as IOrderList[], tl => tl.isDefaultOrder);

    if (d) {
      return d;
    }

    return {
      isDefaultOrder: false,
      timeRange: 'cday',
      shift: 'S',
    } as IOrderList;
  }

  /**
   * Removes object from cognito.
   *
   * @param data The object to be removed.
   * @returns The cold observable to remove the object.
   */
  async remove(data: ISavedItem) {
    return await this._dynamo.deleteReport(data.username, data.createdDate);
  }

  /**
   * Replaces object from cognito.
   *
   * @param data The object to be replaced.
   * @returns The cold observable to replace the object.
   */
  async replace(data: ISavedItem) {
    if (!data.createdDate) {
      data.createdDate = new Date().valueOf();
    }

    return await this._dynamo.putReport(data);
  }

  /**
   * Return all saved objects for a given user.
   *
   * @param user The cognito user object.
   * @returns  The list of objects.
   */
  async savedForUser(user: CognitoUser) {
    if (!user) {
      return null;
    }

    const partition = user.getUsername();
    const p = await this._dynamo.getReports(partition);

    return p as ISavedItem[];
  }

  /**
   * @ignore
   */
  private async _insert(data: ISavedItem) {
    data.createdDate = new Date().valueOf();

    return await this._dynamo.postReport(data);
  }
}
