import { Injectable } from '@angular/core';
import { ConfirmationModalComponent } from '@pjd-development/pjd-dsc-lib';

export interface Successable {
  successOutlet: ConfirmationModalComponent;
  successMessage: string;
  successTitle: string;
}

@Injectable({
  providedIn: 'root'
})
export class SuccessService {
  target: Successable;

  constructor() {
    //
  }

  registerTarget(t: Successable) {
    this.target = t;
  }

  showMessage(title, message) {
    const t = this.target;

    t.successTitle = title;
    t.successMessage = message;
    t.successOutlet.open();
  }
}
