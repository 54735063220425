import { Injectable, isDevMode } from '@angular/core';
import { environment } from '../environments/environment';
import * as _ from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { BackendService } from './backend.service';
import { IOption } from '@pjd-development/pjd-dsc-lib';

/**
 * @ignore
 */
const root = () => isDevMode() ? '' : environment.backendRoot;

/**
 * @ignore
 */
const plantsEndpoint = () => root() + '/api/plants';

const group = 'All Plants';

export interface IPlant extends IOption {
  group: string;
}

export interface IPlantResponse {
  plantNumber: number;
  plantShortName: string;
}

@Injectable({
  providedIn: 'root'
})
export class PlantService {

  readonly allPlants = group;

  private _loadedPromise: Promise<void>;

  /**
   * @ignore
   * alphabetical ordering
   */
  private _plants = [
    /*
    { key: 897, value: 'Conneaut', group, },
    { key: 899, value: 'Erie', group, },
    { key: 894, value: 'Hillsville', group, },
    { key: 892, value: 'Homer City', group, },
    { key: 993, value: 'Koppel', group, },
    { key: 995, value: 'Neville Island', group, },
    { key: 992, value: 'Neville Terminal', group, },
    { key: 891, value: 'New Kensington', group, },
    { key: 991, value: 'Second Avenue', group, },
    { key: 889, value: 'Sterrettania', group, },
    { key: 895, value: 'Wheatland #1', group, },
    { key: 896, value: 'Wheatland #2', group, },
    { key: 898, value: 'Wheelertown', group, },
    { key: 890, value: 'Zelienople', group, },
*/
  ];

  constructor(
    private log: NGXLogger,
    private backend: BackendService,
  ) { }

  get loadedPromise() {
    if (!this._loadedPromise) {
      this._loadedPromise = this.loadPlants().then(() => {
        //
      }).catch((_e) => {
        this._loadedPromise = null;
      });
    }

    return this._loadedPromise;
  }

  /**
   * Get the static list of plants.
   *
   * @returns The list of plants.
   */
  get plants() {
    this.log.trace('get plants in plants.service');

    return _.clone(this._plants);
  }

  get plantKeys() {
    return _.map(this.plants, p => p.key);
  }

  /**
   * loads plants
   */
  async loadPlants() {
    this.log.debug('loading plants');

    try {
      const xx = await this.backend.genericPost(plantsEndpoint(), {}) as IPlantResponse[];

      _.each(xx, p => {
        _.set(p, 'key', Math.trunc(p.plantNumber));
        _.set(p, 'value', _.join([p.plantNumber, p.plantShortName], ' - '));
        _.set(p, 'group', group);
      });
      this._plants = _.sortBy(xx, ['value']);

      return xx;
    } catch (e) {
      if (e.message.includes('no cognito user')) {
        throw e;
      }
      console.error(e);
    }

    return [];
  }

  /**
   * Utility function for looking up a plant.
   *
   * @param _plantNum The id of the plant.
   * @returns The plant object.
   */
  findPlant = (_plantNum: number) => {
    // HACK: find Wheelertown both ways
    const plantNum = _plantNum === 220 ? 898 : _plantNum;
    const found = _.find(this._plants, (p) => plantNum === p.key);

    return _.clone(found);
  };
}
