import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';

// local imports
import { environment } from '../environments/environment';
import { NGXLogger } from 'ngx-logger';

export interface IUserEvent {
  CreationDate: string;
  EventContextData: {
    IpAddress: string;
  }
}

export interface IUser {
  email: string;
  company: string;
  admin: string;
  companies: Array<number>;
  jobs: Array<unknown>;
  memo: string;
  /* eslint-disable  @typescript-eslint/naming-convention */
  view_orders: string;
  given_name: string;
  family_name: string;
  events?: Array<IUserEvent>;
  password?: string;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  /* eslint-enable  @typescript-eslint/naming-convention */
  name: string; // computed full name
  account: string; // computed account description
}

export interface IAdminBody extends IUser {
  /* eslint-disable  @typescript-eslint/naming-convention */
  AccessToken: string;
  /* eslint-enable  @typescript-eslint/naming-convention */
}

const poolData = environment.poolData;

/* eslint-disable  @typescript-eslint/naming-convention */
declare let LogRocket: object;
/* eslint-enable  @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * stored call response to prevent multiple calls to cognito
   */
  cachedAttribs: Promise<Array<CognitoUserAttribute>>;

  /**
   * @ignore
   */
  private _userPool: CognitoUserPool;

  /**
   * @ignore
   */
  private _cognitoUser: CognitoUser;

  /**
   * @ignore
   */
  private email: string;

  /**
   * @ignore
   */
  private password: string;

  /**
   * @ignore
   */
  private accessToken: string;

  /**
   * @ignore
   */
  private _sessionUserAttributes: Record<string, unknown>;

  /**
   * @ignore
   */
  constructor(
    private log: NGXLogger,
  ) {
    this._userPool = new CognitoUserPool(poolData);
  }

  /**
   * prevent modification to pool
   */
  get userPool() {
    return this._userPool;
  }

  /**
   * self change password
   */
  changePassword(user: CognitoUser, old: string, proposed: string) {
    const observable$ = new Observable(observer => {
      user.changePassword(old, proposed, (err: Error, data: 'SUCCESS') => {
        if (err) {
          observer.error.call(observer, err);
        } else {
          observer.next.call(observer, data);
        }
      });

      return {
        unsubscribe: () => {
          //
        }
      };
    });

    return observable$;
  }

  getUsername() {
    const user = this.getCurrentUser();

    return user && user.getUsername();
  }

  async getName() {
    const ua = await this.getUserAttributes(this.getCurrentUser());

    const first = _.find(ua, a => a.Name === 'given_name');
    const last = _.find(ua, a => a.Name === 'family_name');

    return _.join([first.Value, last.Value], ' ');
  }

  /**
   * wrapper for access token
   */
  async getAccessToken(cognitoUser: CognitoUser) {
    try {
      const session = await this.getSession(cognitoUser);

      return session.getAccessToken().getJwtToken();
    } catch (e) {
      this.log.error(e);

      return null;
    } finally {

    }
  }

  /**
   * Gets the current user if possible.
   *
   * @returns The current user.
   */
  getCurrentUser() {
    // revive from local storage if available
    let user = this._cognitoUser;

    try {
      if (!user) {
        user = this.userPool.getCurrentUser();
      }
    } catch (_e) {
      //
    }

    return this.setCurrentUser(user);
  }

  /**
   * checks if current user is admin
   */
  async getIsAdmin(cognitoUser: CognitoUser) {
    try {
      const x = await this.getUserAttributes(cognitoUser);
      let result = false;

      _.each(x, (y: CognitoUserAttribute) => {
        if (y.Name === 'custom:admin' && y.Value === 'true') {
          result = true;
        }
      });

      return result;
    } catch (_e) {
      return false;
    }
  }

  /**
   * checks if user is in lindy or admin
   */
  async getIsAdminOrLindy(cognitoUser: CognitoUser) {
    try {
      const x = await this.getUserAttributes(cognitoUser);
      let result = false;

      _.each(x, (y: CognitoUserAttribute) => {
        if ((y.Name === 'custom:admin' && y.Value === 'true') ||
          (y.Name === 'custom:admin' && y.Value === 'lindy')) {
          result = true;
        }
      });

      return result;
    } catch (_e) {
      return false;
    }
  }

  /**
   * has view orders
   */
  async hasViewOrders(cognitoUser: CognitoUser) {
    try {
      const x = await this.getUserAttributes(cognitoUser);
      let result = false;

      _.each(x, (y: CognitoUserAttribute) => {
        if ((y.Name === 'custom:view_orders' && y.Value === 'true') ||
          (y.Name === 'custom:admin' && y.Value === 'true')) {
          result = true;
        }
      });

      return result;
    } catch (_e) {
      return false;
    }
  }

  /**
   * expose email for reset-password, mostly
   */
  getLastKnownEmail() {
    return this.email;
  }

  /**
   * gets permissions
   */
  async getPermissions(_cognitoUser?: CognitoUser) {
    const cognitoUser = _cognitoUser || this.getCurrentUser();
    /* eslint-disable  @typescript-eslint/naming-convention */
    const adminObj = { Name: 'custom:admin' } as unknown;
    const companyObj = { Name: 'custom:companies' } as unknown;
    const jobObj = { Name: 'custom:jobs' } as unknown;
    /* eslint-enable  @typescript-eslint/naming-convention */

    try {
      this.log.trace('get user attribs for', cognitoUser, _cognitoUser);

      const x = await this.getUserAttributes(cognitoUser);

      this.log.trace('check admin');

      const admin = _.get(_.find(x, adminObj), 'Value') === 'true';

      this.log.trace(`admin is ${admin}`);

      if (!admin) {
        const result = {
          companies: this._unpack(_.get(_.find(x, companyObj), 'Value')),
          jobs: this._unpack(_.get(_.find(x, jobObj), 'Value')),
        };

        this.log.trace(`perms are ${result}`);

        return result;
      }

      return {
        companies: [],
        jobs: []
      };
    } catch (e) {
      // not sure whats going on
      this.log.error(e);

      return null;
    }
  }

  /**
   * wrapper for getSession
   */
  async getSession(cognitoUser: CognitoUser) {
    // TODO: check type
    return new Promise<CognitoUserSession>((resolve, reject) => {
      if (!cognitoUser) {
        return reject(new Error('no cognito user'));
      }

      cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          // alert(err.message || JSON.stringify(err));

          return reject(err);
        }

        const valid = session.isValid();
        const message = 'session validity: ' + valid;

        this.log.info(message);
        /*
        try {
          throw new Error(message);
        } catch (e) {
          console.log(e);
        }
        */

        if (!valid) {
          return reject('session invalid');
        }

        try {
          const fn = _.get(LogRocket, 'identify', _.noop);

          /* eslint-disable @typescript-eslint/naming-convention */
          const _ee = _.attempt(fn, session.getIdToken().payload.email);
          /* eslint-enable @typescript-eslint/naming-convention */
        } catch (_e) {
          // oh well
        }

        return resolve(session);
      });
    });
  }

  /**
   * session user attributes are used in initial password reset
   */
  getSessionUserAttributes() {
    const ua = this._sessionUserAttributes;

    return ua;
  }

  /**
   * Gets user attributes.
   *
   * @returns The attributes of the current user.
   */
  getUserAttributes = async (cognitoUser: CognitoUser) => {
    if (this.cachedAttribs) {
      return this.cachedAttribs;
    }

    this.cachedAttribs = new Promise<Array<CognitoUserAttribute>>(async (resolve, reject) => {
      try {
        // prereq for calling get user attributes
        await this.getSession(cognitoUser);

        // get attribs
        cognitoUser.getUserAttributes((
          e: Error,
          attributes: Array<CognitoUserAttribute>,
        ) => {
          if (e) {
            this.cachedAttribs = null;

            return reject(e);
          }

          return resolve(attributes);
        });
      } catch (e) {
        this.cachedAttribs = null;
        reject(e);
      }
    });

    return this.cachedAttribs;
  };

  /**
   * call after login to set initial password
   */
  async handleNewPassword(newPassword: string) {
    const cognitoUser = this.getCurrentUser();

    return new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        this._sessionUserAttributes,
        {
          onSuccess: (session: CognitoUserSession) => resolve(session),
          onFailure: (err: unknown) => reject(err),
        },
      );
    });
  }

  isWhiteListed() {
    // whitelist beta testing for orders
    const user = this.getCurrentUser() as unknown;
    const users = [
      'joe@roketco.com',
      // 'alex@roketco.com',
      'hello@roketco.com',
      'david@roketco.com',
      'dennis.dipalma@pjdick.com',
      'ryan.crum@trumbullcorp.com',
      'kyle.monceaux@thelindygroup.com',
    ];
    const i = _.findIndex(users,
      (u) => u === _.get(user, 'signInUserSession.idToken.payload.email'));

    return i !== -1;
  }

  /**
   * main login method
   */
  login(email: string, password: string) {
    const userData = this._getUserData(email, password);

    if (!userData.Username) {
      this.log.trace('username is null');

      return of(null);
    }

    const cognitoUser = this.setCurrentUser(new CognitoUser(userData));
    const authenticationDetails = new AuthenticationDetails({
      /* eslint-disable  @typescript-eslint/naming-convention */
      Username: this.email,
      Password: this.password
      /* eslint-enable  @typescript-eslint/naming-convention */
    });

    return new Observable((subscriber$) => {
      const callbacks = {
        onSuccess: (
          session: CognitoUserSession,
          _userConfirmationNecessary?: boolean
        ) => {
          subscriber$.next.call(subscriber$, session);
        },
        onFailure: (err) => {
          if (err.code === 'UnknownError') {
            subscriber$.error.call(subscriber$, err);
          } else if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
            alert('Invalid login credentials. Please try again.');
          } else {
            alert(err.message || JSON.stringify(err));
          }
        },
        newPasswordRequired: (
          userAttributes,
          _requiredAttributes,
        ) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;

          // save user attributes for later
          this._setSessionUserAttributes(userAttributes);

          // notify of new pass requirement
          subscriber$.error.call(subscriber$, 'newPasswordRequired');
        },
      } as IAuthenticationCallback;
      cognitoUser.authenticateUser(authenticationDetails, callbacks);

      return {
        unsubscribe: () => {
          //
        }
      };
    });
  }

  /**
   * Log out the current user.
   */
  logout() {
    const user = this.getCurrentUser();

    this.cachedAttribs = null;
    this.setCurrentUser(null);
    if (user) {
      user.signOut();
    }
  }

  /**
   * refresh token
   */
  async refreshToken(cognitoUser: CognitoUser) {
    const session = await this.getSession(cognitoUser);

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(session.getRefreshToken(), (e: Error, s) => {
        if (e) {
          return reject(e);
        } else {
          this.accessToken = s.getAccessToken().getJwtToken();

          return resolve(this.accessToken);
        }
      });
    });
  }

  /**
   * setter for current user
   */
  setCurrentUser(u: CognitoUser) {
    if (u !== this._cognitoUser) {
      this._sessionUserAttributes = null;
    }
    this._cognitoUser = u;

    return u;
  }

  /**
   * Pure magic.
   */
  _unpack(s: string) {
    const b = _.isString(s) ? s.split(',') : [];
    const a = _.map(b, t => {
      const c = t.split(' - ');

      return c[0] ? { key: c[0], value: t } : null;
    });

    return _.compact(a);
  }

  /**
   * @ignore
   */
  private _getUserData(email: string, password: string) {
    this.email = email;
    this.password = password;

    return {
      /* eslint-disable  @typescript-eslint/naming-convention */
      Username: this.email,
      Pool: this.userPool
      /* eslint-enable  @typescript-eslint/naming-convention */
    };
  }

  /**
   * @private
   * used in initial password change
   */
  private _setSessionUserAttributes(ua: Record<string, unknown>) {
    this._sessionUserAttributes = ua;
  }
}
