// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep datatable-header-cell[title="QTY"]{
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/app/datatable/datatable.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB","sourcesContent":[":host ::ng-deep datatable-header-cell[title=\"QTY\"]{\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
