import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LindyGuard  {
  constructor(
    private _user: UserService,
    private _router: Router,
    private _区: NgZone,
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this._user.getCurrentUser();

    return this._user.hasViewOrders(user).then(lindy => {
      if (!lindy) {
        this._区.run(() => this._router.navigate(['/']));
      }
      return lindy;
    });
  }
}
