import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import * as _ from 'lodash-es';

/**
 *
 */
@Component({
  selector: 'app-product-code-job-report',
  templateUrl: './product-code-job-report.component.html',
  styleUrls: ['./product-code-job-report.component.css']
})
export class ProductCodeJobReportComponent implements AfterViewInit {

  /**
   * should top bar be hidden?
   */
  @Input() hideTopBar: boolean;

  /**
   *
   */
  @Input() searchFilter = '';

  /**
   * Whether all columns should be displayed or some should be hidden.
   */
  @Input() showAll: boolean;

  /**
   *
   */
  @Input() showOrder;

  /**
   *
   */
  @Output() searchFilterUpdated = new EventEmitter();

  /**
   * @ignore
   */
  @ViewChild('boring', { static: true }) private boring;

  /**
   * @ignore
   */
  @ViewChild('hdrTpl', { static: true }) private hdrTpl;

  /**
   * @ignore
   */
  @ViewChild('numberTpl', { static: true }) private numberTpl;

  columns;
  hasMoreData;
  loadingIndicator = true;
  rows;
  total;
  private _allRows;
  private _totalLoads;
  private _totalQty;
  private _sorts = [];

  /**
   * @ignore
   */
  constructor(
  ) {
    //
  }

  get allRows() {
    return this._allRows;
  }

  get sorts() {
    return this._sorts;
  }

  get totalLoads() {
    return this._totalLoads;
  }

  get totalQty() {
    return this._totalQty;
  }

  /**
   * the main data
   */
  @Input() set allRows(r) {
    this._allRows = r;
    if (r) {
      this.rows = r;
      this.loadingIndicator = false;
    }
  }

  /**
   *
   */
  @Input() set sorts(s) {
    this._sorts = s;
  }

  /**
   *
   */
  @Input() set totalLoads(t) {
    this._totalLoads = t;
  }

  /**
   *
   */
  @Input() set totalQty(q) {
    this._totalQty = q;
  }

  /**
   * Used by name column to show name and email.
   *
   * @param  s The string to split.
   * @returns The first line of the string.
   */
  first(s: string) {
    return _.isString(s) ? s.split('\n')[0] : '';
  }

  /**
   *
   */
  last(s) {
    return _.isString(s) ? s.split('\n')[1] : '';
  }

  onSearchFilterUpdated(s: Array<unknown>) {
    this.rows = s;
    this._doUpdateTotals();
    this.searchFilterUpdated.emit(s);
  }

  ngAfterViewInit() {
    _.defer(() => {
      this.initColumns();
    });
  }

  /**
   *
   */
  quickTransform(y) {
    y.customer = (y.Customer || '') + '\n' + y.CName;
    y.product = (y.Product || '') + '\n' + y.PDescription;
    y.order = y.OrderNum + '\n' + y.ODescription;
    //    y['TicketDate'] = new Date(y['TicketDate']);

    return y;
  }

  /**
   * @ignore
   */
  _doUpdateTotals() {
    if (this.totalLoads) {
      if (this.rows.length) {
        this.totalLoads = _.sumBy(this.rows, 'Loads');
      } else {
        this.totalLoads = '0.0';
      }
    }
    if (this.totalQty) {
      if (this.rows.length) {
        this.totalQty = _.sumBy(this.rows, 'TotalQty');
      } else {
        this.totalQty = '0.0';
      }
    }
  }

  /**
   * @ignore
   */
  private initColumns() {
    // 12 * 115 = 1380
    const x = 106;
    const columns: Array<TableColumn> = [
      { name: 'Company', prop: 'customer', width: x * 3 },
      { name: 'Job', prop: 'order', width: x * 2 },
      { prop: 'destination', width: x * 2 },
      { prop: 'product', width: x * 2 },
      { prop: 'lindy', width: x },
      { prop: 'Loads', width: x },
      {
        name: 'QTY',
        prop: 'TotalQty',
        width: 195,
        frozenRight: true,
      },
    ];

    _.each(columns, c => {
      c.cellTemplate = this.hdrTpl;
    });
    columns[2].cellTemplate = this.boring;
    columns[4].cellTemplate = this.boring;
    columns[5].cellTemplate = this.boring;
    columns[6].cellTemplate = this.numberTpl;

    // pare down columns if necessary
    if (!this.showAll) {
      _.pullAt(columns, [2, 4, 5]);
      columns[1].width = x * 4;
      columns[2].width = x * 4;
      if (!this.showOrder) {
        _.pullAt(columns, [1]);
        columns[0].width = x * 5;
        columns[1].width = x * 6;
      }
    }

    // set the columns
    this.columns = columns;
  }
}
