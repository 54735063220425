import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';

// https://jsfiddle.net/koldev/cW7W5/
const saveUrl = (url: string, fileName: string) => {
  const a = document.createElement('a');

  document.body.appendChild(a);
  a.setAttribute('display', 'none');
  _.defer(() => {
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() {
    //
  }

  /**
   *
   */
  download(url: string, name: string, _mime?: string) {
    saveUrl(url, name);
  }
}
