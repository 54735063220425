import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import {
  DropdownQuestion,
  TextboxQuestion,
  LargeModalComponent,
  ModalComponent,
  DatetimeQuestion,
  AbcRadioQuestion,
  AlertComponent,
  IButton,
  IOption,
} from '@pjd-development/pjd-dsc-lib';
import { CompanyService, ICompany } from '../company.service';
import { TimeRangeService, TimeRange } from '../time-range.service';
import { PlantService, IPlant } from '../plant.service';
import * as _ from 'lodash-es';
import { TicketService, IJob } from '../ticket.service';
import { UserService } from '../user.service';
import { IReport, SavedService } from '../saved.service';
import { Router } from '@angular/router';

import {
  QuestionBase,
} from '@pjd-development/pjd-dsc-lib/lib/question/question-base';
import { HashService } from '../hash.service';
import { DropdownService } from '../dropdown.service';

/**
 *
 */
export interface IReportForm {
  companies: Array<ICompany>;
  jobs: Array<IJob>;
  timeRange: TimeRange;
  report?: { key: string };
  plants: Array<IPlant>;
  startTime: Date;
  endTime: Date;
  ticketNumbers?: string;
  byDay: string;
}

/**
 *
 */
@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.css']
})
export class ReportModalComponent implements OnInit, AfterViewInit {

  /**
   * @ignore
   * Add Report Modal
   */
  @ViewChild('createReportModal', { static: true }) private createReportModal:
    LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('editForm', { static: true }) private editForm: ModalComponent;

  /**
   * @ignore
   */
  @ViewChild('noReportType', { static: true }) private noReportType:
    AlertComponent;

  questions: Array<Array<QuestionBase<unknown>>>;
  buttons: Array<IButton>;

  /**
   * @ignore
   */
  private _currentReport: IReport;

  /**
   * @ignore
   */
  private companiesQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  private jobsQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  private plantsQuestion = new DropdownQuestion({
    key: 'plants',
    label: 'Plants',
    options: this.plantService.plants,
    placeholder: 'Select Plants',
    groupBy: 'group',
    selectableGroup: true,
    multiple: true,
  });

  /**
   * @ignore
   */
  private timeRangeQuestion: DropdownQuestion;

  private hashCode: (s: string) => number;
  private canonicalize: (obj: object) => string;

  /**
   * @ignore
   */
  constructor(
    private companyService: CompanyService,
    private dropdownService: DropdownService,
    private _hash: HashService,
    private timeRangeService: TimeRangeService,
    private plantService: PlantService,
    private reportsService: SavedService,
    private router: Router,
    private tix: TicketService,
    private userService: UserService,
  ) {
    this.canonicalize = this._hash.canonicalize;
    this.hashCode = this._hash.hashCode;

    const companyOpts = this.dropdownService
      .companyDropdownQuestionOptionsFactory();
    const jobOpts = this.dropdownService.jobDropdownQuestionOptionsFactory();

    // companies dropdown
    companyOpts.onChange = () => {
      // this.onCompaniesChange();
    };
    this.companiesQuestion = new DropdownQuestion(companyOpts);

    // jobs dropdown
    this.jobsQuestion = new DropdownQuestion(jobOpts);
  }

  /**
   * helper to get user
   */
  get cognitoUser() {
    return this.userService.getCurrentUser();
  }

  get currentReport() {
    return this._currentReport;
  }

  /**
   *
   */
  @Input() set currentReport(r: IReport) {
    this._currentReport = r;
  }

  /**
   * Add Report button handler
   */
  beginCreate = () => {

    // set up defaults
    if (this.currentReport) {
      this.resetForm(this.currentReport);
    } else {
      this.clearAll();
    }

    // click invisible button
    this.createReportModal.open();
  };

  /**
   * Runs after DOM is ready.
   */
  ngAfterViewInit() {
    this.noReportType.destroy();
  }

  /**
   * Runs after constructor.
   */
  ngOnInit() {
    this.initEditQuestions();
    this.buttons = [
      {
        text: 'Submit',
        click: this.submit,
      },
      {
        text: 'Clear All',
        click: this.clearAll,
      }
    ];
  }

  /**
   * @ignore
   */
  private clearAll = () => {
    this.resetForm({
      byDay: false,
      reportType: 'at',
      timeRange: 'pday',
      username: this.userService.getUsername(),
      ticket: [],
    });
  };

  /**
   * @ignore
   */
  private convertTimeRange(form: IReportForm) {
    const key = _.get(form, 'timeRange.key');

    return key === 'cust' ? this.timeRangeService.packCustom(form) : key;
  }

  /**
   * @ignore
   */
  private async initEditQuestions() {
    this.companiesQuestion = new DropdownQuestion({
      key: 'companies',
      label: 'Companies',
      options: [],
      placeholder: 'Select Companies',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        this.onCompaniesChange();
      },
      compareWith: this.dropdownService.cmpFn,
    });
    this.jobsQuestion = new DropdownQuestion({
      key: 'jobs',
      label: 'Jobs',
      options: [],
      placeholder: 'Select Jobs',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      compareWith: this.dropdownService.cmpFn,
    });
    this.timeRangeQuestion = new DropdownQuestion({
      key: 'timeRange',
      label: 'Time Range',
      options: this.timeRangeService.timeRanges,
      placeholder: 'Select Timestamp',
      onChange: () => {
        this.onTimeRangeChange();
      },
      compareWith: this.dropdownService.cmpFn,
      clearable: false,
    });

    // ensure plants are loaded
    await this.plantService.loadedPromise;

    this.plantsQuestion = new DropdownQuestion({
      key: 'plants',
      label: 'Plants',
      options: this.plantService.plants,
      placeholder: 'Select Plants',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        this.onPlantChange();
      },
      compareWith: this.dropdownService.cmpFn,
    });
    this.questions = [
      [new DropdownQuestion({
        key: 'report',
        label: 'Report Type',
        options: this.reportsService.reportTypes,
        placeholder: 'Choose a Report Type',
        onChange: () => {
          // this.onReportChange();
        },
        clearable: false,
        compareWith: this.dropdownService.cmpFn,
      })],
      [
        this.plantsQuestion,
      ],
      [
        this.timeRangeQuestion,
      ],
      [
        new AbcRadioQuestion({
          key: 'byDay',
          label: 'Breakdown By Day',
          options: [
            { key: true, value: 'On' },
            { key: false, value: 'Off' },
          ],
        })
      ],
      [
        new DatetimeQuestion({
          key: 'startTime',
          label: 'Starting Date/Time',
          placeholder: 'Date and time',
          afterPickerClosed: () => { this.onStartTimeChange(); },
        }),
        new DatetimeQuestion({
          key: 'endTime',
          label: 'Ending Date/Time',
          placeholder: 'Date and time',
          afterPickerClosed: () => { this.onEndTimeChange(); },
        })
      ],
      [
        this.companiesQuestion,
      ],
      [
        this.jobsQuestion,
      ],
      [
        new TextboxQuestion({
          key: 'ticketNumbers',
          label: 'Ticket Number(s)',
          placeholder: 'Enter ticket number(s)',
        }),
      ],
    ];
  }

  /**
   * @ignore
   */
  private keys(a: Array<IOption>) {
    return _.compact(_.map(a, c => c.key));
  }

  /**
   * @ignore
   */
  private onCompaniesChange() {
    this.updateDropdowns(true);
  }

  /**
   * @ignore
   */
  private onEndTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this.onTimeRangeChange();
  }

  private onPlantChange() {
    this.onTimeRangeChange();
  }

  /**
   * @ignore
   */
  private onStartTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this.onTimeRangeChange();
  }

  /**
   * @ignore
   * Handles when the time range in the form is changed.
   */
  private onTimeRangeChange(isLindy?: boolean) {
    const form = this.editForm.form; // type info?
    const values = form.value;
    const timeRange = values.timeRange;
    const converted = this.convertTimeRange(values);
    const timestamp = this.timeRangeService.computeTimestamp(converted);
    const lindy = this.companyService.createLindy();
    const json = {
      companies: isLindy ? [lindy] : [{ group: this.tix.allCompanies }],
      jobs: [{ group: this.tix.allJobs }],
    } as Record<string, unknown>;

    if (timeRange !== 'cust') {
      json.startTime = timestamp[0];
      json.endTime = timestamp[1];
    }
    form.patchValue(json);
    this.updateDropdowns();
  }

  /**
   * @ignore
   */
  private ticketListToForm(tl: IReport) {
    const r = this.timeRangeService.computeTimestamp(tl.timeRange);
    const companies = _.map(tl.companies, this.companyService.createCompany);
    const jobs = _.map(tl.jobs, this.tix.createJob);
    const plants = _.map(tl.plants, this.plantService.findPlant);
    const form: IReportForm = {
      plants: plants.length ? plants : [{ group: this.plantService.allPlants }],
      startTime: r[0],
      endTime: r[1],
      timeRange: this.timeRangeService.findTime(tl.timeRange),
      companies: companies.length ? companies : [{ group: this.tix.allCompanies }] as ICompany[],
      jobs: jobs.length ? jobs : [{ group: this.tix.allJobs }] as ICompany[],
      byDay: tl.byDay + '',
      report: { key: tl.reportType },
      ticketNumbers: _.join(tl.ticket),
    };

    return form;
  }

  /**
   * @ignore
   */
  private resetForm(current: IReport) {
    const form = this.ticketListToForm(current);

    this.editForm.form.patchValue(form);
    this.updateDropdowns();
  }

  /**
   * @ignore
   */
  private submit = () => {
    const tl = this.toTicketList(this.editForm.form.value);

    // TODO: add real validation
    if (!tl.reportType) {
      this.noReportType.show();

      return;
    }
    this.createReportModal.close();
    this.reportsService.currentReport = tl;
    this.router.navigate(['/reports/_' + this.hashCode(this.canonicalize(tl))]);
  };

  /**
   * @ignore
   */
  private toTicketList = (form: IReportForm): IReport => {
    const jobs = this.keys(form.jobs);
    const c = this.keys(form.companies);
    const plants = this.keys(form.plants) as unknown[] as number[];
    const ticket = _.isString(form.ticketNumbers) ? form.ticketNumbers.split(',') : null;
    const tl: IReport = {
      reportType: form.report && form.report.key,
      jobs, // jobs.length ? jobs : this.keys(this.jobsQuestion.options),
      companies: c, // c.length ? c : this.keys(this.companiesQuestion.options),
      plants, // plants.length ? plants : this.keys(this.plantsQuestion.options),
      timeRange: this.convertTimeRange(form),
      username: this.userService.getUsername(),
      ticket, // : form.ticketNumbers ? form.ticketNumbers.split(',') : null,
      byDay: form.byDay === 'true',
    };

    return tl;
  };

  /**
   * @ignore
   */
  private async updateDropdowns(onlyJobs?: boolean) {
    const perms = await this.userService.getPermissions(this.cognitoUser);

    if (perms.companies.length === 0 && perms.jobs.length === 0) {
      await this._populateDropdowns(onlyJobs);
    } else {
      this.companiesQuestion.options = perms.companies;
      this.jobsQuestion.options = perms.jobs;
    }
  }

  /**
   * @ignore
   */
  private async _populateDropdowns(onlyJobs?: boolean) {
    const values = this.editForm.form.value;
    // const start = values.startTime;
    // const end = values.endTime;
    const options = {} as Record<string, unknown>;
    const tl = this.toTicketList(values);

    options.plant = tl.plants;
    if (onlyJobs) {
      options.company = tl.companies;
    }
    options.timeStamp = this.timeRangeService.computeTimestamp(tl.timeRange);
    if (_.isArray(options.timeStamp) && options.timeStamp.length === 2) {
      const c = await this.tix.populate(options);

      if (!onlyJobs) {
        this.companiesQuestion.options = c.companies;
      }
      this.jobsQuestion.options = c.jobs;
    }
  }
}
