import {
  Component,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SavedService, IReport } from '../saved.service';
import * as _ from 'lodash-es';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { HeaderBarService } from '../header-bar.service';
import { TicketService } from '../ticket.service';
import { TimeRangeService } from '../time-range.service';
import { ReportModalComponent } from '../report-modal/report-modal.component';
import { SuccessService } from '../success.service';
import { CsvService } from '../csv.service';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface DaySummary {
  dailyRecords: Array<unknown>;
  first: string;
  last: string;
  dailyQty: number;
  dailyLoads?: number;
}

/**
 *
 */
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  /**
   * @ignore
   */
  @ViewChild('reportModal', { static: true })
  private reportModal: ReportModalComponent;

  /**
   * data for dropdown component
   */
  dropDownLinksTwo: unknown[]; // IDropDownLink[];

  /**
   * should loading indicator show
   */
  loadingIndicator: boolean;

  /**
   * The rows if not byDay.
   */
  rows;

  searchFilter = '';
  sorts = [];
  totalLoads;
  totalQty;

  #data: Array<DaySummary>;
  #currentReport;
  #rows;
  #item: string;

  /**
   * @ignore
   */
  constructor(
    private csvService: CsvService,
    private deviceService: DeviceDetectorService,
    private headerBar: HeaderBarService,
    private savedService: SavedService,
    private router: Router,
    private successService: SuccessService,
    private ticketService: TicketService,
    private timeRangeService: TimeRangeService,
    private userService: UserService,
    private _区: NgZone,
  ) {
  }

  /**
   * The current report.
   */
  get currentReport() {
    return this.#currentReport;
  }

  /**
   * The data if we are doing byDay.
   */
  get data() {
    return this.#data;
  }

  /**
   * gets the loaded route param
   */
  get item() {
    return this.#item;
  }

  /**
   * The current route
   */
  @Input() set item(u: string) {
    this.onEvent({ item: u });
    _.defer(() => {
      this.#item = u;
    });
  }

  /**
   * Setter for the current report.
   */
  set currentReport(c: IReport) {
    this.#currentReport = c;
    if (c) {
      const json = {
        timestamp: this.timeRangeService.computeTimestamp(c.timeRange),
        company: c.companies,
        job: c.jobs,
        plant: c.plants,
        byDay: c.byDay,
      };
      const serviceMap = {
        at: this.ticketService.getSalesByProductCode,
        ay: this.ticketService.getSalesByProductCodeAndJob,
        lt: this.ticketService.getSalesSummary
      };

      this._initHeaderBar();
      this.loadingIndicator = true;
      this.data = null;

      _.defer(async () => {
        const x = await serviceMap[c.reportType].bind(this.ticketService)(json);

        this.loadingIndicator = false;
        if (_.isArray(x.data[0])) {
          this.#rows = _.clone(x.data[0]);
          this.rows = x.data[0];
          this.totalLoads = x.totalLoads;
          this.totalQty = x.totalQty;
        } else {
          this.data = x.data;
        }
      });
    } else {
      this._initNoReportHeaderBar();
    }
  }

  /**
   * The data if we are doing byDay.
   */
  set data(d) {
    this.#data = d;
    _.defer(() => {
      if (d) {
        this._initDropDownLinksByDay();
      } else {
        this._initDropDownLinks();
      }
    });
  }

  date(i: number) {
    const start =
      this.timeRangeService.computeTimestamp(this.currentReport.timeRange)[0];

    return start.setDate(start.getDate() + i);
  }

  /**
   *
   */
  onSearchFilterUpdated = (s: string) => {
    this.searchFilter = s;
  };

  /**
   * Angular lifecycle hook.
   */
  ngOnInit() {
    this._initDropDownLinks();
  }

  /**
   * Handles hack for showing no data on by day collapse opening
   */
  onOpened(i: number) {
    const d = this.data[i];

    if (d.dailyRecords.length === 0) {
      d.dailyRecords = [];
    }
  }

  /**
   * @ignore
   */
  private _createDropDownLink(name: string, f: unknown) {
    return {
      name,
      disabled: false,
      active: false,
      onClick: f,
    };
  }

  /**
   * @ignore
   */
  private _initDropDownLinks() {
    const isDesktopDevice = this.deviceService.isDesktop();
    const exportOption = [];

    if (isDesktopDevice) {
      exportOption.push(this._createDropDownLink('Export to Excel', this.#generateCSV));
    }
    this.dropDownLinksTwo = [
      this._createDropDownLink('Add to Saved', this.#save),
      ...exportOption,
      this._createDropDownLink('Print', window.print.bind(window)),
      this._createDropDownLink('Reset Table Sorting', this.#clearSorts),
    ];
  }

  /**
   * @ignore
   */
  private _initDropDownLinksByDay() {
    this.dropDownLinksTwo = [
      this._createDropDownLink('Add to Saved', this.#save),
      this._createDropDownLink('Print', window.print.bind(window)),
      this._createDropDownLink('Reset Table Sorting', this.#clearSorts),
    ];
  }

  private _initHeaderBar() {
    this.headerBar.currentTicketList = this.currentReport;
    this.headerBar.title = this.savedService.findReportType(
      this.currentReport.reportType).value;
    // this.headerBar.hideEditFilters = true;
    this.headerBar.beginEdit = this.reportModal.beginCreate;
  }

  /**
   * @ignore
   */
  private _initNoReportHeaderBar() {
    _.defer(() => {
      this.headerBar.beginCreateReport = this.reportModal.beginCreate;
    });
  }

  /**
   * @ignore
   */
  private async _findReport(createdDate: number) {
    const user = this.userService.getCurrentUser();

    try {
      const r = await this.savedService.find(user, createdDate) as IReport;

      this.currentReport = r;
    } catch (_e) {
      this._区.run(() => this.router.navigate(['/response-code'], {
        queryParams: {
          code: 404,
          message: 'Saved report not found'
        }
      }));
    }
  }

  /**
   * @ignore
   * Subscribes to route param changes. Used to implement various
   * functionality. Also loads ticket list data.
   */
  private onEvent(routeParams: unknown) {
    const url = _.get(routeParams, 'item', '');
    const createdDate = _.parseInt(url);

    if (!url) {
      this._区.run(() => {
        this.router.navigate(['/reports/create']);
      });

      return;
    }
    if (!_.isNaN(createdDate)) {
      this._findReport(createdDate);
    } else if (url === 'create') {
      this.savedService.currentReport = null;
    } else {
      if (this.savedService.currentReport) {
        // placeholder, we load temporary data below
      } else {
        this._区.run(() => this.router.navigate(['/reports/create']));
      }
    }
    _.defer(() => {
      this.currentReport = this.savedService.currentReport;
    });
  }

  #clearSorts = () => {
    this.sorts = [];
  };

  #generateCSV = () => {
    this.csvService.generate(this.#rows);
  };

  #save = async () => {
    try {
      await this.savedService.addReport(this.currentReport);

      this.successService.showMessage('', 'Report Saved');
    } catch (_e) {
      throw new Error(_e);
    }
  };
}
