import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-header',
  templateUrl: './datatable-header.component.html',
  styleUrls: ['./datatable-header.component.css']
})
export class DatatableHeaderComponent {

  /**
   * The state of the checkbox.
   */
  @Input() autoUpdate: boolean;

  /**
   * The state of the multi select checkbox
   */
  @Input() multiSelectEnabled: boolean;

  /**
   * Actions for menu
   */
  @Input() dropDownLinksTwo: unknown[];

  /**
   * sets the bulk downloading state
   */
  @Input() downloading = false;

  /**
   * Last time data were refreshed.
   */
  @Input() lastUpdatedTime: Date;

  /**
   * Whether or not to show the auto refresh part of the header bar.
   */
  @Input() showAutoRefresh: boolean;

  /**
   * Whether or not to show the auto refresh part of the header bar.
   */
  @Input() showMultiSelect: boolean;

  /**
   * flag to show search
   */
  @Input() showSearch = true;

  /**
   * Emits the search string as it changes.
   */
  @Output() searchEventListener = new EventEmitter<string>();

  /**
   *
   */
  @Output() autoUpdateChange = new EventEmitter();

  /**
   *
   */
  @Output() multiSelectEnabledChange = new EventEmitter();

  /**
   * used by action drop down
   */
  dropDownStylesTwo = [
    {
      type: 'outline-base-green',
      size: 'normal',
      name: 'Actions',
      position: 'right'
    },
  ];

  /**
   * @ignore
   */
  private _bulkDownloadText: string;

  /**
   * @ignore
   */
  constructor() {
    //
  }

  get bulkDownloadText() {
    if (this.downloading) {
      return 'Downloading Tickets…';
    }

    return this._bulkDownloadText;
  }

  /**
   * the text to show below checkbox
   */
  @Input()
  set bulkDownloadText(t: string) {
    this._bulkDownloadText = t;
  }

  /**
   * multiSelectEnabled change handler
   */
  onMSEC(e: boolean) {
    this.bulkDownloadText = null;
    this.multiSelectEnabledChange.emit(e);
  }

  /**
   * handle search event
   */
  onSearch(event: Event) {
    this.onSearchEvent(event);
  }

  /**
   * Unwrap event
   */
  onSearchEvent(e) {
    const el = e.srcElement as HTMLInputElement;
    const value = el.value;

    this.searchEventListener.emit(value);
  }
}
