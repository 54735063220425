import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { environment } from '../environments/environment';
import { forkJoin, from, Observable } from 'rxjs';
import { BackendService } from './backend.service';
import { IOption } from '@pjd-development/pjd-dsc-lib';
import { ITicket } from './ticket.service';

const root = environment.backendRoot;

export interface ICompany extends IOption {
  group?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  /**
   * @ignore
   */
  private allCompanies$: Observable<Array<unknown>>;

  /**
   * @ignore
   */
  constructor(
    private backend: BackendService,
  ) {
  }

  createCompany(key: string) {
    return {
      key,
      value: null,
    };
  }

  createLindy() {
    const company = this.createCompany('27106');

    company.value = company.key + ' - LINDY PAVING INC';

    return company;
  }

  /**
   * Returns array of company objects for given ids.
   *
   * @param companies The list of ids.
   * @returns List of company objects.
   */
  findCompanies(companies: Array<string>) {
    const result = _.map(companies, company => this.findCompany(company));

    return forkJoin(result);
  }

  /**
   * Can be used to lookup a company object.
   *
   * @param companyNumber The id of the company.
   * @returns Observable with the located company object.
   */
  findCompany(companyNumber: string) {
    return this.getCompanies().pipe(
      map(companies => _.find(companies, (c: ICompany) => c.key === companyNumber)),
    );
  }

  /**
   * @ignore
   * Function for getting all companies. Useful for performing lookups on ids.
   * @returns An observable which emits all companies.
   */
  private getCompanies() {
    if (!this.allCompanies$) {
      this.allCompanies$ = this.requestCompanies().pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
    }

    return this.allCompanies$;
  }

  /**
   * @ignore
   */
  private requestCompanies() {
    const promise = this.backend.genericGet(root + '/api/company', {},
      true) as Promise<{ recordset: ITicket[] }>;

    return from(promise).pipe(
      map(x => _.get(x, 'recordset')),
      map(x => {
        _.each(x, y => {
          const yy = y as unknown as IOption;

          yy.key = y.Customer;
          yy.value = y.CName;
        });

        return x;
      }),
    );
  }
}
