import {
  Component, ViewChild, OnInit, ChangeDetectorRef,
  Input, Output, EventEmitter
} from '@angular/core';
import {
  LargeModalComponent,
  ModalComponent,
  IButton,
  DropdownQuestion,
  DatetimeQuestion,
  IOption,
} from '@pjd-development/pjd-dsc-lib';
import { NGXLogger } from 'ngx-logger';
import { HeaderBarService } from '../header-bar.service';
import * as _ from 'lodash-es';
import { TimeRange, TimeRangeService } from '../time-range.service';
import { IOrderList, SavedService } from '../saved.service';
import { IPlant, PlantService } from '../plant.service';
import { OrdersService } from '../orders.service';
import { UserService } from '../user.service';
import { DropdownService } from '../dropdown.service';

/**
 *
 */
export interface IOrdersEditFilterForm {
  timeRange: TimeRange;
  //  report?;
  plants: Array<IPlant>;
  startTime: Date;
  endTime: Date;
  shift?: unknown;
  status?: IOption[];
  //  ticketNumbers?;
}

@Component({
  selector: 'app-edit-schedule-modal',
  templateUrl: './edit-schedule-modal.component.html',
  styleUrls: ['./edit-schedule-modal.component.css']
})
export class EditScheduleModalComponent implements OnInit {

  @Input() title = 'Edit Schedule Filters';
  @Input() showDate = false;

  @Output() orderListChanged = new EventEmitter<IOrderList>();

  /**
   * @ignore
   */
  @ViewChild('editForm', { static: true })
  private editForm: ModalComponent;

  /**
   * @ignore
   */
  @ViewChild('editFilters', { static: true })
  private editFilters: LargeModalComponent;

  /**
   * buttons for edit detail modal
   */
  buttons: Array<IButton>;

  /**
   * questions for search modal
   */
  questions: Array<Array<unknown>>;

  hasDefault = false;

  /**
   * @ignore
   */
  #plantsQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #statusQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #timeRangeQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #currentTicketList: IOrderList;

  constructor(
    private log: NGXLogger,
    private _dropdownService: DropdownService,
    public headerBar: HeaderBarService,
    private cd: ChangeDetectorRef,
    private plantService: PlantService,
    private timeRangeService: TimeRangeService,
    private _orders: OrdersService,
    private reportService: SavedService,
    private userService: UserService,
  ) {

  }

  /**
   * @ignore
   */
  private get currentTicketList() {
    return this.#currentTicketList;
  }
  private set currentTicketList(tl: IOrderList) {
    this.log.debug('setting currentticketlist');
    this.#currentTicketList = tl;
    this.headerBar.currentTicketList = tl;
    this.orderListChanged.emit(tl);
  }

  ngOnInit() {
    this.log.debug('ngoninit edit schedule modal');
    this.headerBar.beginEdit = this.#onBeginEdit;
    _.defer(() => {
      this.headerBar.title = null;
      this.cd.detectChanges();
    });
    this.buttons = [
      {
        text: 'Apply',
        click: this.#apply
      },
      {
        text: 'Clear All',
        click: this.#clearAll
      }
    ];
    this._initEditQuestions();

    // standard
    const restored = this.headerBar.popSavedTicketList();

    _.defer(() => {
      if (restored) {
        this.currentTicketList = restored;
      } else {
        this._doDefaultTicketList();
      }
    });
  }

  async removeDefault() {
    const user = this.userService.getCurrentUser();
    const d = await this.reportService.getDefaultOrdersList(user);

    try {
      await this.reportService.remove(d);
      this.hasDefault = false;
    } catch (e) {
      console.error(e);
    }
  }

  async setDefault() {
    const tl = this._toTicketList(this.editForm.form.value);

    tl.isDefaultOrder = true;

    try {
      await this.reportService.addTicketList(tl);
      this.hasDefault = true;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @ignore
   */
  private _convertShift(form: IOrdersEditFilterForm) {
    const key = _.get(form, 'shift.key');

    return key;
  }

  /**
   * @ignore
   */
  private _convertTimeRange(form: IOrdersEditFilterForm) {
    const key = _.get(form, 'timeRange.key');

    return key === 'cust' ? this._packCustom(form) : key;
  }

  /**
   * @ignore
   */
  private async _doDefaultTicketList() {
    this.log.debug('in dodefaultticketlist');

    const user = this.userService.getCurrentUser();
    const defaultTicketList =
      await this.reportService.getDefaultOrdersList(user);

    this.hasDefault = defaultTicketList.isDefaultOrder;
    this.currentTicketList = defaultTicketList;
  }

  /**
   * @ignore
   */
  private async _initEditQuestions() {
    const group = 'All Statuses';

    this.#timeRangeQuestion = this._dropdownService.orderTimeRangeQuestionFactory(
      () => this._onTimeRangeChange()
    );
    this.#plantsQuestion = await this._dropdownService.getQuestion(() => this._onPlantChange());
    this.#statusQuestion = new DropdownQuestion({
      key: 'status',
      label: 'Status',
      options: _.map(this._orders.statuses, s => ({ key: s[0], value: s, group, })),
      placeholder: 'Select a Status',
      onChange: () => {
        this._onStatusChange();
      },

      // options: this.plantService.plants,
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      compareWith: this._dropdownService.cmpFn,
    });
    this.questions = [
      [
        this.#plantsQuestion,
      ],
      [
        this.#timeRangeQuestion,
      ],
      [
        new DatetimeQuestion({
          key: 'startTime',
          label: 'Starting Date',
          placeholder: 'Date',
          pickerType: 'calendar',
          afterPickerClosed: () => { this._onStartTimeChange(); },
        }),
        new DatetimeQuestion({
          key: 'endTime',
          label: 'Ending Date',
          placeholder: 'Date',
          pickerType: 'calendar',
          afterPickerClosed: () => { this._onEndTimeChange(); },
        })
      ],
      [
        this.#statusQuestion,
      ],
      [new DropdownQuestion({
        key: 'shift',
        label: 'Shift',
        options: _.map(this._orders.shifts, s => ({ key: s[4], value: s })),
        placeholder: 'Select a Shift',
        onChange: () => {
          this._onShiftChange();
        },
      })],
    ];
  }

  /**
   * @ignore
   */
  private _keys(a: Array<IOption>) {
    return _.compact(_.map(a, c => c.key));
  }

  /**
   * @ignore
   */
  private _onEndTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this._onTimeRangeChange();
  }

  /**
   * @ignore
   */
  private _onPlantChange() {
    this._onTimeRangeChange();
  }

  /**
   * @ignore
   */
  private _onShiftChange() {
    //
  }

  /**
   * @ignore
   */
  private _onStatusChange() {
    //
  }

  /**
   * @ignore
   */
  private _onStartTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this._onTimeRangeChange();
  }

  /**
   * @ignore
   * Handles when the time range in the form is changed.
   */
  private _onTimeRangeChange(_isLindy?: boolean) {
    const form = this.editForm.form; // type info?
    const values = form.value;
    const timeRange = values.timeRange;
    const converted = this._convertTimeRange(values);
    const timestamp = this.timeRangeService.computeTimestamp(converted);
    const json = {
    } as Record<string, unknown>;

    // NOTE: was just timeRange
    if (timeRange.key !== 'cust') {
      json.startTime = timestamp[0];
      json.endTime = timestamp[1];
    }

    form.patchValue(json);
    this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private _packCustom(form: IOrdersEditFilterForm) {
    return 'cust' + form.startTime?.valueOf() + ','
      + form.endTime?.valueOf();
  }

  /**
   * @ignore
   */
  private _resetForm(current: IOrderList) {
    const form = this._ticketListToForm(current);

    this.editForm.form.patchValue(form);
    this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private _ticketListToForm(tl: IOrderList) {
    const r = this.timeRangeService.computeTimestamp(tl.timeRange);
    const plants = _.map(tl.plants, this.plantService.findPlant);
    const status = _.map(tl.status, this._orders.findStatus);
    const form: IOrdersEditFilterForm = {
      plants: plants.length ? plants : [{ group: this.plantService.allPlants }],
      startTime: r[0],
      endTime: r[1],
      timeRange: this.timeRangeService.findOrderTime(tl.timeRange),
      status: status.length ? status : [{ group: 'All Statuses' }] as IPlant[],
      shift: this._orders.findShift(tl.shift),
    };

    return form;
  }

  /**
   * @ignore
   */
  private _toTicketList(form: IOrdersEditFilterForm): IOrderList {
    const plants = this._keys(form.plants) as unknown[] as number[];
    const statuses = this._keys(form.status);
    const tl: IOrderList = {
      plants, // plants.length ? plants : this.keys(this.plantsQuestion.options),
      timeRange: this._convertTimeRange(form),
      username: this.userService.getUsername(),
      status: statuses,
      shift: this._convertShift(form),
      // ticket,
    };
    this.log.debug('converted order list', tl);

    return tl;
  }

  /**
   * @ignore
   */
  private async _updateDropdowns(_onlyJobs?: boolean) {
    try {
    } catch (e) {
      this.log.error(e, 'caught in ',
        EditScheduleModalComponent.name, this._updateDropdowns.name);
    }
  }

  #apply = () => {
    const tl = this._toTicketList(this.editForm.form.value);

    this.editFilters.close();
    this.currentTicketList = tl;
  };

  #clearAll = () => {
    const form = this.editForm.form;

    form.reset({ timeRange: { key: 'cday' } });
  };

  #onBeginEdit = () => {
    // load companies into modal
    this._resetForm(this.currentTicketList);
    this.editFilters.open();
  };
}
