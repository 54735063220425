import {
  Component, ViewChild, OnInit, ChangeDetectorRef,
  Input, Output, EventEmitter, NgZone
} from '@angular/core';
import {
  LargeModalComponent,
  ModalComponent,
  IButton,
  DropdownQuestion,
  DatetimeQuestion,
  AbcRadioQuestion,
  IOption,
} from '@pjd-development/pjd-dsc-lib';
import { NGXLogger } from 'ngx-logger';
import { HeaderBarService } from '../header-bar.service';
import * as _ from 'lodash-es';
import { ICreateSchedule } from '../saved.service';
import { PlantService } from '../plant.service';
import { OrdersService } from '../orders.service';
import { IPlant } from '../plant.service';
import { Router } from '@angular/router';
import { DropdownService } from '../dropdown.service';
import { parse } from 'date-fns';

/**
 *
 */
export interface ICreatePlantScheduleReportForm {
  plants: Array<IPlant>;
  startTime: Date;
  shift: string;
  status: Array<IOption>;
}

@Component({
  selector: 'app-create-plant-schedule-report-modal',
  templateUrl: './create-plant-schedule-report-modal.component.html',
  styleUrls: ['./create-plant-schedule-report-modal.component.css']
})
export class CreatePlantScheduleReportModalComponent implements OnInit {

  @Input() title = 'Create Report';
  @Input() showDate = true;

  @Output() orderListChanged = new EventEmitter<ICreateSchedule>();

  /**
   * @ignore
   */
  @ViewChild('editForm', { static: true })
  private editForm: ModalComponent;

  /**
   * @ignore
   */
  @ViewChild('editFilters', { static: true })
  private editFilters: LargeModalComponent;

  /**
   * buttons for edit detail modal
   */
  buttons: Array<IButton>;

  /**
   * questions for search modal
   */
  questions: Array<Array<unknown>>;

  /**
   * list of shifts (one word only)
   */
  shifts = _.map(this._orders.shifts,
    s => ({ key: s[4], value: s.split(' ')[0] }));

  /**
   * @ignore
   */
  #plantsQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #statusQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #currentTicketList: ICreateSchedule = {
    status: [],// ['D', 'S', 'C','A'],
    shift: this.shifts[0].key,
    date: new Date().toISOString().split('T')[0],
    plants: [],
  };

  constructor(
    private log: NGXLogger,
    private _dropdownService: DropdownService,
    private headerBar: HeaderBarService,
    private cd: ChangeDetectorRef,
    private plantService: PlantService,
    private _orders: OrdersService,
    private router: Router,
    private _区: NgZone,
  ) {

  }

  /**
   * @ignore
   */
  private get currentTicketList() {
    return this.#currentTicketList;
  }

  private set currentTicketList(tl: ICreateSchedule) {
    this.#currentTicketList = tl;
    //    this.headerBar.currentTicketList = tl;
    this.orderListChanged.emit(tl);
  }

  ngOnInit() {
    this.headerBar.beginCreateSchedule = this.#onBeginEdit;
    // this.headerBar.beginEdit = this.#onBeginEdit;
    _.defer(() => {
      // this.headerBar.title = null;
      this.cd.detectChanges();
    });
    this.buttons = [
      {
        text: 'Create',
        click: this.#apply
      },
    ];
    this._initEditQuestions();
  }

  /**
   * @ignore
   */
  private async _initEditQuestions() {
    const group = 'All Statuses';

    this.#plantsQuestion = await this._dropdownService
      .getQuestion(() => this._onPlantChange());
    this.#statusQuestion = new DropdownQuestion({
      key: 'status',
      label: 'Status',
      options: _.map(
        this._orders.statuses, s => ({ key: s[0], value: s, group, })),
      placeholder: 'Select a Status',
      onChange: () => {
        this._onStatusChange();
      },

      // options: this.plantService.plants,
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      compareWith: this._dropdownService.cmpFn,
    });
    this.questions = [
      [
        new DatetimeQuestion({
          key: 'startTime',
          label: 'Starting Date',
          placeholder: 'Date',
          pickerType: 'calendar',
          afterPickerClosed: () => { this._onStartTimeChange(); },
        }),
        new AbcRadioQuestion({
          key: 'shift',
          label: 'Shift',
          options: [this.shifts[1], this.shifts[2], this.shifts[0]],
          onChange: (_e: unknown) => {
            this._onShiftChange();
          },
          required: true,
        }),
      ],
      [
        this.#statusQuestion,
      ],
      [
        this.#plantsQuestion,
      ],
    ];
  }

  /**
   * @ignore
   */
  private _keys(a: Array<IOption>) {
    return _.compact(_.map(a, c => c.key));
  }

  /**
   * @ignore
   */
  private _onPlantChange() {
    //
  }

  /**
   * @ignore
   */
  private _onShiftChange() {
    //
  }

  /**
   * @ignore
   */
  private _onStatusChange() {
    //
  }

  /**
   * @ignore
   */
  private _onStartTimeChange() {
    //  this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private _resetForm(current: ICreateSchedule) {
    this.log.debug('current order list', current);

    const form = this._ticketListToForm(current);

    this.editForm.form.patchValue(form);
    // this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private _ticketListToForm(tl: ICreateSchedule) {
    const parsed = parse(tl.date, 'yyyy-MM-dd', new Date());
    const plants = _.map(tl.plants, this.plantService.findPlant) as unknown[] as IPlant[];
    const status = _.map(tl.status, this._orders.findStatus);
    const form: ICreatePlantScheduleReportForm = {
      plants: plants.length ? plants : [{ group: this.plantService.allPlants }] as IPlant[],
      startTime: parsed,
      status: status.length ? status : [{ group: 'All Statuses' }] as IPlant[],
      shift: tl.shift || 'S',
    };

    return form;
  }

  /**
   * @ignore
   */
  private _toTicketList(form: ICreatePlantScheduleReportForm): ICreateSchedule {
    const plants = this._keys(form.plants);
    const statuses = this._keys(form.status);
    const tl: ICreateSchedule = {
      plants, // plants.length ? plants : this.keys(this.plantsQuestion.options),
      //   username: this.userService.getUsername(),
      status: statuses,
      date: (form.startTime || new Date()).toISOString().split('T')[0],
      shift: form.shift,
      // ticket,
    };
    this.log.debug('converted order list', tl);

    return tl;
  }

  #apply = () => {
    const tl = this._toTicketList(this.editForm.form.value);
    const { date, status, plants, shift } = tl;

    this.editFilters.close();
    this.currentTicketList = tl;
    this._区.run(() => this.router.navigate(['/plant-schedule-summary'], {
      queryParams: {
        date, status, plants, shift
      },
    }));
  };

  #onBeginEdit = () => {
    // load companies into modal
    this._resetForm(this.currentTicketList);
    this.editFilters.open();
  };
}
