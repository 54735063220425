import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-report-collapse',
  templateUrl: './report-collapse.component.html',
  styleUrls: ['./report-collapse.component.css']
})
export class ReportCollapseComponent implements AfterViewInit {

  @Input() date = new Date();

  @Input() first = new Date();

  @Input() last = new Date();

  @Input() openInitial;

  @Output() opened = new EventEmitter();

  @ViewChild('button', { static: true }) private button: ElementRef;

  id = _.uniqueId('report-collapse-');

  private _open: boolean;

  constructor(
  ) {
    //
  }

  get open() {
    return this._open;
  }

  set open(b) {
    this._open = b;
    if (b) {
      this.opened.emit();
    }
  }

  ngAfterViewInit() {
    if (this.openInitial) {
      this.button.nativeElement.click();
    }
  }
}
