import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  NgZone,
  Input,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, } from '@angular/router';
import * as _ from 'lodash-es';
import {
  LargeModalComponent,
  ModalComponent,
  ConfirmationModalComponent,
  IButton,
  DropdownQuestion,
  TextboxQuestion,
  DatetimeQuestion,
  IOption,
} from '@pjd-development/pjd-dsc-lib';
import { DeviceDetectorService } from 'ngx-device-detector';

// local imports
import { StandardOptions, ITicket, PopulateOptions } from '../ticket.service';
import { SavedService, ITicketList } from '../saved.service';
import { IUserPreferences, PreferencesService } from '../preferences.service';
import {
  DatatableComponent, IActivateEvent,
} from '../datatable/datatable.component';
import { HeaderBarService } from '../header-bar.service';
import { UserService } from '../user.service';
import { MediaService } from '../media.service';
import { TimeRangeService, TimeRange } from '../time-range.service';
import { PlantService, IPlant } from '../plant.service';
import { ICompany, CompanyService } from '../company.service';
import { IJob, TicketService } from '../ticket.service';
import { SuccessService } from '../success.service';
import { CsvService } from '../csv.service';
import { DownloadService } from '../download.service';
import { NGXLogger } from 'ngx-logger';
import { saveAs } from 'file-saver-es';
import { NotifierService } from 'angular-notifier';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { DropdownService } from '../dropdown.service';

/**
 *
 */
export interface IEditFilterForm {
  companies: Array<ICompany>;
  jobs: Array<IJob>;
  timeRange: TimeRange;
  report?;
  plants: Array<IPlant>;
  startTime: Date;
  endTime: Date;
  ticketNumbers?;
}

/**
 *
 */
@Component({
  selector: 'app-tickets',
  providers: [DatePipe],
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * @ignore
   */
  @ViewChild('editForm', { static: true })
  private editForm: ModalComponent;

  /**
   * @ignore
   */
  @ViewChild('editFilters', { static: true })
  private editFilters: LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('editTmpl', { static: true })
  private editTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('hdrTpl', { static: true })
  private hdrTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('voidTmpl', { static: true })
  private voidTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('numberTpl', { static: true })
  private numberTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('timeTmpl', { static: true })
  private timeTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('checkboxTpl', { static: true })
  private checkboxTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('selectAllTpl', { static: true })
  private selectAllTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('datatable', { static: true })
  private datatable: DatatableComponent;

  /**
   * @ignore
   */
  @ViewChild('imageModal', { static: true })
  private imageModal: LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('pdfModal', { static: true }) private pdfModal:
    LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('ticketDetails', { static: true })
  private ticketDetails: LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('ticketImage', { static: true })
  private ticketImage: ElementRef;

  /**
   * @ignore
   */
  @ViewChild('ticketPdf', { static: true })
  private ticketPdf: ElementRef;

  /**
   * @ignore
   */
  // openAdd name addCancel
  @ViewChild('openAdd', { static: true })
  private openAdd: ConfirmationModalComponent;

  /**
   * @ignore
   */
  @ViewChild('name', { static: true })
  private name: ElementRef;

  /**
   * @ignore
   */
  @ViewChild('addCancel', { static: true })
  private addCancel: ElementRef;

  /**
   * @ignore
   */
  @ViewChild('editColumnsButton', { static: true })
  private editColumnsButton: LargeModalComponent;

  /**
   * copy for sortablejs
   */
  _columns = [];

  /**
   * rows in table
   */
  allRows: Array<ITicket>;

  /**
   * buttons for edit detail modal
   */
  buttons: Array<IButton>;

  /**
   * buttons for detail modal
   */
  buttons2 = [
    {
      text: 'View Image',
      kind: 'secondary',
      click: () => {
        this.ticketDetails.close();
        this.imageModal.open();
        if (_.isString(this.selectedRow.Plant) && _.isString(this.selectedRow.Number)) {
          this.loadImage(this.selectedRow.Plant, this.selectedRow.Number);
        }
      },
    },
    {
      text: 'View Ticket PDF',
      click: () => {
        this.ticketDetails.close();
        if (_.isString(this.selectedRow.Plant) && _.isString(this.selectedRow.Number)) {
          this._loadPdf(this.selectedRow.Plant, this.selectedRow.Number);
        }
      },
    },
  ];

  downloading = false;

  downloadInProgress = false;

  /**
   * indicates if pdf is loading
   */
  loadingPdf: boolean;

  /**
   * buttons for edit columns modal
   */
  editColumnsButtons: Array<IButton>;

  /**
   * buttons for image modal
   */
  imageButtons: Array<IButton>;

  /**
   * multi select status
   */
  multi = false;

  /**
   * holder for hidden column
   */
  multiCol;

  /**
   * holder for disabled states
   */
  multiDisabled = {};

  /**
   * buttons for pdf modal
   */
  pdfButtons: Array<IButton>;

  /**
   * current selected row
   */
  selectedRow: Record<string, string | number> = {};

  /**
   * list of applied sorts
   */
  sorts = [];

  /**
   * current user prefs
   */
  userPrefs: IUserPreferences;

  /**
   * questions for search modal
   */
  questions: Array<Array<unknown>>;

  /**
   * @ignore
   */
  #plantsQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #companiesQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #jobsQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #timeRangeQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  #allRows: ITicket[];

  /**
   * @ignore
   */
  #pdfDataUrl: string;

  /**
   * @ignore
   */
  #imageDataUrl: string;

  /**
   * @ignore
   * contains route param
   */
  #item: string;

  /**
   * @ignore
   */
  #currentTicketList: ITicketList;

  /**
   * @ignore
   */
  #showVoid = false;

  /**
   * @ignore
   */
  constructor(
    private cd: ChangeDetectorRef,
    private dropdownService: DropdownService,
    private companyService: CompanyService,
    private csvService: CsvService,
    private deviceService: DeviceDetectorService,
    private download: DownloadService,
    private headerBar: HeaderBarService,
    private mediaService: MediaService,
    private notifier: NotifierService,
    private plantService: PlantService,
    private prefService: PreferencesService,
    private reportService: SavedService,
    private router: Router,
    private successService: SuccessService,
    private timeRangeService: TimeRangeService,
    private tix: TicketService,
    private userService: UserService,
    private log: NGXLogger,
    private _zone: NgZone,
  ) {
    this.userPrefs = { autoUpdate: false };
  }

  /**
   * helper to get user
   */
  get cognitoUser() {
    return this.userService.getCurrentUser();
  }

  /**
   * @ignore
   */
  private get selected() {
    return this.datatable.selected;
  }

  /**
   * @ignore
   */
  private get currentTicketList() {
    return this.#currentTicketList;
  }

  /**
   * grabs param from router
   */
  @Input() set item(x: string) {
    this.onEvent({ item: x });
  }

  /**
   * @ignore
   */
  private set currentTicketList(tl: ITicketList) {
    this.#currentTicketList = tl;
    this.headerBar.currentTicketList = tl;
    this.refreshData();
  }

  /**
   * Called when saving current ticket list.
   */
  async addSave() {
    _.set(this.currentTicketList, 'name', this.name.nativeElement.value);
    _.set(this.currentTicketList, 'username', this.userService.getUsername());
    _.set(this.currentTicketList, 'isDefault', false);

    try {
      await this.reportService.addTicketList(this.currentTicketList);

      this.addCancel.nativeElement.click();
      this.successService.showMessage('', 'Ticket List Saved');
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Used by ngx-datatable to display the first part of a cell.
   *
   * @param s The string to be split.
   * @returns The first part of the string.
   */
  first(s: string | number) {
    return _.isString(s) ? s.split('\n')[0] : '';
  }

  /**
   * Pass-through for lodash function.
   *
   * @param d The date to be shortened
   * @returns The short string representing the date.
   */
  isDate(d: Date) {
    return _.isDate(d);
  }

  /**
   * Used by ngx-datatable to display the second part of a cell.
   *
   * @param s The string to be split.
   * @returns The second part of the string.
   */
  last(s: string | number) {
    return _.isString(s) ? s.split('\n')[1] : '';
  }

  /**
   * Loads the image specified by plant and ticket number.
   *
   * @param plant The plant id for the ticket.
   * @param  ticketNum The id of the ticket.
   */
  async loadImage(plant: string, ticketNum: string) {
    this.imageModal.open();
    this.ticketImage.nativeElement.src = '';
    this.imageButtons[0].disabled = true;

    const x = await this.mediaService.findPictures(plant, ticketNum);

    if (x) {
      this.imageButtons[0].disabled = false;
      this.#imageDataUrl = x;
      this.ticketImage.nativeElement.src = x;
    }
    _.delay(() => {
      document.body.classList.add('modal-open');
    }, 500);
  }

  /**
   * WARNING: MAKING CHANGES HERE REQUIRES BUMP in pref.service
   */
  createDefaultColumns() {
    const columns: Array<Record<string, unknown>> = [
      { sortable: false, width: 80, resizeable: false },
      { name: 'Ticket #', prop: 'Number', width: 80 },
      { prop: 'Plant', width: 64 },
      { prop: 'PhysicalDate', name: 'Time Out', width: 80 },
      { name: 'Company', prop: 'customer', width: 140 },
      { name: 'ECMS #', prop: 'ECMS', width: 80 },
      { name: 'Job', prop: 'order', width: 140 },
      { name: 'Lindy', prop: 'LindyJobNum', width: 80 },
      { name: 'Dest ID', prop: 'DestinationID', width: 98 },
      { name: 'Address', prop: 'OAddress1', width: 140 },
      { prop: 'Truck', width: 80 },
      { prop: 'product', width: 140 },
      { name: 'JMF #', prop: 'JMF', width: 92 },
      { name: 'Load #', prop: 'Loads', width: 67 },
      { name: 'Rec/Tot', prop: 'qty', width: 80 },
    ];

    _.each(columns, (c) => {
      c.resizeable = false;
    });

    return columns;
  }

  /**
   * Called after DOM is ready.
   */
  async ngAfterViewInit() {
    try {
      // load user preferences
      const pref = await this.prefService.find(this.userService.getCurrentUser());

      this._loadUserPref(pref);
    } catch (e) {
      // probably not logged in, router will figure out and send to login page
      this.log.error(e, 'caught in ',
        TicketsComponent.name, this.ngAfterViewInit.name);
    }
  }

  /**
   * Called before component is destroyed.
   */
  ngOnDestroy() {
    window.removeEventListener('afterprint', this.#navigateToTickets);
  }

  /**
   * Called after constructor.
   */
  ngOnInit() {
    this.headerBar.beginEdit = this.#onBeginEdit;
    _.defer(() => {
      this.headerBar.title = null;
      this.cd.detectChanges();
    });
    window.addEventListener('afterprint', this.#navigateToTickets);
    this.buttons = [
      {
        text: 'Apply',
        click: this.#apply
      },
      {
        text: 'Clear All',
        click: this.#clearAll
      }
    ];
    this.imageButtons = [
      { text: 'Download', click: this.#downloadImage },
      //  { text: 'Print', click: window.print.bind(window) },
    ];
    this.pdfButtons = [
      { text: 'Download', click: this.#downloadPdf },
      //  { text: 'Print', click: window.print.bind(window) },
    ];
    this.editColumnsButtons = [
      { click: this.#editColumns, text: 'Apply' },
      { text: 'Reset to Default', click: this.#resetToDefault },
    ];
    this._initEditQuestions();
  }

  /**
   * Handler for events from ngx-datatable.
   *
   * @param e The event dispatched from the datatable.
   */
  onActivate = (e: IActivateEvent) => {
    if (e.type === 'click' && _.get(e, 'cellIndex')) {
      const row = e.row as Record<string, string | number>;

      this.selectedRow = row;
      this.ticketDetails.open();
    }
  };

  /**
   * handler for checkbox changed
   */
  onMultiChange() {
    this.multi = !this.multi;

    if (this.multi) {
      const temp = this.datatable.columns.pop();

      this.datatable.columns.unshift(this.multiCol);
      this.multiCol = temp;
      this._pushDisabled();
    } else {
      const temp = this.datatable.columns.shift();

      this.datatable.columns.push(this.multiCol);
      this.multiCol = temp;
      this._popDisabled();
    }

    this.datatable.columns = [...this.datatable.columns];
  }

  /**
   * refreshes ticket list using getter
   */
  onRefresh = () => {
    this.currentTicketList = this.currentTicketList;
  };

  /**
   * event handler for ngx-datatable
   */
  onSelect(e: unknown[]) {
    if (!this.datatable.dropDownLinksTwo) {
      return;
    }

    const pdfIndex = this.datatable.dropDownLinksTwo.length - 1;
    const disabled = !e.length;
    const _route = _.get(this.datatable.dropDownLinksTwo[pdfIndex], '_route');

    this.datatable.dropDownLinksTwo[pdfIndex].route = disabled ? null : _route;
    this.datatable.dropDownLinksTwo[pdfIndex].disabled = disabled;
  }

  onUpdateChange() {
    this._saveAutoRefreshStatus();
  }

  onVoidChange() {
    this.#showVoid = !this.#showVoid;
  }

  /**
   * handler for drag and drop list
   */
  drop(a, b) {
    moveItemInArray(b, a.previousIndex, a.currentIndex);
  }

  /**
   * Used by edit columns modal to display names of columns.
   *
   * @param  p The property name to be capitalized.
   * @returns The capitalized string.
   */
  prop(p: string) {
    return _.capitalize(p);
  }

  /**
   *
   */
  quickTransform(y: ITicket) {
    // conditional fields
    const total = y.DestinationID ? (y.DestTotal || 0) : (y.Total || 0);
    const loads = y.DestinationID ? y.DestLoads : y.Loads;

    y.customer = y.Customer + '\n' + y.CName;
    y.product = y.Product + '\n' + y.PDescription;
    y.order = y.OrderNum + '\n' + y.ODescription;
    y.qty = (y.Qty || 0) + '\n' + total;
    y.Loads = loads;

    return y;
  }

  /**
   * Performs a refresh of the table.
   */
  async refreshData() {
    if (!this.currentTicketList) {
      return;
    }

    const json: StandardOptions = {} as StandardOptions;
    const selectedPlants: Array<number> = this.currentTicketList?.plants;
    const tickets = this.currentTicketList?.ticket;
    const { jobs, companies } = this.currentTicketList;
    const timestamp =
      this.timeRangeService.computeTimestamp(
        this.currentTicketList.timeRange);

    json.timestamp = timestamp;
    json.plant = selectedPlants;
    json.job = jobs;
    json.company = companies;
    json.ticket = tickets;

    this.datatable.loadingIndicator = true;
    this.datatable.allRows = [];

    try {
      let x = await this.tix.standardRequest(json);

      if (!this.#showVoid) {
        x = _.filter(x, y => y.VOID !== 'VOID');
      }
      this.#allRows = _.clone(x);
      this.allRows = x;
    } catch (e) {
      throw e;
    } finally {
      this.datatable.loadingIndicator = false;
    }
  }

  /**
   * @ignore
   */
  private _clearSorts() {
    this.sorts = [];
  }

  /**
   * @ignore
   */
  private _convertTimeRange(form: IEditFilterForm) {
    const key = _.get(form, 'timeRange.key');

    return key === 'cust' ? this._packCustom(form) : key;
  }

  /**
   * @ignore
   */
  private _createDropDownLink(name: string, route: string) {
    return {
      name,
      disabled: false,
      active: false,
      route: `/tickets/${this.#item}-` + route
    };
  }

  /**
   * @ignore
   */
  private async _doDownloadNow(b64: string) {
    const blob = await this.mediaService.b64toBlob(b64);
    const name = 'tickets-' + _.now() + '.zip';

    saveAs(blob, name);
    this._showBulkSuccess();
  }

  /**
   * @ignore
   */
  private async _findReport(createdDate: number) {
    const user = this.userService.getCurrentUser();
    const r = await this.reportService.find(user, createdDate) as ITicketList;

    this.currentTicketList = r;
  }

  /**
   * @ignore
   * Function to generate CSV for current view.
   */
  private _generateCSV() {
    this.csvService.generate(this.#allRows);
  }

  /**
   * @ignore
   */
  private async _handlePendingDownload(res: { b64: string; error: Record<string, unknown> }) {
    if (!_.keys(res.error).length) {
      // pretty sure download will be created
      this._zone.run(() => {
        this.notifier.notify('success', 'This is a large download. You will be emailed when your download is ready.');
        // this._doBootstrapAlert();
      });
    }
  }

  /**
   * @ignore
   */
  private _initDropDownLinks() {
    const isDesktopDevice = this.deviceService.isDesktop();
    const exportOption = [];
    const pdfLink = this._createDropDownLink('Download Tickets', 'downloadPdfs');

    pdfLink.disabled = true;
    _.set(pdfLink, '_route', pdfLink.route);
    pdfLink.route = null;
    if (isDesktopDevice) {
      exportOption.push(this._createDropDownLink('Export to Excel', 'csv'));
    }
    this.datatable.dropDownLinksTwo = [
      this._createDropDownLink('Refresh Data', 'refresh'),
      this._createDropDownLink('Add to Saved', 'add'),
      this._createDropDownLink('Edit Columns', 'editColumns'),
      ...exportOption,
      this._createDropDownLink('Print', 'print'),
      this._createDropDownLink('Reset Table Sorting', 'clear'),
      pdfLink,
    ];
  }

  /**
   * @ignore
   */
  private async _initEditQuestions() {
    const companyOpts = this.dropdownService
      .companyDropdownQuestionOptionsFactory();
    const jobOpts = this.dropdownService.jobDropdownQuestionOptionsFactory();

    // companies dropdown
    companyOpts.onChange = () => {
      this._onCompaniesChange();
    };
    companyOpts.compareWith = this.dropdownService.cmpFn;
    this.#companiesQuestion = new DropdownQuestion(companyOpts);

    // jobs dropdown
    jobOpts.compareWith = this.dropdownService.cmpFn;
    this.#jobsQuestion = new DropdownQuestion(jobOpts);

    // time range dropdown
    this.#timeRangeQuestion = new DropdownQuestion({
      key: 'timeRange',
      label: 'Time Range',
      options: this.timeRangeService.timeRanges,
      placeholder: 'Select Timestamp',
      onChange: () => {
        this._onTimeRangeChange();
      },
      compareWith: this.dropdownService.cmpFn,
      clearable: false,
    });

    // ensure that plants are loaded
    await this.plantService.loadedPromise;

    this.#plantsQuestion = new DropdownQuestion({
      key: 'plants',
      label: 'Plants',
      options: this.plantService.plants,
      placeholder: 'Select Plants',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        this._onPlantChange();
      },
      compareWith: this.dropdownService.cmpFn,
    });

    this.questions = [
      [new DropdownQuestion({
        key: 'report',
        label: 'Preset Ticket Lists',
        options: [
          { key: 'at', value: 'All Companies Today' },
          { key: 'ay', value: 'All Companies Previous Day' },
          { key: 'lt', value: 'All Lindy Paving Today' },
          { key: 'ly', value: 'All Lindy Paving Previous Day' },
        ],
        placeholder: 'Select a Preset',
        onChange: () => {
          this._onReportChange();
        },
      })],
      [
        this.#plantsQuestion,
      ],
      [
        this.#timeRangeQuestion,
      ],
      [
        new DatetimeQuestion({
          key: 'startTime',
          label: 'Starting Date/Time',
          placeholder: 'Date and time',
          afterPickerClosed: () => { this._onStartTimeChange(); },
        }),
        new DatetimeQuestion({
          key: 'endTime',
          label: 'Ending Date/Time',
          placeholder: 'Date and time',
          afterPickerClosed: () => { this._onEndTimeChange(); },
        })
      ],
      [
        this.#companiesQuestion,
      ],
      [
        this.#jobsQuestion,
      ],
      [
        new TextboxQuestion({
          key: 'ticketNumbers',
          label: 'Ticket Number(s)',
          placeholder: 'Enter ticket number(s)',
        }),
      ],
    ];

    const admin = await this.userService.getIsAdminOrLindy(this.cognitoUser);

    if (!admin) {
      this.questions = this.questions.slice(1);
    }
  }

  /**
   * @ignore
   */
  private _keys(a: Array<IOption>) {
    return _.compact(_.map(a, c => c.key));
  }

  /**
   * @ignore
   * Loads the pdf specified by plant and ticket number.
   * @param plant The plant id for the ticket.
   * @param  ticketNum The id of the ticket.
   */
  private async _loadPdf(plant: string, ticketNum: string) {
    this.pdfModal.open();
    this.loadingPdf = true;
    this.ticketPdf.nativeElement.src = '';
    this.pdfButtons[0].disabled = true;

    const x = await this.mediaService.findPdf(plant, ticketNum);

    if (x) {
      this.pdfButtons[0].disabled = false;
      this.#pdfDataUrl = x;
      this.ticketPdf.nativeElement.setAttribute('src', x);
      this.loadingPdf = false;
    }
    _.delay(() => {
      document.body.classList.add('modal-open');
    }, 500);
  }

  /**
   * @ignore
   */
  private _onCompaniesChange() {
    this._updateDropdowns(true);
  }

  /**
   * @ignore
   */
  private _onEndTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this._onTimeRangeChange();
  }

  /**
   * handles updates to route parameters
   */
  private onEvent(routeParams: Record<string, string>) {
    const url = routeParams.item;
    const createdDate = _.parseInt(url);
    let redirect = true;

    if (!url) {
      this._zone.run(() => {
        this.router.navigate(['/tickets/standard']);
      });

      return;
    }
    this.#item = url;
    this._initDropDownLinks();
    if (url.includes('print')) {
      window.print();
    } else if (url.includes('refresh')) {
      this.refreshData();
    } else if (url.includes('clear')) {
      this._clearSorts();
    } else if (url.includes('csv')) {
      this._generateCSV();
    } else if (url.includes('add')) {
      if (this.currentTicketList) {
        if (!this.currentTicketList.ticket) {
          this.openAdd.open();
        } else {
          throw new Error('Cannot save ticket list with ticket numbers');
        }
      }
    } else if (url.includes('editColumns')) {
      this.editColumnsButton.open();
    } else if (url.includes('downloadPdfs')) {
      this.#downloadPdfs();
    } else if (url.includes('no-reload') && this.currentTicketList) {
      redirect = false;
    } else if (!_.isNaN(createdDate)) {
      this._findReport(createdDate);
      redirect = false;
    } else {
      redirect = false;
      this._doDefaultTicketList();
    }
    if (redirect) {
      this._zone.run(() => this.router.navigate([`/tickets/${this.#item.split('-')[0]}-no-reload`]));
    }
  }

  /**
   * @ignore
   */
  private _onPlantChange() {
    this._onTimeRangeChange();
  }

  /**
   * @ignore
   */
  private _onReportChange() {
    const form = this.editForm.form; // type info?
    const { value } = form;

    if (!value.report || !value.report.key) {
      return;
    }

    const report = form.value.report.key;

    form.patchValue({
      timeRange: { key: report[1] === 't' ? 'cday' : 'pday' }
    });
    this._onTimeRangeChange(report[0] === 'l');
  }

  /**
   * @ignore
   */
  private _onStartTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this._onTimeRangeChange();
  }

  /**
   * @ignore
   * Handles when the time range in the form is changed.
   */
  private _onTimeRangeChange(isLindy?: boolean) {
    const form = this.editForm.form; // type info?
    const values = form.value;
    const timeRange = values.timeRange;
    const converted = this._convertTimeRange(values);
    const timestamp = this.timeRangeService.computeTimestamp(converted);
    const lindy = this.companyService.createLindy();
    const json = {
      companies: isLindy ? [lindy] : [{ group: this.tix.allCompanies }],
      jobs: [{ group: this.tix.allJobs }],
    } as Record<string, unknown>;

    if (timeRange !== 'cust') {
      json.startTime = timestamp[0];
      json.endTime = timestamp[1];
    }
    form.patchValue(json);
    this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private _packCustom(form: IEditFilterForm) {
    return 'cust' + form.startTime?.valueOf() + ','
      + form.endTime?.valueOf();
  }

  /**
   * @ignore
   */
  private async _populateDropdowns(onlyJobs?: boolean) {
    const values = this.editForm.form.value;
    const options = {
    } as PopulateOptions;
    const tl = this._toTicketList(values);

    options.plant = tl.plants;
    if (onlyJobs) {
      options.company = tl.companies;
    }
    options.timeStamp = this.timeRangeService.computeTimestamp(tl.timeRange);
    if (_.isArray(options.timeStamp) && options.timeStamp.length === 2) {
      const c = await this.tix.populate(options);

      if (!onlyJobs) {
        if (this.#companiesQuestion) {
          this.#companiesQuestion.options = c.companies;
        }
      }
      if (this.#jobsQuestion) {
        this.#jobsQuestion.options = c.jobs;
      }
    }
  }

  /**
   * @ignore
   */
  private _resetForm(current: ITicketList) {
    const form = this._ticketListToForm(current);

    this.editForm.form.patchValue(form);
    this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private async _saveAutoRefreshStatus() {
    if (!this.datatable.header) { return; }
    this.userPrefs.autoUpdate = this.datatable.header.autoUpdate;

    await this.prefService.insertOrReplace(this.userPrefs);
  }

  /**
   * @ignore
   */
  private _showBulkSuccess() {
    this.notifier.notify('success', 'Bulk PDFs Download Complete');
  }

  /**
   * @ignore
   */
  private async _doDefaultTicketList() {
    const user = this.userService.getCurrentUser();
    const defaultTicketList = await this.reportService.getDefaultTicketList(user);

    if (!defaultTicketList) {
      try {
        const tl = await this.reportService.createDefault(user);

        this.currentTicketList = tl;
      } catch (e) {
        // swallowing since theres not much we can do otherwise
        this.log.error(e, 'caught in ',
          TicketsComponent.name, this._doDefaultTicketList.name);
      }
    } else {
      this.currentTicketList = defaultTicketList;
    }
  }

  /**
   * @ignore
   */
  private async _loadUserPref(pref: unknown) {
    this.log.trace('loading user pref:', pref);

    // check to make sure object exists
    if (pref) {
      // set to property
      this.userPrefs = pref;

      // check to make sure is current
      if (this.prefService.isPrefOutdated(this.userPrefs)) {
        // create new columns
        this.userPrefs.columns = this.createDefaultColumns();

        // update version
        this.userPrefs._version = this.prefService.currentVersion;

        try {
          // update preferences
          await this.prefService.insertOrReplace(this.userPrefs);
        } catch (e) {
          this.log.error(e, 'caught in ', this._loadUserPref.name);
        }
      }
    } else {
      const columns = this.createDefaultColumns();

      this.userPrefs = {
        username: this.userService.getUsername(),
        columns,
      };

      try {
        await this.prefService.insertOrReplace(this.userPrefs);
      } catch (e) {
        this.log.error(e, 'caught in ', this._loadUserPref.name);
      }
    }
    this.#initColumns();
  }

  /**
   * @ignore
   */
  private _pushDisabled() {
    if (!this.datatable.dropDownLinksTwo) {
      return;
    }

    const pdfIndex = this.datatable.dropDownLinksTwo.length - 1;

    _.each(this.datatable.dropDownLinksTwo, (d, i) => {
      if (i === pdfIndex) {
        return;
      }

      this.multiDisabled[i] = {
        disabled: d.disabled,
        route: d.route,
      };
      d.disabled = true;
      d.route = null;
    });
  }

  /**
   * @ignore
   */
  private _popDisabled() {
    if (!this.datatable.dropDownLinksTwo) {
      return;
    }

    const pdfIndex = this.datatable.dropDownLinksTwo.length - 1;

    _.each(this.datatable.dropDownLinksTwo, (d, i) => {
      if (i === pdfIndex) {
        return;
      }

      d.disabled = this.multiDisabled[i].disabled;
      d.route = this.multiDisabled[i].route;
    });
  }

  /**
   * @ignore
   */
  private _ticketListToForm(tl: ITicketList) {
    if (!tl) {
      return null;
    }

    const r = this.timeRangeService.computeTimestamp(tl.timeRange);
    const companies = _.map(tl.companies, this.companyService.createCompany);
    const jobs = _.map(tl.jobs, this.tix.createJob);
    const plants = _.map(tl.plants, this.plantService.findPlant);
    const form: IEditFilterForm = {
      plants: plants.length ? plants : [{ group: this.plantService.allPlants }],
      startTime: r[0],
      endTime: r[1],
      timeRange: this.timeRangeService.findTime(tl.timeRange),
      companies: companies.length ? companies : [{ group: this.tix.allCompanies }] as ICompany[],
      jobs: jobs.length ? jobs : [{ group: this.tix.allJobs }] as IJob[],
    };

    return form;
  }

  /**
   * @ignore
   */
  private _toggleMultiCheckbox() {
    this.onMultiChange();
  }

  /**
   * @ignore
   */
  private _toTicketList(form: IEditFilterForm): ITicketList {
    const jobs = this._keys(form.jobs);
    const c = this._keys(form.companies);
    const plants = this._keys(form.plants) as unknown[] as number[];
    const ticket = _.isString(form.ticketNumbers) ? form.ticketNumbers.split(',') : null;
    const tl: ITicketList = {
      jobs, // jobs.length ? jobs : this.keys(this.jobsQuestion.options),
      companies: c, // c.length ? c : this.keys(this.companiesQuestion.options),
      plants, // plants.length ? plants : this.keys(this.plantsQuestion.options),
      timeRange: this._convertTimeRange(form),
      username: this.userService.getUsername(),
      ticket,
    };

    return tl;
  }

  /**
   * @ignore
   */
  private _transformCompanyPermissions(perms: Array<IOption>) {
    const companies = _.map(perms, (p) => {
      const { key, value } = p;

      return {
        Customer: key,
        CName: value,
        group: this.tix.allCompanies,
        key,
        value,
      };
    });

    return companies as IOption[];
  }

  /**
   * @ignore
   */
  private _transformJobPermissions(perms: Array<IOption>) {
    const jobs = _.map(perms, (p) => {
      const { key, value } = p;

      return {
        OrderNum: key,
        ODescription: value,
        group: this.tix.allJobs,
        key,
        value,
      };
    });

    return jobs as IOption[];
  }

  /**
   * @ignore
   */
  private async _updateDropdowns(onlyJobs?: boolean) {
    try {
      const perms = await this.userService.getPermissions(this.cognitoUser);

      if (!perms || (_.isEmpty(perms.companies) && _.isEmpty(perms.jobs))) {
        await this._populateDropdowns(onlyJobs);
      } else {
        if (this.#companiesQuestion) {
          this.#companiesQuestion.options = this._transformCompanyPermissions(
            perms.companies);
        }
        if (this.#jobsQuestion) {
          this.#jobsQuestion.options = this._transformJobPermissions(
            perms.jobs);
        }
      }
    } catch (e) {
      this.log.error(e, 'caught in ',
        TicketsComponent.name, this._updateDropdowns.name);
    }
  }

  #apply = () => {
    const tl = this._toTicketList(this.editForm.form.value);

    this.editFilters.close();
    this.currentTicketList = tl;
  };

  #clearAll = () => {
    const form = this.editForm.form;

    form.reset({ timeRange: { key: 'pday' } });
  };

  #downloadImage = () => {
    this.download.download(this.#imageDataUrl, this.selectedRow.Number + '.jpg',
      'image/jpeg');
  };

  #downloadPdf = () => {
    this.download.download(this.#pdfDataUrl, this.selectedRow.Number + '.pdf',
      'application/pdf');
  };

  #downloadPdfs = async () => {
    _.defer(() => {
      this.downloading = true;
    });

    const res = await this.tix.bulkImageDownload(this.selected);

    if (res.b64) {
      await this._doDownloadNow(res.b64);
    } else {
      // error or email pending
      await this._handlePendingDownload(res);
    }

    this.downloading = false;
    this._toggleMultiCheckbox();
  };

  #editColumns = async () => {
    this.datatable.columns = _.cloneDeep(this._columns);
    this.userPrefs.columns = this.datatable.columns;

    await this.prefService.insertOrReplace(this.userPrefs);

    this.editColumnsButton.close();
  };

  #initColumns = () => {
    const columns = this.userPrefs.columns;

    _.each(columns, (c, i) => {
      if (!i) {
        c.cellTemplate = this.checkboxTpl;
        c.headerTemplate = this.selectAllTpl;
      } else {
        const { prop } = c;
        const p = prop.toString();
        const p0 = p[0];
        const isUpperCase = !p0 || p0 === p0.toUpperCase();
        const isDate = _.includes(p, 'Date');
        const isQty = _.includes(p, 'qty');

        c.cellTemplate = isUpperCase ? this.editTmpl : this.hdrTpl;
        if (isDate) {
          c.cellTemplate = this.timeTmpl;
        }
        if (isQty) {
          c.cellTemplate = this.numberTpl;
        }
        if (c.prop === 'Number') {
          c.cellTemplate = this.voidTmpl;
        }
      }
      this._columns.push(_.clone(c));
    });

    const clone = _.clone(columns);

    this.multiCol = clone.shift();
    this.datatable.columns = clone;
  };

  #navigateToTickets = () => {
    this._zone.run(() => this.router.navigate(['/tickets']));
  };

  #resetToDefault = async () => {
    _.defer(() => {
      this._columns = this.createDefaultColumns();
    });
    this.userPrefs.columns = this.createDefaultColumns();
    this.#initColumns();
    this.datatable.columns = this.datatable.columns;

    await this.prefService.insertOrReplace(this.userPrefs);

    this.editColumnsButton.close();
  };

  #onBeginEdit = () => {
    // load companies into modal
    this._resetForm(this.currentTicketList);
    this.editFilters.open();
  };
}
