import {
  Component, OnDestroy, Renderer2, OnInit, Input
} from '@angular/core';
import { OrdersService } from '../orders.service';
import _ from 'lodash-es';
import { NavBarService } from '../nav-bar.service';
import { HeaderBarService } from '../header-bar.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit, OnDestroy {

  html: string;

  private _item: string;

  constructor(
    private _orders: OrdersService,
    private nav: NavBarService,
    private header: HeaderBarService,
    private location: Location,
    // private _zone: NgZone,
    // private router: Router,
    private renderer: Renderer2,
    // private el: ElementRef,
  ) {

  }

  get item() {
    return this._item;
  }
  @Input() set item(x: string) {
    this._item = x;
    this._loadData();
  }

  /**
   * handles close button
   */
  beginClose() {
    this.onClose();
  }

  /**
   * angular lifecycle hook
   */
  ngOnDestroy() {
    this.nav.restoreNav();
    this.renderer.removeClass(document.body, 'app-view-email');
    this.renderer.setStyle(document.body, 'background-color', 'white');
  }

  /**
   * angular lifecycle hook
   */
  async ngOnInit() {
    _.defer(() => {
      this.nav.hideNav();
      this.header.clearAll();
      this.renderer.setStyle(document.body, 'background-color', '#e5e5e5');
      this.renderer.addClass(document.body, 'app-view-email');
    });

  }

  /**
   * @ignore
   */
  private async _loadData() {
    const { html } = await this._orders.getEmail(this.item);

    const s = html.split('<body')[1].split('</body>')[0].split('>');

    s.shift();

    const parsed = _.join(s, '>');

    this.html = parsed;
  }

  /**
   * @ignore
   * handles close button
   */
  private onClose() {
    this.location.back();
  }
}
