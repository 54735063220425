import { Injectable } from '@angular/core';
import { DropdownQuestion, } from '@pjd-development/pjd-dsc-lib';
import { OrdersService } from './orders.service';
import { IPlant, PlantService } from './plant.service';
import _ from 'lodash-es';
import { TimeRangeService } from './time-range.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor(
    private orders: OrdersService,
    private plants: PlantService,
    private timeRangeService: TimeRangeService,
  ) { }

  /**
   * Common logic for jobs/companies dropdowns.
   */
  cmpFn = (opt: IPlant, sel: IPlant) => {
    let result = false;

    if (opt.group && sel.group && !sel.key) {
      result = result || (opt.group === sel.group);
    }
    if (opt.key && sel.key) {
      result = result || (opt.key === sel.key);
    }

    return result;
  };

  orderTimeRangeQuestionFactory(onChange?: () => void) {
    return new DropdownQuestion({
      key: 'timeRange',
      label: 'Time Range',
      options: this.timeRangeService.orderTimeRanges,
      placeholder: 'Select Timestamp',
      onChange: () => {
        if (onChange) {
          onChange();
        }
      },
      compareWith: this.cmpFn,
      clearable: false,
    });
  }

  /**
   * factory for dropdowns
   */
  dropdownFactory(key: string, label: string, placeholder: string) {
    return new DropdownQuestion({
      key,
      label,
      placeholder,
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        //
      },
      compareWith: this.cmpFn,
      hidden: true,
    });
  }

  /**
   * returns default options for company dropdown
   */
  companyDropdownQuestionOptionsFactory() {
    return {
      key: 'companies',
      label: 'Companies',
      options: [],
      placeholder: 'Select Companies',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
    } as Record<string, unknown>;
  }

  /**
   * returns default options for job dropdown
   */
  jobDropdownQuestionOptionsFactory() {
    return {
      key: 'jobs',
      label: 'Jobs',
      options: [],
      placeholder: 'Select Jobs',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
    } as Record<string, unknown>;
  }

  /**
   * factory for plant question
   */
  async getQuestion(onChange?: () => void) {
    await this.plants.loadedPromise;

    return new DropdownQuestion({
      key: 'plants',
      label: 'Plants',
      options: this.plants.plants,
      placeholder: 'Select Plants',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        if (onChange) {
          onChange();
        }
      },
      compareWith: this.cmpFn,
    });
  }

  /**
   * factory for plant question
   */
  async getQuestionSingle(onChange?: () => void) {
    await this.plants.loadedPromise;

    return new DropdownQuestion({
      key: 'plant',
      label: 'Plant',
      options: this.plants.plants,
      placeholder: '',
      //   groupBy: 'group',
      //    selectableGroup: true,
      //    multiple: true,
      onChange: () => {
        if (onChange) {
          onChange();
        }
      },
      compareWith: this.cmpFn,
      required: true,
      invRatio: 2,
      medRatio: 1,
      clearable: false,
    });
  }

  /**
   * factory for shift question
   */
  getShiftQuestion() {
    return new DropdownQuestion({
      key: 'shift',
      label: 'Shift',
      options: [
        { key: 'Day', value: 'Day' },
        { key: 'Night', value: 'Night' },
      ],
      onChange: () => {
        //
      },
      compareWith: this.cmpFn,
      invRatio: 6,
      medRatio: 3,
    });
  }

  async getWorkQuestion() {
    const { workTypes } = await this.orders.getSystemDefaults();

    return new DropdownQuestion({
      key: 'workType',
      label: 'Work Type',
      options: _.map(workTypes, w => ({ key: w, value: w })),
      placeholder: '',
      required: false,
      clearable: false,
      compareWith: this.cmpFn,
    });
  }

  async getStatusQuestion() {
    const { accountStatuses } = await this.orders.getSystemDefaults();

    return new DropdownQuestion({
      key: 'accountStatus',
      label: 'Account Status',
      options: _.map(accountStatuses, w => ({ key: w, value: w })),
      placeholder: '',
      required: true,
      clearable: false,
      compareWith: this.cmpFn,
    });
  }

}
