import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  AlertComponent,
  ModalComponent,
  LargeModalComponent,
  CheckboxQuestion,
  IButton,
  IOption,
} from '@pjd-development/pjd-dsc-lib';
import * as _ from 'lodash-es';
import { UserService, IUser, IUserEvent } from '../user.service';
import { of, Observable } from 'rxjs';
import { CompanyService } from '../company.service';
import {
  AbcRadioQuestion,
  TextboxQuestion,
} from '@pjd-development/pjd-dsc-lib';
import { SuccessService } from '../success.service';
import { HeaderBarService } from '../header-bar.service';
import { TicketService } from '../ticket.service';
import { PasswordService } from '../password.service';
import { DropdownService } from '../dropdown.service';
import { AdminService } from '../admin.service';
import { IActivateEvent } from '../datatable/datatable.component';

const SMALL_WIDTH = 185;

const textboxFactory = (a, b, c, d?) => new TextboxQuestion({
  key: a,
  label: b,
  placeholder: c,
  type: d,
});

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, AfterViewInit {

  /**
   * @ignore
   */
  @ViewChild('createOrEditModal', { static: true })
  private createOrEditModal: LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('accountCreated', { static: true })
  private accountCreated: AlertComponent;

  /**
   * @ignore
   */
  @ViewChild('deleteConfirmation', { static: true })
  private deleteConfirmation: AlertComponent;

  /**
   * @ignore
   */
  @ViewChild('invalidEmail', { static: true })
  private invalidEmail: AlertComponent;

  /**
   * @ignore
   */
  @ViewChild('passwordMismatch', { static: true })
  private passwordMismatch: AlertComponent;

  /**
   * @ignore
   */
  @ViewChild('passwordInvalid', { static: true })
  private passwordInvalid: AlertComponent;

  /**
   * @ignore
   */
  @ViewChild('userDetailsModal', { static: true })
  private userDetailsModal: LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('editTmpl', { static: true })
  private editTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('hdrTpl', { static: true })
  private hdrTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('companiesCol', { static: true })
  private companiesCol: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('jobsCol', { static: true })
  private jobsCol: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('createForm', { static: true })
  private createForm: ModalComponent;

  /**
   *
   */
  buttons;

  /**
   *
   */
  buttons2;

  /**
   * show the spinner
   */
  loadingIndicator = true;

  /**
   * used for user details modal
   */
  selectedRow: IUser = {} as IUser;

  /**
   * rows for table
   */
  rows;

  /**
   * rows for table
   */
  columns: Array<Record<string, string | number | boolean | TemplateRef<unknown>>> = [
    { prop: 'family_name', name: 'Last Name', width: SMALL_WIDTH },
    { prop: 'given_name', name: 'First Name', width: SMALL_WIDTH },
    { prop: 'account', width: 285, },
    { prop: 'company', width: SMALL_WIDTH },
    { prop: 'companies', name: 'Companies Visible', width: 215 },
    { name: 'Jobs Visible', prop: 'jobs', width: 215 },
    { name: 'View Orders', prop: 'viewOrders', width: 90 },
  ];

  /**
   * Create/Edit Questions
   */
  companiesQuestion = this.dropdown.dropdownFactory(
    'companies', 'Companies Visible', 'Select Companies');

  /**
   * Create/Edit Questions
   */
  jobsQuestion = this.dropdown.dropdownFactory('jobs', 'Jobs Visible', 'Select Jobs');

  /**
   * Create/Edit Questions
   */
  emailQuestion = textboxFactory('email', 'Email', 'Enter email address');

  /**
   * Create/Edit Questions
   */
  viewOrdersQuestion = new CheckboxQuestion({
    key: 'view_orders',
    label: 'Account Restrictions',
    value: true,
    option: 'View Orders',
    onChange: (_e) => {
      //
    },
  });

  /**
   * Create/Edit Questions
   */
  createQuestions = [
    [
      textboxFactory('given_name', 'First Name', 'Enter user\'s first name'),
      textboxFactory('family_name', 'Last Name', 'Enter user\'s last name'),
    ],
    [
      this.emailQuestion,
      textboxFactory('company', 'Company',
        'Enter company associated with user'),
    ],
    [
      new AbcRadioQuestion({
        key: 'admin',
        label: 'Account Type',
        options: [
          { key: 'true', value: 'Admin' },
          { key: 'lindy', value: 'Lindy' },
          { key: 'false', value: 'External' },
        ],
        onChange: (e) => {
          this.onTypeChange(e.target.id);
        },
      }),
      this.viewOrdersQuestion,
    ],
    [this.companiesQuestion],
    [this.jobsQuestion],
    [
      textboxFactory('memo', 'Memo', 'Enter memo'),
    ],
    [
      textboxFactory('password',
        'Password Reset', 'Enter New Password', 'password'),
      textboxFactory('confirm_password',
        'Confirm Password Reset', 'Confirm New Password', 'password'),
    ],
  ];

  /**
   * @ignore
   */
  private _companies: IOption[];

  /**
   * @ignore
   */
  private _jobs: IOption[];

  /**
   * @ignore
   */
  private _isEditing: boolean;

  /**
   * @ignore
   */
  private nonEditingButtons: IButton[];

  /**
   * @ignore
   */
  private editingButtons: IButton[];

  /**
   * @ignore
   */
  constructor(
    public pass: PasswordService,
    private companyService: CompanyService,
    private headerBar: HeaderBarService,
    private successService: SuccessService,
    private ticketService: TicketService,
    private userService: AdminService,
    private _userService: UserService,
    private dropdown: DropdownService,
  ) {
    //
  }

  /**
   * helper to get user
   */
  get cognitoUser() {
    return this._userService.getCurrentUser();
  }

  /**
   * used to track edit status
   */
  get isEditing() {
    return this._isEditing;
  }

  /**
   * cache companies and register listeners
   */
  get companies() {
    return this._companies;
  }

  /**
   * cache jobs and register listeners
   */
  get jobs() {
    return this._jobs;
  }

  set companies(c: IOption[]) {
    this.companiesQuestion.options = c;
    this._companies = c;
  }

  set jobs(j: IOption[]) {
    this._jobs = j;
    this.jobsQuestion.options = j;
  }

  set isEditing(b) {
    this._isEditing = b;
    this.buttons2 = b ? this.editingButtons : this.nonEditingButtons;
  }

  /**
   *
   */
  accountType(s) {
    if (s === 'true') {
      return 'Admin';
    }
    if (s === 'false') {
      return 'External';
    }
    return 'Lindy';
  }

  /**
   * Helper for view.
   *
   * @param s The string to split.
   * @returns The first line of the string.
   */
  first(s: string) {
    return s ? s.split('\n')[0] : '';
  }

  /**
   * Helper for the view.
   *
   * @param s The string to be split.
   * @returns The second line of the string.
   */
  last(s: string) {
    return s ? s.split('\n')[1] : '';
  }

  /**
   * Helper for view.
   *
   * @param s The string to split.
   * @returns The first line of the string.
   */
  first2(s: string) {
    return s ? s.split(' - ')[0] : '';
  }

  /**
   * Helper for the view.
   *
   * @param s The string to be split.
   * @returns The second line of the string.
   */
  last2(s: string) {
    return s ? s.split(' - ').slice(1).join(' - ') : '';
  }

  /**
   * Helper for the view.
   *
   * @param v List of company ids to look up.
   * @returns The list of companies.
   */
  lookup(v: Array<string>): Observable<Array<unknown>> {
    const allCompanies$ = of([{ key: 'All' }]);

    return v.length ? this.companyService.findCompanies(v) : allCompanies$;
  }

  /**
   * Lifecycle hook for Angular.
   */
  ngAfterViewInit() {
    this._initConfirmationHooks();
    this.initColumns();
  }

  /**
   * Lifecycle hook for Angular.
   */
  async ngOnInit() {
    _.defer(() => {
      this.headerBar.showCreate = true;
      this.headerBar.beginCreate = this.beginCreate;
    });
    this.companiesQuestion.onChange = async () => {
      await this.companiesChanged();
    };
    this.buttons = [
      {
        text: 'Edit',
        click: this.beginEdit
      }
    ];
    this.nonEditingButtons = [
      {
        text: 'Submit',
        click: this.createAccount,
      }
    ];
    this.editingButtons = [
      {
        text: 'Save',
        click: this.update,
      },
      {
        text: 'Delete',
        click: this.delete,
      }
    ];
    this.isEditing = false;
    this.loadData();

    const c = await this.ticketService.populate({});

    this.companies = c.companies;
    this.jobs = c.jobs;
  }

  /**
   *
   */
  neverLoggedIn() {
    /* eslint-disable  @typescript-eslint/naming-convention */
    const {
      UserCreateDate,
      UserLastModifiedDate,
      UserStatus,
    } = this.selectedRow;
    /* eslint-enable  @typescript-eslint/naming-convention */

    const isChangeRequired = UserStatus === 'FORCE_CHANGE_PASSWORD';

    return (UserCreateDate === UserLastModifiedDate) && isChangeRequired;
  }

  /**
   * Handler for ngx-datatable.
   *
   * @param e Event from datatable.
   */
  async onActivate(e: IActivateEvent) {
    if (e.type === 'click') {
      const row = e.row;

      this.selectedRow = _.clone(row) as IUser;

      const events = await this.userService.getUserAuthEvents(
        this.cognitoUser,
        this.selectedRow.email,
      );

      this.selectedRow.events = _.chunk(events, 3)[0] as IUserEvent[];
      this.userDetailsModal.open();
    }
  }

  /**
   *
   */
  onTypeChange = (e: string) => {
    // e = admin flag
    if (e === 'false') {
      this.jobsQuestion.hidden = false;
      this.companiesQuestion.hidden = false;
      this.disableViewOrders();
    } else if (e === 'true') {
      this.disableViewOrders();
      this.jobsQuestion.hidden = true;
      this.companiesQuestion.hidden = true;
    } else {
      this.enableViewOrders();
      this.jobsQuestion.hidden = true;
      this.companiesQuestion.hidden = true;
    }
  };

  private disableViewOrders() {
    // const controls = this.createForm.form.controls;
    //    const viewOrders = controls['view_orders'];

    this.createForm.findQuestion('view_orders').hidden = true;
    // viewOrders.disable();
  }

  private enableViewOrders() {
    //    const controls = this.createForm.form.controls;
    //    const viewOrders = controls['view_orders'];

    this.createForm.findQuestion('view_orders').hidden = false;
    // viewOrders.enable();
  }

  private setViewOrders() {
    const controls = this.createForm.form.controls;
    const viewOrders = controls.view_orders;

    viewOrders.setValue(true);
  }

  private clearViewOrders() {
    const controls = this.createForm.form.controls;
    const viewOrders = controls.view_orders;

    viewOrders.setValue(false);
  }

  /**
   * @ignore
   * needs proper this
   */
  private beginCreate = () => {
    this.createForm.form.reset();
    this.createForm.form.patchValue({
      companies: [],
      jobs: [],
    });
    this.emailQuestion.disabled = false;
    this.isEditing = false;
    this.deleteConfirmation.destroy();
    this.invalidEmail.destroy();
    this.passwordMismatch.destroy();
    this.passwordInvalid.destroy();
    this.accountCreated.destroy();
    this.createOrEditModal.open();
  };

  /**
   * @ignore
   * Click handler for rows in datatable.
   * @param e The row to be edited.
   */
  private beginEdit = (_e) => {
    this.patchEditQuestions();
    this.emailQuestion.disabled = true;
    this.isEditing = true;
    this.deleteConfirmation.destroy();
    this.invalidEmail.destroy();
    this.passwordMismatch.destroy();
    this.passwordInvalid.destroy();
    this.onTypeChange(this.selectedRow.admin);
    this.userDetailsModal.close();
    this.accountCreated.destroy();
    this.createOrEditModal.open();
  };

  /**
   * @ignore
   */
  private async companiesChanged() {
    const companies = this.keys(this.createForm.form.value.companies);
    const c = await this.ticketService.populate({
      company: companies,
    });

    this.jobs = c.jobs;
  }

  /**
   * @ignore
   * Create account click handler.
   */
  private createAccount = async () => {
    const { form } = this.createForm;

    this.enableViewOrders();

    const user = form.value;

    user.view_orders = user.view_orders + '';

    if (!user.email || user.email.includes('+')) {
      this.invalidEmail.show();

      return;
    } else {
      this.invalidEmail.destroy();
    }

    // check if passwords match
    if (!this._passwordCheck(true)) {
      return;
    }

    user.name = user.given_name + ' ' + user.family_name;
    user.account = (user.admin === 'true' ? 'ADMIN' : '') + '\n' + user.email;

    try {
      await this.userService.create(this.cognitoUser, user);

      this.loadData();
      form.reset();
      this.accountCreated.show();
      _.delay(() => this.accountCreated.destroy(), 5000);
    } catch (e) {
      const error = 'UsernameExistsException\nAn account with the given email already exists.';

      if (e.error === error) {
        this.invalidEmail.show();
      } else {
        throw e;
      }
    }
  };

  /**
   * @ignore
   * Shows alert to confirm delete.
   */
  private delete = () => {
    this.deleteConfirmation.show();
  };

  /**
   * @ignore
   */
  private initColumns() {
    _.each(this.columns, (c2) => {
      //        c2.cellTemplate = this.editTmpl;
      c2.resizeable = false;
    });
    this.columns[2].cellTemplate = this.hdrTpl;
    this.columns[4].cellTemplate = this.companiesCol;
    this.columns[5].cellTemplate = this.jobsCol;
  }

  /**
   * @ignore
   */
  private keys(a: Array<IOption>) {
    return _.compact(_.map(a, c => c.key));
  }

  /**
   * @ignore
   */
  private loadData() {
    this.rows = [];
    this.loadingIndicator = true;
    this._doLoadData();
  }

  /**
   * @ignore
   */
  private async _doLoadData(t?: Record<string, string>) {
    const { _token, users } = await this.userService.list(this.cognitoUser, t);

    {
      const x = users;
      const a = x as unknown[];

      this.rows = [...this.rows, ...a];
    }

    // reusing variable names
    {
      const x = _token;
      const pt = _.get(x, 'PaginationToken');

      if (pt === 'undefined') {
        this.loadingIndicator = false;
      } else {
        this._doLoadData(x as Record<string, string>);
      }
    }
  }

  /**
   * @ignore
   */
  private _initConfirmationHooks() {
    this.deleteConfirmation.okay = async () => {
      const title = '';
      const message = 'Account deleted';

      await this.userService.remove(
        this.cognitoUser,
        this.selectedRow.email,
      );

      this.loadingIndicator = true;
      this.loadData();
      this.createOrEditModal.close();
      this.successService.showMessage(title, message);
    };
  }

  /**
   * @ignore
   */
  private async patchEditQuestions() {
    this.createForm.form.patchValue(this.selectedRow);
    if (this.selectedRow.view_orders === 'true') {
      this.setViewOrders();
    } else {
      this.clearViewOrders();
    }
    this.emailQuestion.value = this.selectedRow.email;
    await this.companiesChanged();
  }

  /**
   * @ignore
   */
  private _passwordCheck(allowEmpty?: boolean) {
    const { value } = this.createForm.form;
    const check = value.password === value.confirm_password;
    const valid = this.pass.validatePassword(value.password);
    const isEmpty = !value.password;

    if (allowEmpty && isEmpty) {
      return true;
    }

    if (!check) {
      this.passwordMismatch.show();
    } else {
      this.passwordMismatch.destroy();
    }
    if (!isEmpty && !valid) {
      this.passwordInvalid.show();
    } else {
      this.passwordInvalid.destroy();
    }

    return check && valid;
  }

  /**
   * @ignore
   * Perform update in cognito for the selected user.
   */
  private update = async () => {
    if (!this._passwordCheck(true)) {
      return;
    }

    this.enableViewOrders();

    const { value } = this.createForm.form;

    value.view_orders = value.view_orders + '';

    await this.userService.update(
      this.cognitoUser,
      value,
    );

    this.loadData();
    this.createOrEditModal.close();
    this.successService.showMessage('', 'Account updated');
  };
}
