import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  NgZone
} from '@angular/core';
import { Router, NavigationEnd, } from '@angular/router';
import * as _ from 'lodash-es';
import {
  AlertComponent, ConfirmationModalComponent, DropDownLink,
} from '@pjd-development/pjd-dsc-lib';

// local imports
import { UserService } from './user.service';
import { Successable, SuccessService } from './success.service';
import { HeaderBarService } from './header-bar.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { addDays, isToday, isTomorrow, startOfToday } from 'date-fns';
import { NavBarService } from './nav-bar.service';
import { IOrderList } from './saved.service';
import { UnloadService } from './unload.service';
import { filter, map } from 'rxjs';

const _orderLinks = [
  {
    name: 'Schedules',
    active: false,
    route: '/schedules',
  },
  {
    name: 'Orders',
    active: false,
    route: '/orders',
  },
];

const _normalLinks1 = [
  {
    name: 'Tickets List',
    active: false,
    route: '/tickets',
  },
];

const _normalLinks2 = [
  {
    name: 'Saved',
    active: false,
    route: '/saved',
  },
];

const _lindyLinks = [
  {
    name: 'Graphs',
    active: false,
    route: '/graphs',
    hasChildLinks: false,
  },
  {
    name: 'Reports',
    active: false,
    route: '/reports',
    hasChildLinks: false,
  },
];

const _adminLinks = [
  {
    name: 'Admin',
    active: false,
    route: '/admin',
  },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, Successable {

  /**
   * Implements Successable
   */
  @ViewChild('passwordSavedButton', { static: true }) successOutlet: ConfirmationModalComponent;

  /**
   * alert for change password modal
   */
  @ViewChild('alert', { static: true }) private alertComponent: AlertComponent;

  /**
   * @ignore
   */
  @ViewChild('closeChangePassword', { static: true })
  private closeChangePassword: ElementRef;

  /**
   *
   */
  url = '';

  /**
   * flag for hiding children
   */
  loading = false;

  /**
   * Used by password change modal
   */
  currentPassword: string;

  /**
   * Used by password change modal
   */
  newPassword: string;

  /**
   * Used by password change modal
   */
  newPasswordAgain: string;

  isSmall = false;

  /**
   * Implements Successable
   */
  successMessage: string;

  /**
   * Implements Successable
   */
  successTitle: string;

  /**
   * nav bar styles
   */
  navBarStylesOne: Array<unknown>;

  /**
   * links for schedule/orders create button
   */
  dropDownLinks: Array<DropDownLink> = [
    {
      name: 'New Order',
      disabled: false,
      active: false,
      route: '/order-details/new'
    },
    {
      name: 'Plant Schedule Summary',
      disabled: false,
      active: false,
      onClick: () => { this.h().beginCreateSchedule(); },
    },
  ];

  /**
   * controls the visibility of the drop down menu
   */
  isHidden = true;

  /**
   * normal nav
   */
  abbreviatedNav = {
    appTitle: 'Lindy Paving Ticketing',
    type: 'abbreviated'
  };
  /**
   * mobile nav
   */
  mobileNav = {
    appTitle: 'Lindy Paving Ticketing',
    type: 'mobile'
  };

  /**
   * nav bar links
   */
  navBarLinksOne: Array<unknown>;

  /**
   * should icons be shown
   */
  showIcons: boolean;

  /**
   * links for admin users
   */
  private linksOne = [
    ..._orderLinks,
    ..._normalLinks1,
    ..._lindyLinks,
    ..._normalLinks2,
    ..._adminLinks,
  ];

  /**
   * links for normal users
   */
  private linksTwo = [
    ..._normalLinks1,
    ..._normalLinks2,
  ];

  /**
   * links for lindy users
   */
  private linksThree = [
    ..._orderLinks,
    ..._normalLinks1,
    ..._lindyLinks,
    ..._normalLinks2,
  ];

  private wasSchedule = false;

  /**
   * @ignore
   */
  constructor(
    private router: Router,
    private userService: UserService,
    private successService: SuccessService,
    private headerBar: HeaderBarService,
    private cd: ChangeDetectorRef,
    public ga: GoogleAnalyticsService,
    private nav: NavBarService,
    private unload: UnloadService,
    private _zone: NgZone,
  ) {
    const events =
      router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url));

    events.subscribe(() => {
      this.onRouterEvent();
    });
  }

  /**
   * helper to get user
   */
  get cognitoUser() {
    return this.userService.getCurrentUser();
  }

  get isSchedule() {
    const v = this.url.startsWith('/schedules');

    // save last route
    if (v) {
      this.headerBar._isOrders = false;
    }

    if (v !== this.wasSchedule) {
      this.wasSchedule = v;
    }

    return v;
  }

  get isOrders() {
    const v = this.url.includes('orders');

    // save last route
    if (v) {
      this.headerBar._isOrders = true;
    }

    return v;
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: unknown) {
    if (window.innerWidth <= 550) {
      this.isSmall = true;
    } else {
      this.isSmall = false;
    }
  }

  /**
   * catches browser events like back button
   */
  @HostListener('window:popstate', ['$event'])
  onHashchange(_e: unknown) {
    //    this.headerBar.clearCurrentOrder();
  }

  doBeforeUnload() {
    // Alert the user window is closing
    return !this.unload.shouldBlockUnload();
  }

  doUnload() {
    // Clear session or do something
    this.unload.doCallback();
  }

  /**
   * Expose change detector for updating alerts
   * after an error.
   */
  detectChanges() {
    this.cd.detectChanges();
  }

  /**
   * Getter for the headerBar
   */
  h() {
    return this.headerBar;
  }

  /**
   * Getter for the navBar
   */
  n() {
    return this.nav;
  }

  /**
   * Quick check for whether page needs authentication.
   *
   * @return  True if page requires authentication.
   */
  isLoggedIn(): boolean {
    return this.needsLoggedIn(this.router.url);
  }

  /**
   * Click handler for logout button.
   */
  logout() {
    this._zone.run(() => {
      this.router.navigate(['/logout']);
    });
  }

  /**
   *
   */
  nextDay(event) {
    event.stopPropagation();

    const tl = this.headerBar.currentTicketList;

    if (tl.timeRange.includes('cday')) {
      this.gotoToday(event);

      return this.nextDay(event);
    }

    const first = tl.timeRange.split('cust');
    const s = +(first[1].split(',')[0]);
    const tomorrow = addDays(s, 1);
    const end = addDays(tomorrow, 0);
    const e = end.valueOf();

    tl.timeRange = 'cust' + tomorrow.valueOf() + ',' + e;
    this.headerBar.currentTicketList = tl;
    this.headerBar.notifyListCallback();
  }

  prevDay(event) {
    event.stopPropagation();

    const tl = this.headerBar.currentTicketList;

    if (tl.timeRange.includes('cday')) {
      this.gotoToday(event);

      return this.prevDay(event);
    }

    const first = tl.timeRange.split('cust');
    const s = +(first[1].split(',')[0]);
    const yesterday = addDays(s, -1);
    const end = addDays(yesterday, 0);
    const e = end.valueOf();

    tl.timeRange = 'cust' + yesterday.valueOf() + ',' + e;
    this.headerBar.currentTicketList = tl;
    this.headerBar.notifyListCallback();
  }

  /**
   * handles clicking on drop down links.
   */
  click(link: DropDownLink) {
    this.isHidden = true;
    if (link.onClick) {
      _.defer(link.onClick);
    }
  }

  /**
   * Click Handler for Hide and Show Drop-down Links
   */
  toggle() {
    this.isHidden = !this.isHidden;
  }

  /**
   * handles events outside the drop down
   */
  onClickedOutside(_event: Event) {
    if (!this.isHidden) {
      this.isHidden = true;
    }
  }

  gotoToday(event) {
    event.stopPropagation();

    const tl = this.headerBar.currentTicketList;
    const start = startOfToday();
    const s = start.valueOf();
    const end = addDays(start, 0);
    const e = end.valueOf();

    tl.timeRange = 'cust' + s + ',' + e;
    this.headerBar.currentTicketList = tl;
    this.headerBar.notifyListCallback();
  }

  afterPickerClosed(event) {
    if (_.isArray(event)) {
      return;
    }

    const tl = this.headerBar.currentTicketList;
    const s = event.valueOf();
    const end = addDays(event, 0);
    const e = end.valueOf();

    tl.timeRange = 'cust' + s + ',' + e;
    this.headerBar.currentTicketList = tl;
    this.headerBar.notifyListCallback();
  }

  isToday() {
    if (!this.headerBar) {
      return false;
    }

    const today = isToday(this.headerBar.startDate);

    return today;
  }

  isTomorrow() {
    if (!this.headerBar) {
      return false;
    }

    const today = isTomorrow(this.headerBar.startDate);

    return today;
  }

  /**
   * Lifecycle hook for angular.
   */
  ngAfterViewInit() {
    _.defer(() => {
      this.alertComponent.destroy();
    });
  }

  /**
   * Lifecycle hook for angular.
   */
  ngOnInit(): void {
    this.onResize({});
    this.successService.registerTarget(this);
    this.setNavBar();
  }

  /**
   * for create plant schedule report modal
   */
  onListChanged(_list: IOrderList) {
    //
  }

  /**
   * Click handler for changing password.
   */
  save() {
    this.saveCallback(
      this.currentPassword, this.newPassword, this.newPasswordAgain);
  }

  /**
   * @ignore
   */
  private changePassword(old, proposed) {
    const user = this.userService.getCurrentUser();

    this.userService.changePassword(user, old, proposed).subscribe(() => {
      this.closeChangePassword.nativeElement.click();
      this.successService.showMessage('',
        'Password Saved');
    }, (err: unknown) => {
      const a = this.alertComponent;
      const message = _.get(err, 'message');

      a.message = message;
      a.level = 'warning';
      a.show();
    });
  }

  /**
   * @ignore
   */
  private onRouterEvent() {
    this.setNavBar();
    if (this.isLoggedIn() && !this.userService.getCurrentUser()) {
      this.router.navigate(['/']);
    } else if (this.isLoggedIn()) {
      let hasIdToken = false;

      _.forOwn(localStorage, (_val, key) => {
        hasIdToken = hasIdToken || key.includes('idToken');
      });
      if (!hasIdToken) {
        this.router.navigate(['/logout']);
      }
    }
  }

  /**
   * @ignore
   */
  private saveCallback(a, b, c) {
    if (b === c) {
      this.changePassword(a, b);
    }
  }

  /**
   * @ignore
   */
  private async setNavBar() {
    const url: string = this.router.url;
    const adminLinks = this.linksOne;
    const regularLinks = this.linksTwo;
    const orderLinks = this.linksThree;

    _.defer(() => { this.url = url; });

    if (url !== '/') {
      _.each(adminLinks, (link) => {
        const route = link.route;

        link.active = url.startsWith(route);
      });
      _.each(regularLinks, (link) => {
        const route = link.route;

        link.active = url.includes(route);
      });
      _.each(this.linksThree, (link) => {
        const route = link.route;

        link.active = url.includes(route);
      });
      this.navBarStylesOne =
        url === '/forgot-password' ? [this.abbreviatedNav] : [this.mobileNav];
    } else {
      this.navBarStylesOne = [this.abbreviatedNav];
    }

    if (!this.userService.getCurrentUser()) {
      _.defer(() => {
        this.navBarLinksOne = [];
        this.showIcons = false;
      });
    } else {
      if (url === '/reset-password') {
        // TODO: ensure correct state for reset password route
      } else {
        const admin = await this.userService.getIsAdmin(this.cognitoUser);
        //    const lindy = await this.userService.getIsAdminOrLindy(this.cognitoUser);
        const orders = await this.userService.hasViewOrders(this.cognitoUser);
        const loggedIn = this.isLoggedIn();
        const isWhiteListed = this.userService.isWhiteListed();

        // for beta testing
        if (isWhiteListed) {

        } else {
          /*
          if (lindyLinks[0].name.includes('Sch')) {
            lindyLinks.shift();
            lindyLinks.shift();
          }
          if (adminLinks[0].name.includes('Sch')) {
            adminLinks.shift();
            adminLinks.shift();
          }
          */
        }

        // set icons and navlinks
        this.showIcons = loggedIn;
        if (loggedIn) {
          if (admin) {
            this.navBarLinksOne = adminLinks;
          } else {
            if (orders) {
              this.navBarLinksOne = orderLinks;
            } else {
              this.navBarLinksOne = regularLinks;
            }
          }
        } else {
          this.navBarLinksOne = [];
        }
      }
    }
  }

  /**
   * @ignore
   */
  private needsLoggedIn(url: string) {
    const loggedOutRoutes = '/reset-password/forgot-password/logout';
    const route = url.split('?')[0];

    const result =
      url !== '/' && !loggedOutRoutes.includes(route);

    return result;
  }
}
