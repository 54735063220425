import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { LargeModalComponent } from '@pjd-development/pjd-dsc-lib';

@Component({
  selector: 'app-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.css']
})
export class ListModalComponent implements AfterViewInit {

  /**
   * for showing additional companies/jobs
   */
  @ViewChild('largeModal') largeModal: LargeModalComponent;

  array$ = of([]);
  buttons = [];
  footerLabel;
  open;
  title;

  constructor() {
    //
  }

  ngAfterViewInit() {
    this.open = this.largeModal.open;
  }
}
