import {
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SavedService, IGraph } from '../saved.service';
import * as _ from 'lodash-es';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { HeaderBarService } from '../header-bar.service';
import { TicketService } from '../ticket.service';
import { TimeRangeService } from '../time-range.service';
import { SuccessService } from '../success.service';
import {
  CreateGraphModalComponent,
} from '../create-graph-modal/create-graph-modal.component';
import { ChartService, IChartData } from '../chart.service';
import { PlantService } from '../plant.service';
import { DatePipe } from '@angular/common';
import {
  DatatableHeaderComponent,
} from '../datatable-header/datatable-header.component';
import { IUserPreferences, PreferencesService } from '../preferences.service';

/**
 *
 */
@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.css'],
  providers: [DatePipe],
})
export class GraphsComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * needed to access checkbox state for auto refresh
   */
  @ViewChild('header') header: DatatableHeaderComponent;

  /**
   * @ignore
   */
  @ViewChild('createModal', { static: true })
  private createModal: CreateGraphModalComponent;

  /**
   * the last updated time to display in the header
   */
  lastUpdatedTime: Date;

  /**
   * data for dropdown component
   */
  dropDownLinksTwo: unknown[]; // IDropDownLink[];

  /**
   * should loading indicator show
   */
  loadingIndicator = false;

  /**
   * loaded user prefs
   */
  userPrefs: IUserPreferences;

  destroyed = false;

  /**
   * chart data to be rendered
   */
  data: Array<IChartData>;

  #currentGraph: IGraph;
  #item: string;

  /**
   * @ignore
   */
  constructor(
    private headerBar: HeaderBarService,
    private savedService: SavedService,
    private router: Router,
    private successService: SuccessService,
    private ticketService: TicketService,
    private timeRangeService: TimeRangeService,
    private userService: UserService,
    private chartService: ChartService,
    private plantService: PlantService,
    private datePipe: DatePipe,
    private prefService: PreferencesService,
    private _区: NgZone,
  ) {
    this.userPrefs = { autoUpdate: false };
  }

  /**
   * for content projection in the header
   */
  get timeOut() {
    const { timeRange } = this.currentGraph;
    const r = this.timeRangeService.computeTimestamp(
      timeRange
    );
    const startValue = this.datePipe.transform(r[0], 'short');
    const endValue = this.datePipe.transform(r[1], 'short');

    return startValue + ' - ' + endValue;
  }

  /**
   * The current graph.
   */
  get currentGraph() {
    return this.#currentGraph;
  }

  /**
   * for content projection in the header
   */
  get plantName() {
    const { plants } = this.currentGraph;

    if (plants.length) {
      return _.join(_.map(plants, p => this.plantService.findPlant(p).value), ', ');
    }

    return this.plantService.allPlants;
  }

  get item() {
    return this.#item;
  }

  /**
   * The current route
   */
  @Input() set item(u: string) {
    this.onEvent({ item: u });
    _.defer(() => {
      this.#item = u;
    });
  }

  set currentGraph(c: IGraph) {
    this.#currentGraph = c;
    if (c) {
      this._refreshData();
    } else {
      this._initNoGraphHeaderBar();
    }
  }

  /**
   * load user prefs
   */
  async ngAfterViewInit() {
    const pref = await this.prefService.find(this.userService.getCurrentUser());

    if (pref) {
      this.userPrefs = pref;
    }
  }

  /**
   * destroy interval on teardown
   */
  ngOnDestroy() {
    this.destroyed = true;
  }

  /**
   * Angular lifecycle hook.
   */
  ngOnInit() {
    this._initDropDownLinks();
    this._updateLoop();
  }

  /**
   * handler for auto update checkbox value changed
   */
  onUpdateChange() {
    //
  }

  /**
   * @ignore
   * Subscribes to route param changes. Used to implement various
   * functionality. Also loads ticket list data.
   */
  private onEvent(routeParams: unknown) {
    const url = _.get(routeParams, 'item', '');
    const createdDate = _.parseInt(url);

    if (!url) {
      this._区.run(() => {
        this.router.navigate(['/graphs/create']);
      });

      return;
    }

    //    this.item = url;

    if (!_.isNaN(createdDate)) {
      this._findGraph(createdDate);
    } else if (url === 'create') {
      this.savedService.currentGraph = null;
    } else {
      if (this.savedService.currentGraph) {
        // load the temporary graph
      } else {
        // prompt to create
        this._区.run(() => this.router.navigate(['/graphs/create']));
      }
    }
    _.defer(() => {
      this.currentGraph = this.savedService.currentGraph;
    });
  }

  /**
   * @ignore
   */
  private async _refreshData() {
    const {
      products, periodInHours, graphType, plants, companies, jobs, timeRange
    } = this.#currentGraph;
    const options = {
      periodInHours,
      graphType,
      plant: plants,
      company: companies,
      job: jobs,
      product: products,
      timestamp: this.timeRangeService.computeTimestamp(timeRange),
    };

    if (options.timestamp.length !== 2) {
      throw new Error('Invalid timestamp');
    }
    this.loadingIndicator = !this.data;

    try {
      const gd = await this.ticketService.getGraph(options);
      const { value } = this.savedService.findGraphType(graphType);

      this.data = _.sortBy(this.chartService[_.camelCase(value)](gd), 'name');
      this._initHeaderBar();
      this.lastUpdatedTime = new Date();
    } catch (e) {
      throw e;
    } finally {
      this.loadingIndicator = false;
    }
  }

  /**
   * @ignore
   */
  private _createDropDownLink(name: string, f: unknown) {
    return {
      name,
      disabled: false,
      active: false,
      onClick: f,
    };
  }

  /**
   * @ignore
   */
  private _initDropDownLinks() {
    this.dropDownLinksTwo = [
      this._createDropDownLink('Add to Saved', this.#save),
      this._createDropDownLink('Print', window.print.bind(window)),
    ];
  }

  /**
   * @ignore
   */
  private async _initHeaderBar() {
    this.headerBar.currentTicketList = this.currentGraph;
    if (this.currentGraph) {
      this.headerBar.title = this.savedService.findGraphType(
        this.currentGraph.graphType).value;
    }
    this.headerBar.beginEdit = this.createModal.beginCreate;
  }

  /**
   * @ignore
   */
  private _initNoGraphHeaderBar() {
    _.defer(() => {
      this.headerBar.beginCreateGraph = this.createModal.beginCreate;
    });
  }

  /**
   * @ignore
   */
  private async _findGraph(createdDate: number) {
    const user = this.userService.getCurrentUser();

    try {
      const r = await this.savedService.find(user, createdDate);

      this.currentGraph = r as IGraph;
    } catch (_e) {
      this._区.run(() => this.router.navigate(['/response-code'], {
        replaceUrl: true,
        queryParams: {
          code: 404,
          message: 'Saved graph not found'
        }
      }));
    }
  }

  /**
   * @ignore
   */
  private _updateLoop = () => {
    _.delay(() => {
      if (this.header?.autoUpdate) {
        this._refreshData();
      }
      if (!this.destroyed) {
        this._updateLoop();
      }
    }, 30000);
  };

  #save = async () => {
    await this.savedService.addGraph(this.currentGraph);

    try {
      this.successService.showMessage('', 'Graph Saved');
    } catch (_e) {
      throw new Error(_e);
    }
  };
}
