import { Injectable } from '@angular/core';
import {
  DatetimeQuestion, DropdownQuestion,
  TextboxQuestion,
} from '@pjd-development/pjd-dsc-lib';
import {
  QuestionBase,
} from '@pjd-development/pjd-dsc-lib/lib/question/question-base';
import _ from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { DropdownService } from '../dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  plantQuestion: DropdownQuestion;
  workTypeQuestion: DropdownQuestion;
  statusQuestion: DropdownQuestion;
  shiftQuestion = this.dropdownService.getShiftQuestion();
  datetimeQuestion = new DatetimeQuestion({
    key: 'date',
    label: 'Date Requested',
    required: true,
    placeholder: '--',
    pickerType: 'calendar',
    invRatio: 6,
    medRatio: 3,
  });

  // textbox questions
  jobQuestion =
    this.textboxQuestionFactory('job', 'Job Name', true);
  jobLocationQuestion =
    this.textboxQuestionFactory('jobLocation', 'Job Location', true);
  truckCountQuestion =
    this.textboxQuestionFactory('truckCount', '# of Trucks', true);
  quoteQuestion =
    this.textboxQuestionFactory('quote', 'Quote #', false);
  customerPOQuestion =
    this.textboxQuestionFactory('customerPO', 'Customer PO', false);
  customerCompanyNameQuestion =
    this.textboxQuestionFactory('customerCompanyName', 'Company Name', true);
  customerCompanyNumberQuestion =
    this.textboxQuestionFactory('customerCompanyNumber', 'Customer #', true);
  customerFirstNameQuestion =
    this.textboxQuestionFactory('customerFirstName', 'First Name', false);
  customerLastNameQuestion =
    this.textboxQuestionFactory('customerLastName', 'Last Name', false);
  customerPhoneQuestion =
    this.textboxQuestionFactory('customerPhone', 'Phone Number', false);
  customerEmailQuestion =
    this.factoryWithHelpText('customerEmail', 'Email Address', false,
      'Seperate multiple email addresses with comma.');
  commentsQuestion =
    this.factoryWithHelpText('comments', 'Customer Comments', false,
      'Note: These comments will be visible to the customer');
  materialTypeQuestion =
    this.textboxQuestionFactory('materialType', 'Material Type', true);
  productCodeQuestion =
    this.textboxQuestionFactory('productCode', 'Prod Code', true);
  jmfNumberQuestion =
    this.textboxQuestionFactory('jmfNumber', 'JMF Number', true);
  tonsQuestion =
    this.textboxQuestionFactory('tons', 'Tons', true);
  truckQuestion =
    this.textboxQuestionFactory('truckCount', '# of Trucks', false);
  materialCommentsQuestion =
    this.textboxQuestionFactory('comments', 'Material Comments', false);

  /**
   * questions for general section
   */
  generalQuestions: Array<Array<QuestionBase<unknown>>>;

  /**
   * questions for customer section
   */
  customerQuestions: Array<Array<QuestionBase<unknown>>>;

  /**
   * questions for materials section
   */
  materialsQuestions: Array<Array<QuestionBase<unknown>>>;

  private _loadedPromise: Promise<void>;

  constructor(
    private log: NGXLogger,
    private dropdownService: DropdownService,

  ) { }

  get loadedPromise() {
    if (!this._loadedPromise) {
      this._loadedPromise = this._initQuestions().then(() => {
        //
      }).catch((_e) => {
        this._loadedPromise = null;
      });
    }

    return this._loadedPromise;
  }

  /**
   * @ignore
   */
  private async _initQuestions() {
    this.log.info('initializing forms');
    this.plantQuestion = await this.dropdownService.getQuestionSingle();
    this.statusQuestion = await this.dropdownService.getStatusQuestion();
    this.workTypeQuestion = await this.dropdownService.getWorkQuestion();
    this.generalQuestions = [
      [
        this.plantQuestion,
        this.datetimeQuestion,
        // NOTE: this is now a native time input
        new DropdownQuestion({
          key: 'time',
          label: 'Initial Drop Time',
          options: [], // this._getTimeOptions(),
          placeholder: '--',
          onChange: () => {
            //
          },
          compareWith: this.dropdownService.cmpFn,
          invRatio: 6,
          medRatio: 3,
          required: true,
          clearable: false,
        }),
        this.shiftQuestion,
      ], [
        this.jobQuestion,
      ], [
        this.jobLocationQuestion,
        this.truckCountQuestion,
      ],
      [
        this.workTypeQuestion,
      ],
      [
        this.quoteQuestion,
        this.customerPOQuestion,
      ],
    ];
    this.customerQuestions = [
      [
        this.statusQuestion,
      ],
      // Company Name* , Customer Number*
      [
        this.customerCompanyNameQuestion,
        this.customerCompanyNumberQuestion,
      ],
      // First Name , Last Name
      [
        this.customerFirstNameQuestion,
        this.customerLastNameQuestion,
      ],
      // Phone Number ,     Email Address
      [
        this.customerPhoneQuestion,
        this.customerEmailQuestion,
      ],
      // Quote Number // Customer PO  // Account Status*  //  Work Type
      // text // text // dropdown // dropdown
      // comments
      [
        this.commentsQuestion,
      ]
    ];
    this.materialsQuestions = [
      [
        this.materialTypeQuestion,
        this.productCodeQuestion,
        this.jmfNumberQuestion,
        this.tonsQuestion,
        this.truckQuestion,
        new DropdownQuestion({
          key: 'time',
          label: 'Drop Time',
          options: [], // this._getTimeOptions(true),
          placeholder: '--',
          onChange: () => {
            //
          },
          compareWith: _.noop, // this.dropdownService.cmpFn,
          invRatio: 6,
          medRatio: 2,
          required: true,
          clearable: false,
        }),
      ],
      [
        this.materialCommentsQuestion
      ],
    ];


    this.log.info('forms initialized');
  }

  /**
   * @ignore
   */
  private factoryWithHelpText(key: string, label: string, required: boolean, helpText: string) {
    return new TextboxQuestion({
      key,
      label,
      placeholder: '--',
      required,
      helpText,
    });
  }

  /**
   * @ignore
   */
  private textboxQuestionFactory(key: string, label: string, required: boolean) {
    return this.factoryWithHelpText(key, label, required, '');
  }
}
