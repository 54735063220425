import { Injectable } from '@angular/core';
import {
  addDays,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMinuteOfInterval,
  format,
  isWithinInterval,
} from 'date-fns';
import { ITicket, IGraphData, IGraphOptions } from './ticket.service';
import * as _ from 'lodash-es';

export interface IChartData {
  name: string;
  series: Array<{
    value: number;
    name: string; // Date
  }>;
}

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() {
    //
  }

  /**
   * handles tonsShippedPerHour graph
   */
  tonsShippedPerHour(data: IGraphData) {
    const { periodInHours } = data.options;
    const xValues = this._computeIntervalArray(data.options, periodInHours);
    const nonVoid = _.filter(data.filteredTickets, y => y.VOID !== 'VOID');
    const plants = _.groupBy(nonVoid, (t) => t.PlantName);
    const d = _.map(plants, (t, name) => {
      const series = this._createSeries(t, xValues);

      return {
        series,
        name,
      };
    });

    return d;
  }

  /**
   * handles tonsShippedPerHourPerProduct graph
   */
  tonsShippedPerHourPerProduct(data: IGraphData) {
    const { periodInHours } = data.options;
    const xValues = this._computeIntervalArray(data.options, periodInHours);
    const byProduct = _.groupBy(
      data.filteredTickets,
      t => t.Product + ' - ' + t.PDescription
    );
    const d = _.map(byProduct, (tickets, name) => ({
      name,
      series: this._createSeries(tickets, xValues),
    }));

    return d;
  }

  /**
   * @ignore
   */
  private _computeIntervalArray(data: IGraphOptions, period: number) {
    const { timestamp } = data;

    // bail if timestamp is wrong type
    if (!_.isString(timestamp[0]) || !_.isString(timestamp[1])) {
      return [];
    }

    // HACK: split off time for safari
    const a = _.first(timestamp[0].split(' ')) as string;
    const b = _.first(timestamp[1].split(' ')) as string;

    // compute interval
    const interval = this._createInterval(
      new Date(a),
      // HACK: move latter date up 1 because of other hack
      addDays(new Date(b), 1),
    );

    if (period === 1) {
      // every hour
      return eachHourOfInterval(interval);
    } else if (period === 24) {
      // every day
      return eachDayOfInterval(interval);
    } else {
      // every 15 mins
      return eachMinuteOfInterval(interval, { step: 15 });
    }
  }

  /**
   * @ignore
   */
  private _createInterval(start: Date, end: Date) {
    return {
      start,
      end,
    };
  }

  /**
   * @ignore
   */
  private _createSeries(tickets: Array<ITicket>, xValues: Array<Date>) {
    const binnedTickets = _.groupBy(tickets, t => {
      let i = xValues.length - 1;

      while (i) {
        const interval = this._createInterval(xValues[i - 1], xValues[i]);

        if (isWithinInterval(new Date(t.TicketDate), interval)) {
          break;
        }
        i--;
      }

      const x = xValues[i];

      return this._format(x);
    });
    const result = _.map(xValues, (x) => {
      const name = this._format(x);
      const a = binnedTickets[name];

      return {
        value: _.sumBy(a || [], 'Qty'),
        name: x,
      };
    });

    return result;
  }

  /**
   * @ignore
   */
  private _format(x: Date) {
    return format(x, 'LLL d pp');
  }
}
