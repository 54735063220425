import { Component, Input, } from '@angular/core';

@Component({
  selector: 'app-circle-loader',
  templateUrl: './circle-loader.component.html',
  styleUrls: ['./circle-loader.component.css']
})
export class CircleLoaderComponent {

  @Input()
  loadingIndicator;

  @Input()
  loadingMessage;

  constructor() {
    //
  }
}
