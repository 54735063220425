import { Component, Input } from '@angular/core';
import _ from 'lodash-es';
import { Location } from '@angular/common';

@Component({
  selector: 'app-response-code',
  templateUrl: './response-code.component.html',
  styleUrls: ['./response-code.component.css']
})
export class ResponseCodeComponent {

  private _code: string;
  private _message: string;

  constructor(
    private location: Location,
  ) { }

  get code() {
    return (+this._code || 404) as unknown as string;
  }
  get message() {
    return this._message || 'Page not found';
  }
  @Input() set code(c: string) {
    this._code = c;
  }

  @Input() set message(m: string) {
    this._message = m;
  }

  back() {
    this.location.back();
  }
}
