import {
  Component,
  NgZone,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash-es';

// local imports
import { UserService } from '../user.service';
import { SuccessService } from '../success.service';
import { first } from 'rxjs/operators';
import { PasswordService } from '../password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  code: string;
  confirmPass: string;
  newPass: string;
  email: string;

  /**
   * @ignore
   */
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private successService: SuccessService,
    private router: Router,
    private notifier: NotifierService,
    private _pass: PasswordService,
    private _区: NgZone,
  ) {
  }

  get cognitoUser() {
    const cognitoUser = this.userService.getCurrentUser();

    return cognitoUser;
  }

  /**
   * Angular lifecycle hook.
   */
  ngOnInit() {
    this.route
      .queryParams
      .pipe(
        first(),
      )
      .subscribe(params => {
        const user = this.cognitoUser;

        this.email = params.email || '';
        if (!this.email && !user) {
          this._区.run(() => this.router.navigate(['/']));
        }
      });
  }

  /**
   * resets password according to current flow type
   */
  resetPassword() {
    const password = this.newPass === this.confirmPass ? this.newPass : null;

    if (password) {
      if (!this.email) {
        const userAttr = this.userService.getSessionUserAttributes();

        if (userAttr) {
          // do init password change
          this._handleNewPassword(password);
        } else {
          // everything else
          this.forceChangePassword(password);
        }
      } else {
        this.resetPass();
      }
    } else {
      this.notifier.notify('warning', 'Passwords do not match');
    }
  }

  /**
   * @ignore
   */
  private _doLogin(email: string, newPass: string) {
    this.userService.login(email, newPass).subscribe(() => {
      this.successService.showMessage('Password Updated',
        'You are now logged in.');
      this._区.run(() => this.router.navigate(['/tickets']));
    });
  }

  /**
   * @ignore
   */
  private async _handleNewPassword(password: string) {
    await this.userService.handleNewPassword(password);

    this._doLogin(this.userService.getLastKnownEmail(), password);
  }

  /**
   * @ignore
   */
  private async forceChangePassword(password: string) {
    // ensure password meets requirements
    if (!this._pass.validatePassword(password)) {
      return this._showWarning();
    }

    // do the change
    await this._pass.forceChangePassword(this.cognitoUser, password);

    // login
    this._doLogin(this.cognitoUser.getUsername(), password);
  }

  /**
   * @ignore
   */
  private async resetPass() {
    await this._pass.confirmForgotPassword(this.email, this.code, this.newPass);

    this._doLogin(this.email, this.newPass);
  }

  /**
   * @ignore
   */
  private _showWarning() {
    this.notifier.notify('warning',
      this._pass.message);
  }
}
