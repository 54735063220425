import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { UserService } from './user.service';
import _ from 'lodash-es';

// declare let ga: (a: string, b: string, c?: string) => void; // <-- Here we declare GA variable

@Injectable()
export class GoogleAnalyticsService {

  constructor(
    router: Router,
    private _user: UserService,
  ) {
    if (!environment.production) {
      return; // <-- If you want to enable GA only in production
    }

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const userId = this._user.getCurrentUser()?.getUsername();
        const _ga = _.get(window, 'ga', _.noop);

        _ga('set', 'user_id', userId);
        _ga('set', 'page', event.url);
        _ga('send', 'pageview');
      }
    });
  }
}
