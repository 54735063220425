import {
  Component, OnInit,
  ViewChild, TemplateRef,
  Input, Output, EventEmitter,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash-es';
import { PlantService } from '../plant.service';
import { SavedService } from '../saved.service';
import { TimeRangeService } from '../time-range.service';

@Component({
  selector: 'app-saved-datatable',
  templateUrl: './saved-datatable.component.html',
  styleUrls: ['./saved-datatable.component.css']
})
export class SavedDatatableComponent implements OnInit {

  @Input() allRows;
  @Input() loadingIndicator;
  @Input() actions;
  @Input() noDataMessage;
  @Input() sorts = [];
  @Output() activate = new EventEmitter<unknown>();
  @Output() _select = new EventEmitter<unknown>();
  @Output() _submit = new EventEmitter<unknown>();

  /**
   *
   */
  @ViewChild('hdrTpl', { static: true }) hdrTpl: TemplateRef<unknown>;

  /**
   *
   */
  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<unknown>;

  /**
   *
   */
  @ViewChild('plantTmpl', { static: true }) plantTmpl: TemplateRef<unknown>;

  /**
   *
   */
  @ViewChild('submitTmpl', { static: true }) submitTmpl: TemplateRef<unknown>;

  /**
   *
   */
  @ViewChild('timeTmpl', { static: true }) timeTmpl: TemplateRef<unknown>;

  /**
   *
   */
  @ViewChild('typeTmpl', { static: true }) typeTmpl: TemplateRef<unknown>;

  /**
   *
   */
  @ViewChild('graphTypeTmpl', { static: true }) graphTypeTmpl: TemplateRef<unknown>;

  /**
   *
   */
  @ViewChild('nameTmpl', { static: true }) nameTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('datatable', { static: true })
  private datatable: DatatableComponent;

  reportType: (s: string) => unknown;
  graphType: (s: string) => unknown;


  plants = [];

  #cols;

  constructor(
    private plantService: PlantService,
    private timeRangeService: TimeRangeService,
    private saved: SavedService,
  ) {
    this.reportType = this.saved.findReportType;
    this.graphType = this.saved.findGraphType;
  }

  get columns() {
    return this.#cols;
  }

  /**
   * accessor for reaching into privates
   */
  get selected() {
    return this.datatable.selected;
  }

  @Input() set columns(c) {
    _.each(c, (col) => {
      const s = col.cellTemplate;
      const t = _.get(this, s);

      col.cellTemplate = t;
    });

    this.#cols = c;
  }

  /**
   * Used by name column to show name and email.
   *
   * @param s The string to split.
   * @returns The first line of the string.
   */
  first(s: string) {
    return _.isString(s) ? s.split('\n')[0] : '';
  }

  /**
   *
   */
  last(s: string) {
    return _.isString(s) ? s.split('\n')[1] : '';
  }

  /**
   * Helper for displaying data in the view.
   *
   * @param p The list plants to look up.
   * @returns The string representing the list of plants.
   */
  plant(p: Array<number>) {
    const result = [];
    const plants = this.plants;

    if (!p || p.length === plants.length) {
      return 'All';
    }
    _.each(p, plant => {
      result.push(this.plantService.findPlant(plant).value);
    });

    return _.join(result, ', ');
  }

  /**
   * Helper function for the view.
   *
   * @param t The time range to lookup.
   * @returns The time range object.
   */
  time(t: string) {
    return this.timeRangeService.findTime(t).value;
  }

  async ngOnInit() {
    await this.plantService.loadedPromise;

    this.plants = this.plantService.plants;
  }

  onActivate(e) {
    this.activate.emit(e);
  }
  onSelect(e) {
    this._select.emit(e);
  }
}
