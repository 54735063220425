import { Injectable, isDevMode } from '@angular/core';
import {
  map,
  shareReplay,
} from 'rxjs/operators';
import _ from 'lodash-es';
import { Observable, forkJoin, of } from 'rxjs';
import { environment } from '../environments/environment';
import { UserService } from './user.service';
import { PlantService } from './plant.service';
import { BackendParams, BackendService } from './backend.service';
import { IOption } from '@pjd-development/pjd-dsc-lib';
import { ICompany } from './company.service';
import { NGXLogger } from 'ngx-logger';

const salessumRoute = '/api/salessum';
const proRoute = `/api/salespro`;
const projobRoute = `/api/salesprojob`;
const ALL_COMPANIES = 'All Companies';
const ALL_JOBS = 'All Jobs';
const ALL_PRODUCTS = 'All Products';

/**
 * @ignore
 */
const root = () => isDevMode() ? '' : environment.backendRoot;

/**
 * @ignore
 */
const standardEndpoint = () => root() + '/api/standard';

/**
 * @ignore
 */
const populateEndpoint = () => root() + '/api/populate';

/**
 * @ignore
 */
const bulkImageEndpoint = () => root() + '/api/bulk-image-download';

/**
 * @ignore
 */
const pad = (n: number) => {
  if (n < 10) {
    return '0' + n;
  }
  return n;
};

/**
 * Interface for calling standard endpoint.
 */
export interface StandardOptions {
  company?: Array<string>;
  job?: Array<string>;
  timestamp: Array<string | Date>; // sometimes date sometimes string
  plant?: Array<number>;
  ticket?: Array<string>;
}

/**
 *
 */
export interface IGraphOptions extends StandardOptions {
  product?: Array<string>;
  periodInHours: number;
  graphType: string;
}

export interface SalesSummaryOptions extends StandardOptions {
  byDay: boolean;
  timestamp: Date[] | string[];
}

/**
 * Interface for calling populate endpoints.
 */
export interface PopulateOptions {
  plant?: Array<number>;
  timeStamp?: Array<Date>;
  company?: Array<string>;
  token?: string;
}

/**
 * Interface for lookUp options.
 */
export interface LookUpOptions {
  timeStamp: Array<Date>;
  plant: Array<number>;
  company: Array<string>;
  job: Array<string>;
}

/**
 * Interface for job object.
 */
export interface IJob extends IOption {
  group?: string;
}

/**
 * interface for products
 */
export interface IProduct extends IOption {
  group?: string;
}

export interface SummaryResponse {
  data: Array<SummaryItem>;
  totalLoads: number; // 383022
  totalQty: number; // 397259.58999999875
}

export interface SummaryItem {
  /* eslint-disable  @typescript-eslint/naming-convention */
  CName: string; // "PETER J CARUSO & SONS"
  Loads: number; // 9
  ODescription: string; // "MISC JOB"
  OrderNum: string; // "MISC"
  PDescription: string; // "25MM BIN 64-22 RAP B"
  Qty: number; // 22.09
  /* eslint-enable  @typescript-eslint/naming-convention */
}

export interface ITicket {
  /* eslint-disable  @typescript-eslint/naming-convention */
  CName: string;
  Customer: string;
  DestinationID: string;
  DestTotal: number;
  DestLoads: number;
  ECMS: string;
  JMF: string;
  LindyJobNum: string;
  Loads: number;
  /* eslint-disable  id-blacklist */
  Number: string;
  /* eslint-enable  id-blacklist */
  ODescription: string;
  OrderNum: string;
  PDescription: string;
  Plant: string;

  // v2
  PlantName?: string;

  Product: string;
  Qty: number;
  TicketDate: string;
  PhysicalDate: string;
  Total: number;
  Truck: string;
  VOID: string;
  Version: string;
  /* eslint-enable  @typescript-eslint/naming-convention */
  // synthetic props
  customer?: string;
  product?: string;
  order?: string;
  qty?: string;
}

export interface IGraphData {
  tickets: Array<ITicket>;
  filteredTickets: Array<ITicket>;
  products: Array<IProduct>;
  filteredProducts: Array<IProduct>;
  options: IGraphOptions;
}

interface IWrapper {
  data: { dailyRecords: unknown[] }[] | unknown[];
}

/**
 * Service for calling ticket related endpoint.
 */
@Injectable({
  providedIn: 'root'
})
export class TicketService {

  /**
   * @ignore
   */
  private allCompanies$: Observable<unknown>;

  /**
   * @ignore
   */
  constructor(
    private backend: BackendService,
    private userService: UserService,
    private _plants: PlantService,
    private log: NGXLogger,
  ) {
  }

  get allCompanies() {
    return ALL_COMPANIES;
  }

  get allJobs() {
    return ALL_JOBS;
  }

  get allProducts() {
    return ALL_PRODUCTS;
  }

  /**
   * Factory for Job object.
   *
   * @param key The key to create object for.
   * @returns Dummy object with the given key.
   */
  createJob(key: string) {
    return {
      key
    } as IJob;
  }

  /**
   * Factory for Product object.
   *
   * @param key The key to create object for.
   * @returns Dummy object with the given key.
   */
  createProduct(key: string) {
    return {
      key
    } as IProduct;
  }

  /**
   *
   */
  async findJobs(jobs: Array<string>) {
    const result = [] as Observable<IJob>[];

    while (jobs?.length) {
      const job = jobs.shift();

      result.push(await this._findJob(job));
    }

    return forkJoin(result);
  }

  /**
   * gets data needed for populating products dropdown and
   * rendering graphs
   */
  async getGraph(json: IGraphOptions) {
    const {
      product,
      //  periodInHours,
      //  graphType
    } = json;
    const productLength = product && product.length;
    const tickets = await this.standardRequest(json);
    const products = _.uniqBy(_.map(tickets, (y) => ({
      key: y.Product,
      value: y.PDescription,
      group: this.allProducts,
    })), 'key') as IProduct[];
    const productArrays = [products] as Array<IProduct>[];

    if (productLength) {
      const productKeys = _.map(product, p => ({ key: p }));

      productArrays.push(productKeys as IProduct[]);
    }

    const filteredProducts = _.intersectionBy(...productArrays, 'key');
    const filteredTickets = !productLength ? tickets : _.filter(tickets, t => {
      /* eslint-disable  @typescript-eslint/naming-convention */
      const { Product } = t;
      /* eslint-enable  @typescript-eslint/naming-convention */

      return _.find(product, p => p === Product);
    });
    const result = {
      tickets,
      products,
      filteredProducts,
      filteredTickets,
      options: json,
    } as IGraphData;

    return result;
  }

  async getSalesByProductCodeAndJob(json: SalesSummaryOptions) {
    const x = await this._getReport(projobRoute, json);

    x.data = _.get(x, 'results');

    const z = this._stripSalesProJob(x);

    return z;
  }

  async getSalesByProductCode(json: SalesSummaryOptions) {
    const x = await this._getReport(proRoute, json);

    return this._stripSalesPro(x);
  }

  async getSalesSummary(json: SalesSummaryOptions) {
    const x = await this._getReport(salessumRoute, json);

    return this._stripSalesSum(x);
  }

  /**
   * Calls standard endoint.
   *
   * @param data The json parameters for the standard call.
   * @returns The ticket list requested.
   */
  async standardRequest(_data: StandardOptions) {
    const json = await this._guardData(_data);
    const dts = this._dateToString;
    const timeStamp = json.timestamp ? _.map(json.timestamp, dts) : null;
    const payload = _.set(json, 'timestamp', timeStamp);

    return this.backend.genericPost(standardEndpoint(),
      this._sanitizeStandard(payload)) as Promise<ITicket[]>;
  }

  /**
   * returns download data if available
   */
  async bulkImageDownload(_tickets: ITicket[]) {
    const s = await this.userService.getSession(this.userService.getCurrentUser());
    const email = s.getIdToken().payload.email as string;
    const tickets = _.map(_tickets, t => `${t.Plant}_${t.Number}`);

    return this.backend.genericPost(bulkImageEndpoint(),
      { email, tickets }) as Promise<{ b64: string; error: Record<string, unknown> }>;
  }

  /**
   * Looks up companies/jobs for a ticket list.
   *
   * @param  json The parameters of the search.
   * @returns The resulting jobs/companies lists, if any.
   */
  async lookUpTicketList(json: LookUpOptions) {
    const companies = json.company || [];
    const jobs = json.job || [];
    const result = {
      company: [],
      jobs: [],
    };

    try {
      const x = await this.populate({
        plant: json.plant,
        timeStamp: json.timeStamp,
      });

      if (!_.isArray(x.companies) || !_.isArray(x.jobs)) {
        console.error('bad response from populate');

        return result;
      }

      if ((x.companies.length - 1 > companies.length) && json.company) {
        result.company = json.company;
      }
      if ((x.jobs.length - 1 > jobs.length) && json.job) {
        result.jobs = json.job;
      }
    } catch (e) {
      this.log.error(e);
    }

    return result;
  }

  /**
   * Calls populate endpoint.
   *
   * @param options The options object for the populate call.
   * @returns The companies and jobs requested.
   */
  async populate(options: PopulateOptions) {
    const dts = this._dateToString;

    await this._plants.loadedPromise;

    const params = {
      plant: this._plants.plantKeys,
      token: options.token,
    } as BackendParams;

    if (options.plant && options.plant.length) {
      // HACK: add wheelertown second plant
      if (_.find(options.plant, p => p === 898)) {
        options.plant.push(220);
      }

      params.plant = options.plant;
    }
    if (options.timeStamp) {
      const s = dts(options.timeStamp[0]);
      const e = dts(options.timeStamp[1]);

      params.timestamp = [s, e];
    }
    if (options.company && options.company.length) {
      params.company = options.company;
    }
    try {
      const x = await this.backend.genericPost(populateEndpoint(), params, true);
      const jobs = _.get(x, 'jobs');

      _.each(jobs as ITicket[], y => {
        _.set(y, 'key', y.OrderNum);
        _.set(y, 'value', y.OrderNum + ' - ' + y.ODescription);
        _.set(y, 'group', this.allJobs);
      });

      const companies = _.get(x, 'companies', []);

      _.each(companies as ITicket[], y => {
        _.set(y, 'key', y.Customer);
        _.set(y, 'value', y.Customer + ' - ' + y.CName);
        _.set(y, 'group', this.allCompanies);
      });

      const lindy = _.remove(companies, (z: ICompany) => z.key === '27106');

      if (lindy.length) {
        companies.unshift(lindy[0]);
      }

      return x as { jobs: IJob[], companies: ICompany[] };
    } catch (_val) {
      // TODO: improve this code path
      // this is mostly for 504s but they are blocked by CORS
      // so we just swallow all of them. womp womp.
      return { jobs: [], companies: [] };
    }
  }

  /**
   * @ignore
   */
  private _dateToString(date: Date) {
    const r = date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      // 'T'
      ' ' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds());
    /* + '.' + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) + 'Z'*/

    return r;
  }

  /**
   * @ignore
   * Can be used to lookup a job object.
   * @param  jobId The id of the job.
   * @returns  Observable with the located job object.
   */
  private async _findJob(jobId: string) {
    const jobs$ = (await this._getCompanies()).pipe(
      map((companies: Record<string, IJob[]>) => {
        const job = _.find(companies.jobs, c => c.key === jobId);

        return job;
      }),
    );

    return jobs$;
  }

  /**
   * @ignore
   */
  private async _getReport(endpoint: string, json: SalesSummaryOptions) {
    json.timestamp = _.map(json.timestamp, this._dateToString);
    if (json.byDay) {
      json.timestamp = _.map(json.timestamp, (s) => s.split(' ')[0]);
    }

    try {
      const x = (await this.backend.genericPost(
        environment.backendRoot + endpoint,
        this._sanitizeStandard(json),
        true
      )) as IWrapper;

      _.each(x.data, (d: { dailyRecords: Record<string, unknown>[] }) => {
        const first = _.first(d.dailyRecords);

        if (first && first.Date) {
          d.dailyRecords = [];
        }
      });

      return x as SummaryResponse;
    } catch (val) {
      const error = _.get(val, 'error');

      if (error === 'recordsets does not exist in /sales') {
        return { data: [] };
      }

      throw val;
    }
  }

  /**
   * @ignore
   */
  private _stripReport(report: IWrapper, prop: string) {
    const { data } = report;

    _.each(data, (day) => {
      const first = _.get(day, 'dailyRecords', day) as unknown[];
      const ff = _.first(first);
      const customer = _.get(ff, prop);

      if (customer === '') {
        first.shift();
      }
    });

    return report;
  }

  /**
   * @ignore
   */
  private _stripSalesPro(report: IWrapper) {
    const prop = 'Customer';

    return this._stripReport(report, prop);
  }

  /**
   * @ignore
   */
  private _stripSalesProJob(report: IWrapper) {
    const prop = 'Customer';

    return this._stripReport(report, prop);
  }

  /**
   * @ignore
   */
  private _stripSalesSum(report: IWrapper) {
    const prop = 'CName';

    return this._stripReport(report, prop);
  }

  /**
   * @ignore
   */
  private _sanitizeStandard(payload: StandardOptions) {
    return _.omitBy(payload, p => _.isNil(p) || p.length === 0) as BackendParams;
  }

  /**
   * @ignore
   * Function for getting all companies. Useful for performing lookups on ids.
   * @returns An observable which emits all companies.
   */
  private async _getCompanies() {
    if (!this.allCompanies$) {
      this.allCompanies$ = of(await this.populate({
      })).pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
    }

    return this.allCompanies$;
  }

  /**
   * @ignore
   */
  private async _guardData(json: StandardOptions) {
    const perms = await this.userService.getPermissions();

    // FIXME: not secure
    if (!perms) {
      return json;
    }

    // i am an admin
    if (perms.jobs.length === 0 && perms.companies.length === 0) {
      return json;
    }

    // i am not an admin
    if (!json.company || json.company.length === 0) {
      json.company = _.map(perms.companies, c => c.key);
    } else {
      json.company = _.intersectionWith(json.company, perms.companies,
        (a, b) => a === _.get(b, 'key'));
    }
    if (!json.job || json.job.length === 0) {
      json.job = _.map(perms.jobs, j => j.key);
    } else {
      json.job = _.intersectionWith(json.job, perms.jobs,
        (a, b) => a === _.get(b, 'key'));
    }

    return json;
  }
}
