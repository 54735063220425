// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-id,
.company-name {
  font-weight: bold;
}

.order-info {
  display: grid;
  grid-template-columns: 240px 70px;
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

@media only screen and (max-width: 525px) {
  .order-info {
    grid-template-columns: auto auto;
  }
}

.order-block:nth-child(odd) {
  background: white;
}

.order-block:nth-child(even) {
  background: #f5f5f5;
}

.order-block.odd {
  background: white;
}

.order-block.even {
  background: #f5f5f5;
}

.order-info {
  padding: 15px;
  line-height: 18px;
}

.order-info:hover {
  cursor: pointer;
  background: #FDFCF3;
}

.order-line:nth-child(even) {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/app/order/order.component.css"],"names":[],"mappings":"AAAA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iCAAiC;EACjC,kCAAkC;EAClC,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE;IACE,gCAAgC;EAClC;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".company-id,\n.company-name {\n  font-weight: bold;\n}\n\n.order-info {\n  display: grid;\n  grid-template-columns: 240px 70px;\n  grid-template-rows: repeat(4, 1fr);\n  grid-column-gap: 0px;\n  grid-row-gap: 0px;\n}\n\n@media only screen and (max-width: 525px) {\n  .order-info {\n    grid-template-columns: auto auto;\n  }\n}\n\n.order-block:nth-child(odd) {\n  background: white;\n}\n\n.order-block:nth-child(even) {\n  background: #f5f5f5;\n}\n\n.order-block.odd {\n  background: white;\n}\n\n.order-block.even {\n  background: #f5f5f5;\n}\n\n.order-info {\n  padding: 15px;\n  line-height: 18px;\n}\n\n.order-info:hover {\n  cursor: pointer;\n  background: #FDFCF3;\n}\n\n.order-line:nth-child(even) {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
