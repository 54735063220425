import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { IGraphForm } from './create-graph-modal/create-graph-modal.component';
import { IReportForm } from './report-modal/report-modal.component';

const msInDay = 60 * 60 * 24 * 1000 - 1;

export interface TimeRange {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class TimeRangeService {

  /**
   * @ignore
   */
  private _orderTimeRanges = [
    /*
    // updated 4/25/24
    Today
    Tomorrow
    Yesterday
    Next 24 Hours
    Last 24 Hours
    Previous Week
    Previous Month
    Week to Date
    Month to Day
    Year to Day
    Custom
    */
    //   { key: 'all', value: 'All dates' },
    { key: 'cday', value: 'Today' },
    { key: 'nday', value: 'Tomorrow' },
    { key: 'pday', value: 'Yesterday' },

    { key: 'n24', value: 'Next 24 hours' },
    { key: 'last24', value: 'Last 24 hours' },

    { key: 'pweek', value: 'Last Week' },
    { key: 'pmonth', value: 'Last Month' },

    { key: 'cweek', value: 'Week to Date' },

    // { key: 'n7', value: 'Next 7 days' },

    { key: 'cmonth', value: 'Month to Day' },
    { key: 'cyear', value: 'Year to Day' },

    //    { key: 'nweek', value: 'Next week' },
    //  { key: 'nmonth', value: 'Next month' },

    { key: 'cust', value: 'Custom' },
  ];

  /**
   * @ignore
   */
  private _timeRanges = _.cloneDeep(this._orderTimeRanges);

  constructor() {
    //
  }

  /**
   * Get the static list of time ranges.
   *
   * @returns The list of time ranges.
   */
  get timeRanges() {
    return _.clone(this._timeRanges);
  }

  /**
   * Get the static list of time ranges.
   *
   * @returns The list of time ranges.
   */
  get orderTimeRanges() {
    return _.clone(this._orderTimeRanges);
  }

  /**
   *
   */
  computeTimestamp(timeRange: string): Array<Date> {
    const {
      subtract1,
      subtract24,
      setMidnight,
      getFirstOfMonth,
      getFirstOfYear,
      add24,
      add1
    } = this;
    let r = [];

    if (!timeRange) {
      return r;
    }

    const isLast24 = timeRange[0] === 'l';
    const isCurrent = timeRange[0] === 'c';
    const isNext = timeRange[0] === 'n';
    // const isPrevious = timeRange[0] === 'p';
    const isYear = timeRange.includes('year');
    const isCustom = timeRange.includes('cust');
    const is24 = !isCustom && timeRange.includes('24');
    const is7 = !isCustom && timeRange.includes('7');
    const isAll = timeRange === 'all';
    const isMonth = timeRange.includes('month');
    const isWeek = timeRange.includes('week');
    const isDay = timeRange.includes('day');

    if (isAll) {
      // const range = 864000000000000;

      // NOTE: will not include orders before year 1970 or after year 29349
      r = [new Date(0), new Date()];
    }
    if (isCustom) {
      const s = timeRange.split('cust')[1];

      if (_.isString(s)) {
        const t = s.split(',');

        r[0] = new Date(+t[0]);
        r[1] = new Date(+t[1]);
      }
    }
    if (is24) {
      if (isLast24) {
        r = [subtract24(new Date()), new Date()];
      } else {
        r = [new Date(), this.add24(new Date())];
      }
    }
    if (is7) {
      r = [new Date(), this.add7(new Date())];
    }
    if (isDay) {
      if (isCurrent) {
        r = [
          setMidnight(new Date()),
          this.add24(this.setMidnight(new Date()))
        ];
      } else if (isNext) {
        r = [
          add24(add1(setMidnight(new Date()))),
          add24(this.add24(this.setMidnight(new Date())))
        ];
      } else {
        r = [
          subtract24(setMidnight(new Date())),
          subtract1(setMidnight(new Date()))
        ];
      }
    }
    if (isWeek) {
      if (isCurrent) {
        r = [
          this.lastSunday(new Date()),
          this.add7(this.lastSunday(new Date())),
        ];
      } else if (isNext) {
        const sun = this.nextSunday(new Date());

        r = [sun, this.add7(sun)];
      } else {
        r = [
          this.lastSunday(subtract24(this.lastSunday(new Date()))),
          subtract1(this.lastSunday(new Date())),
        ];
      }
    }
    if (isMonth) {
      if (isCurrent) {
        r = [
          getFirstOfMonth(new Date()),
          this.getFirstOfNextMonth(new Date())
        ];
      } else if (isNext) {
        const mon = this.getFirstOfNextMonth(new Date());

        r = [
          mon,
          this.getFirstOfNextMonth(mon)
        ];
      } else {
        r = [
          getFirstOfMonth(subtract24(getFirstOfMonth(new Date()))),
          subtract1(getFirstOfMonth(new Date())),
        ];
      }
    }
    if (isYear) {
      r = [
        getFirstOfYear(new Date()),
        new Date()
      ];
    }

    return r;
  }

  /**
   * Utility function for looking up a time range.
   *
   * @param time The string representing the time range.
   * @returns The time range object.
   */
  findTime(time: string) {
    return _.find(this.timeRanges, x => time.includes(x.key));
  }

  /**
   * Utility function for looking up a time range.
   *
   * @param _time The string representing the time range.
   * @returns The time range object.
   */
  findOrderTime(_time: string) {
    const time = _time || '';

    return _.find(this.orderTimeRanges, x => time.includes(x.key));
  }

  packCustom(form: IGraphForm | IReportForm) {
    return 'cust' + form.startTime.valueOf() + ','
      + form.endTime.valueOf();
  }

  /**
   * @ignore
   */
  private lastSunday(d: Date) {
    const sunday = new Date(d.setDate(d.getDate() - d.getDay()));

    return this.setMidnight(sunday);
  }

  /**
   * @ignore
   */
  private nextSunday(d: Date) {
    let sun = this.lastSunday(d);

    sun = this.add7(d);
    sun = this.add7(d);

    return sun;
  }

  /**
   * @ignore
   */
  private getFirstOfMonth(d) {
    return new Date(d.getFullYear(), d.getMonth(), 1);
  }

  /**
   * @ignore
   */
  private getFirstOfNextMonth(d) {
    return new Date(d.getFullYear(), d.getMonth() + 1, 1);
  }

  /**
   * @ignore
   */
  private getFirstOfYear(d) {
    return new Date(d.getFullYear(), 0, 1);
  }

  /**
   * @ignore
   */
  private subtract24(d) {
    return new Date(d.valueOf() - msInDay);
  }

  /**
   * @ignore
   */
  private subtract1(d) {
    return new Date(d.valueOf() - 6000);
  }

  /**
   * @ignore
   */
  private add1(d) {
    return new Date(d.valueOf() + 6000);
  }

  /**
   * @ignore
   */
  private add24(d) {
    return new Date(d.valueOf() + msInDay);
  }

  /**
   * @ignore
   */
  private add7(d) {
    _.each(_.range(7), __ => {
      d = this.add24(d);
    });

    return d;
  }

  /**
   * @ignore
   */
  private setMidnight(d) {
    d.setHours(0, 0, 0, 0);

    return d;
  }
}
