import { Component, Input } from '@angular/core';
import { IOrder } from '../orders.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent {

  @Input() isOdd = true;
  @Input() order: IOrder;

  constructor() {
    //
  }

  get value() {
    return this.order.status;
  }

  get trk() {
    return this.order.trk + ' TRK';
  }

  get tons() {
    return this.order.tons + ' TON';
  }

  get rap() {
    const rap = this.order.tonsRAP;

    return rap ? rap + ' RAP' : null;
  }
}
