import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  showNav = true;

  constructor() {
    //
  }

  hideNav() {
    this.showNav = false;
  }

  restoreNav() {
    this.showNav = true;
  }
}
