import { Component, Input, } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ListModalComponent } from '../list-modal/list-modal.component';
import * as _ from 'lodash-es';
import { map } from 'rxjs/operators';
import { TicketService } from '../ticket.service';
import { TimeRangeService } from '../time-range.service';
import { PlantService } from '../plant.service';
import { DatePipe } from '@angular/common';
import { CompanyService } from '../company.service';
import { IOrder, OrdersService } from '../orders.service';
import { ITicketList } from '../saved.service';

@Component({
  selector: 'app-filters',
  providers: [DatePipe],
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent {

  /**
   * for showing additional companies/jobs
   */
  @Input() largeModal: ListModalComponent;

  /**
   *
   */
  content: {
    filternum: number;
    open: boolean;
    clickId: string;
    filters: unknown;
  };

  /**
   * provides an order to the filters object
   */
  keys: string[];

  /**
   * alternative labels
   */
  labels: string[];

  /**
   * @ignore
   */
  private _tl: ITicketList & IOrder; // Ticket list

  /**
   * @ignore
   */
  private ticketKeys = ['plants', 'work date', 'company', 'jobs'];

  /**
   * @ignore
   */
  private ticketLabels = ['Plants', 'Work Date', 'Companies', 'Jobs'];

  /**
   * @ignore
   */
  private orderLabels = ['Plants', 'Date', 'Status', 'Shift'];

  /**
   * @ignore
   */
  private orderKeys = ['plants', 'work date', 'status', 'shift'];

  /**
   * @ignore
   */
  private scheduleLabels = ['Plants', 'Status', 'Shift'];

  /**
   * @ignore
   */
  private scheduleKeys = ['plants', 'status', 'shift'];

  /**
   * @ignore
   */
  private _url: string;

  /**
   * @ignore
   */
  constructor(
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private plantService: PlantService,
    private timeRangeService: TimeRangeService,
    private tix: TicketService,
    private order: OrdersService,
  ) { }

  get currentTicketList() {
    return this._tl;
  }

  get url() {
    return this._url;
  }

  /**
   * for conditional logic
   */
  @Input() set url(y) {
    this._url = y;
    this.updateKeysAndLabels();
  }

  /**
   * main data
   */
  @Input() set currentTicketList(tl) {
    this._tl = tl;
    if (tl) {
      this.updateFilterLabel();
    }
  }


  isTicket() {
    return this.keys.length === 4 && this.keys[3] === 'jobs';
  }

  /**
   * opens modal
   */
  show(key: string, value: Observable<Array<unknown>>) {
    this.largeModal.array$ = value;
    this.largeModal.footerLabel = key;
    this.largeModal.title = key;
    this.largeModal.open();

    // NOTE: removed this, hope it doesnt break anything
    // event.stopPropagation();
  }

  /**
   * @ignore
   */
  private async updateFilterLabel() {
    const selectedPlants = this.currentTicketList.plants;
    const shift = this.currentTicketList.shift;
    const status = this.currentTicketList.status;
    const allPlants = !selectedPlants || !selectedPlants.length;
    const allStatus = !status || !status.length;
    const tickets = this.currentTicketList.ticket;
    const { jobs, companies, timeRange } = this.currentTicketList;
    const filters = {
      plants: of([this.plantService.allPlants]),
      company: of([this.tix.allCompanies]),
      jobs: of([this.tix.allJobs]),
      // order stuff
      status: of(['All Statuses']),
      shift: of(['All Shifts']),
    } as Record<string, unknown>;

    // ensure plants are loaded
    await this.plantService.loadedPromise;

    const _plants = this.plantService.plants;

    if (selectedPlants && selectedPlants.length
      && selectedPlants.length !== _plants.length) {
      const value = [];

      _.each(selectedPlants, p => {
        const plant = (this.plantService.findPlant(p));

        if (plant) {
          value.push(plant.value);
        }
      });
      filters.plants = of(value);
    }
    if (tickets) {
      filters.tickets = of(tickets);
    }
    if (timeRange) {
      const r = this.timeRangeService.computeTimestamp(
        timeRange
      );

      // TODO: validate r[0], r[1] before transforming
      const startValue = this.datePipe.transform(r[0], 'short');
      const endValue = this.datePipe.transform(r[1], 'short');

      filters['work date'] = of([startValue + ' - ' + endValue]);
    }
    if (this.keys && this.isTicket()) {
      const zz = await this.tix.lookUpTicketList({
        plant: !allPlants ? selectedPlants : this.plantService.plantKeys,
        timeStamp: this.timeRangeService.computeTimestamp(timeRange),
        job: jobs,
        company: companies,
      });
      const { jobs: job, company } = zz;

      if (company.length) {
        const value = this.companyService.findCompanies(company).pipe(
          map(k => _.map(k, y => _.get(y, 'key') + ' - ' + _.get(y, 'value'))),
        );

        filters.company = value;
      }
      if (job.length) {
        const value = (await this.tix.findJobs(job)).pipe(
          map(k => _.map(k, y => _.get(y, 'value'))),
        );

        filters.jobs = value;
      }
    }
    if (shift) {
      const i = this.order.getShiftIndex(shift);
      const s = this.order.shifts[i];

      filters.shift = of([s]);
    }
    if (!allStatus) {
      const ss = _.map(status, sss => {
        const i = this.order.getStatusIndex(sss);
        const s = this.order.statuses[i];

        return s;
      });

      filters.status = of([ss]);
    }
    this.content = {
      filternum: _.keys(filters).length,
      open: false,
      clickId: 'groupOne',
      filters,
    };
  }

  /**
   * @ignore
   */
  private updateKeysAndLabels() {
    const isOrder = this.url.includes('orders');
    const isSchedule = this.url.includes('schedules');

    if (!isOrder && !isSchedule) {
      this.keys = this.ticketKeys;
      this.labels = this.ticketLabels;
    } else {
      if (isOrder) {
        this.keys = this.orderKeys;
        this.labels = this.orderLabels;
      } else {
        this.keys = this.scheduleKeys;
        this.labels = this.scheduleLabels;
      }
    }
  }
}
