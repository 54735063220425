import {
  ChangeDetectorRef, Component,
  Input,
  NgZone,
  OnDestroy, OnInit
} from '@angular/core';
import { HeaderBarService } from '../header-bar.service';
import { OrdersService, IOrderOptions, IOrder } from '../orders.service';
import { IOrderList } from '../saved.service';
import * as _ from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { TimeRangeService } from '../time-range.service';
import { Router } from '@angular/router';
import { KeyValue } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { LodashService } from '../lodash.service';

@Component({
  selector: 'app-schedule-page',
  templateUrl: './schedule-page.component.html',
  styleUrls: ['./schedule-page.component.css']
})
export class SchedulePageComponent implements OnInit, OnDestroy {

  /**
   * rows in table
   */
  allRows: unknown;

  loadingIndicator = true;
  loadingMessage = 'Loading';

  keys = _.keys;

  /**
   * @ignore
   * contains route param
   */
  #item: string;

  constructor(
    private _orders: OrdersService,
    private headerBar: HeaderBarService,
    private router: Router,
    private timeRangeService: TimeRangeService,
    private log: NGXLogger,
    private cd: ChangeDetectorRef,
    private _区: NgZone,
    private renderer: Renderer2,
    private lodash: LodashService,
  ) { }

  private get currentTicketList() {
    return this.headerBar.currentTicketList;
  }

  @Input() set item(x: string) {
    this.onEvent({ item: x });
  }

  /**
   * strip seconds from time
   */
  formatTime(t: string) {
    const noSec = _.join(t.split(':00 '), ' ');

    if (noSec[0] === '0') {
      return noSec.substring(1);
    }

    return noSec;
  }

  // Order by time
  keyOrder = (a: KeyValue<string, unknown>, b: KeyValue<string, unknown>): number => {
    const aParts = a.key.split(' ');
    const bParts = b.key.split(' ');

    if (aParts[1] !== bParts[1]) {
      return aParts[1] < bParts[1] ? -1 : 1;
    }

    const aHour = +aParts[0].split(':')[0];
    const bHour = +bParts[0].split(':')[0];

    return aHour < bHour ? -1 : 1;
  };

  // order by sortScore
  sortOrder = (a: KeyValue<string, Record<string, number>>, b: KeyValue<string, Record<string, number>>): number => {
    const aa = a.value.sortScore;
    const bb = b.value.sortScore;

    return aa < bb ? -1 : 1;
  };

  ngOnDestroy() {
    this.headerBar.clearListCallback();
    this.renderer.removeClass(document.body, 'schedules-page');
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'schedules-page');
    _.defer(() => {
      this.headerBar.title = null;
      this.cd.detectChanges();
    });
    this.headerBar.registerListCallback(() => this.refreshData());
  }

  /**
   * handles opening detail modal
   */
  onActivate(order: IOrder) {
    this.log.debug('order', order);
    this.lodash.hide('.calendar');
    this._区.run(() => this.router.navigate(['/order-details/' + order.id]));
  }

  /**
   * detect change in filter list
   */
  onListChanged(_list: IOrderList) {
    // force to today
    if (!_list.timeRange.includes('cust')) {
      this.headerBar.currentTicketList.timeRange = 'cday';
    }
    this.refreshData();
  }

  /**
   * handles updates to route parameters
   */
  onEvent(routeParams: { item: string }) {
    const url = routeParams.item;

    if (!url) {
      _.defer(() => {
        this.router.navigate(['/schedules/standard']);
      });

      return;
    }
    this.#item = url;

    //   this._initDropDownLinks();
  }

  plantId(s: string) {
    const ss = s.split('\n');

    return ss[0];
  }

  plantName(s: string) {
    const ss = s.split('\n');

    return ss[1];
  }

  /**
   * Performs a refresh of the table.
   */
  async refreshData() {
    const json: IOrderOptions = {} as IOrderOptions;
    const selectedPlants: Array<number> = this.currentTicketList.plants;
    const timestamp =
      this.timeRangeService.computeTimestamp(
        this.currentTicketList.timeRange);

    // prepare request body
    json.timestamp = timestamp;
    json.plant = selectedPlants;
    json.shift = this.currentTicketList.shift;
    json.status = this.currentTicketList.status;

    // clear table while loading
    this.loadingIndicator = true;
    this.allRows = [];

    try {
      const x = await this._orders.standardRequest(json);
      const plants = _.groupBy(x, 'Plant') as Record<string, IOrder[] | unknown>;

      _.each(plants, (v: IOrder[], k) => {
        plants[k] = _.groupBy(v, 'time');
      });
      this.allRows = plants;
    } catch (e) {
      throw e;
    } finally {
      this.loadingIndicator = false;
    }
  }
}
