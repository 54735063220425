import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class LodashService {

  toLower = _.toLower;

  /**
   * Pass-through for lodash function.
   *
   * @param d The date to be shortened
   * @returns The short string representing the date.
   */
  isDate = _.isDate;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    //
  }

  /**
   * finds element by query
   */
  querySelector(query: string) {
    const el = this.document.querySelector(query) as HTMLElement;

    return el;
  }

  /**
   * hides an element by query
   */
  hide(query: string) {
    const el = this.querySelector(query);

    el.style.visibility = 'hidden';
  }
}
