import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  NgZone,
} from '@angular/core';
import {
  DropdownQuestion,
  LargeModalComponent,
  ModalComponent,
  DatetimeQuestion,
  AlertComponent,
  IButton,
  IOption,
} from '@pjd-development/pjd-dsc-lib';
import { CompanyService, ICompany } from '../company.service';
import { TimeRangeService, TimeRange } from '../time-range.service';
import { PlantService, IPlant } from '../plant.service';
import * as _ from 'lodash-es';
import { TicketService, IJob, IProduct, IGraphOptions } from '../ticket.service';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import {
  QuestionBase,
} from '@pjd-development/pjd-dsc-lib/lib/question/question-base';
import { IGraph, SavedService } from '../saved.service';
import { differenceInHours } from 'date-fns';
import { HashService } from '../hash.service';
import { DropdownService } from '../dropdown.service';

/**
 *
 */
export interface IGraphForm {
  companies: Array<ICompany>;
  jobs: Array<IJob>;
  timeRange: TimeRange;
  graph?: { key: string };
  graphGroup?: { key: number };
  plants: Array<IPlant>;
  startTime: Date;
  endTime: Date;
  ticketNumbers?: string;
  products: Array<IProduct>;
}

/**
 *
 */
@Component({
  selector: 'app-create-graph-modal',
  templateUrl: './create-graph-modal.component.html',
  styleUrls: ['./create-graph-modal.component.css']
})
export class CreateGraphModalComponent implements OnInit, AfterViewInit {

  /**
   * @ignore
   */
  @ViewChild('createGraphModal', { static: true })
  private createGraphModal: LargeModalComponent;

  /**
   * @ignore
   */
  @ViewChild('editForm', { static: true })
  private editForm: ModalComponent;

  /**
   * @ignore
   */
  @ViewChild('invalidTimeRange', { static: true })
  private invalidTimeRange: AlertComponent;

  /**
   *
   */
  questions: Array<Array<QuestionBase<unknown>>>;

  /**
   *
   */
  buttons: Array<IButton>;

  private hashCode: (s: string) => number;
  private canonicalize: (obj: object) => string;

  #currentGraph: IGraph;
  #companiesQuestion: DropdownQuestion;
  #jobsQuestion: DropdownQuestion;
  #productsQuestion: DropdownQuestion;
  #plantsQuestion: DropdownQuestion;
  #timeRangeQuestion: DropdownQuestion;

  /**
   * @ignore
   */
  constructor(
    private companyService: CompanyService,
    private _dropdownService: DropdownService,
    private _hash: HashService,
    private timeRangeService: TimeRangeService,
    private plantService: PlantService,
    private savedService: SavedService,
    private router: Router,
    private tix: TicketService,
    private userService: UserService,
    private _区: NgZone,
  ) {
    this.canonicalize = this._hash.canonicalize;
    this.hashCode = this._hash.hashCode;

    const companyOpts = this._dropdownService
      .companyDropdownQuestionOptionsFactory();
    const jobOpts = this._dropdownService.jobDropdownQuestionOptionsFactory();

    // companies dropdown
    companyOpts.onChange = () => {
      // this.onCompaniesChange();
    };
    this.#companiesQuestion = new DropdownQuestion(companyOpts);

    // jobs dropdown
    this.#jobsQuestion = new DropdownQuestion(jobOpts);
  }

  /**
   * helper to get user
   */
  get cognitoUser() {
    return this.userService.getCurrentUser();
  }

  get currentGraph() {
    return this.#currentGraph;
  }

  /**
   *
   */
  @Input() set currentGraph(r: IGraph) {
    this.#currentGraph = r;
  }

  /**
   * Add graph button handler
   */
  beginCreate = () => {
    // set up defaults
    if (this.currentGraph) {
      this._resetForm(this.currentGraph);
    } else {
      this.#clearAll();
    }

    // click invisible button
    this.createGraphModal.open();
  };

  /**
   * Runs after DOM is ready.
   */
  ngAfterViewInit() {
    this.invalidTimeRange.destroy();
  }

  /**
   * Runs after constructor.
   */
  ngOnInit() {
    this._initEditQuestions();
    this._initButtons();
  }

  /**
   * @ignore
   */
  private _convertTimeRange(form: IGraphForm) {
    const key = _.get(form, 'timeRange.key');

    return key === 'cust' ? this.timeRangeService.packCustom(form) : key;
  }

  /**
   * @ignore
   */
  private _initButtons() {
    this.buttons = [
      {
        text: 'Submit',
        click: this.#submit,
      },
      {
        text: 'Clear All',
        click: this.#clearAll,
      }
    ];
  }

  /**
   * @ignore
   */
  private _initCompaniesQuestion() {
    this.#companiesQuestion = new DropdownQuestion({
      key: 'companies',
      label: 'Companies',
      options: [],
      placeholder: 'Select Companies',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        this._onCompaniesChange();
      },
      compareWith: this._dropdownService.cmpFn,
    });

    return this.#companiesQuestion;
  }

  /**
   * @ignore
   */
  private async _initEditQuestions() {
    const graphQuestion = new DropdownQuestion({
      key: 'graph',
      label: 'Graph Type',
      options: this.savedService.graphTypes,
      placeholder: 'Choose a Graph Type',
      onChange: () => {
        //
      },
      clearable: false,
      compareWith: this._dropdownService.cmpFn,
    });
    const graphGroupQuestion = new DropdownQuestion({
      key: 'graphGroup',
      label: 'Group Graph',
      options: this.savedService.graphGroupTypes,
      placeholder: 'Choose a Grouping Option',
      onChange: () => {
        //
      },
      clearable: false,
      compareWith: this._dropdownService.cmpFn,
    });
    const customTimeRow = [
      new DatetimeQuestion({
        key: 'startTime',
        label: 'Starting Date/Time',
        placeholder: 'Date and time',
        afterPickerClosed: () => { this._onStartTimeChange(); },
      }),
      new DatetimeQuestion({
        key: 'endTime',
        label: 'Ending Date/Time',
        placeholder: 'Date and time',
        afterPickerClosed: () => { this._onEndTimeChange(); },
      })
    ];

    this.questions = [
      [
        graphQuestion,
      ],
      [
        graphGroupQuestion,
      ],
      [
        await this._initPlantsQuestion(),
      ],
      [
        this._initTimeRangeQuestion(),
      ],
      customTimeRow,
      [
        this._initCompaniesQuestion(),
      ],
      [
        this._initJobsQuestion(),
      ],
      [
        this._initProductsQuestion(),
      ]
    ];
  }

  /**
   * @ignore
   */
  private _initJobsQuestion() {
    this.#jobsQuestion = new DropdownQuestion({
      key: 'jobs',
      label: 'Jobs',
      options: [],
      placeholder: 'Select Jobs',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      compareWith: this._dropdownService.cmpFn,
    });

    return this.#jobsQuestion;
  }

  /**
   * @ignore
   */
  private async _initPlantsQuestion() {
    // ensure plants are loaded
    await this.plantService.loadedPromise;

    this.#plantsQuestion = new DropdownQuestion({
      key: 'plants',
      label: 'Plants',
      options: this.plantService.plants,
      placeholder: 'Select Plants',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      onChange: () => {
        this._onPlantChange();
      },
      compareWith: this._dropdownService.cmpFn,
    });

    return this.#plantsQuestion;
  }

  /**
   * @ignore
   */
  private _initProductsQuestion() {
    this.#productsQuestion = new DropdownQuestion({
      key: 'products',
      label: 'Products',
      options: [],
      placeholder: 'Select Products',
      groupBy: 'group',
      selectableGroup: true,
      multiple: true,
      compareWith: this._dropdownService.cmpFn,
    });

    return this.#productsQuestion;
  }

  /**
   * @ignore
   */
  private _initTimeRangeQuestion() {
    this.#timeRangeQuestion = new DropdownQuestion({
      key: 'timeRange',
      label: 'Time Range',
      options: this.timeRangeService.timeRanges,
      placeholder: 'Select Timestamp',
      onChange: () => {
        this._onTimeRangeChange();
      },
      compareWith: this._dropdownService.cmpFn,
      clearable: false,
    });

    return this.#timeRangeQuestion;
  }

  /**
   * @ignore
   */
  private _keys(a: Array<IOption>) {
    return _.compact(_.map(a, c => c.key));
  }

  /**
   * @ignore
   */
  private _onCompaniesChange() {
    this._updateDropdowns(true);
  }

  /**
   * @ignore
   */
  private _onEndTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this._onTimeRangeChange();
  }

  private _onPlantChange() {
    this._onTimeRangeChange();
  }

  /**
   * @ignore
   */
  private _onStartTimeChange() {
    this.editForm.form.patchValue({ timeRange: { key: 'cust' } });
    this._onTimeRangeChange();
  }

  /**
   * @ignore
   * Handles when the time range in the form is changed.
   */
  private _onTimeRangeChange(isLindy?: boolean) {
    const form = this.editForm.form; // type info?
    const values = form.value;
    const timeRange = values.timeRange;
    const converted = this._convertTimeRange(values);
    const timestamp = this.timeRangeService.computeTimestamp(converted);
    const lindy = this.companyService.createLindy();
    const json = {
      companies: isLindy ? [lindy] : [{ group: this.tix.allCompanies }],
      jobs: [{ group: this.tix.allJobs }],
    } as Record<string, string | Date | unknown[]>;

    if (timeRange !== 'cust') {
      json.startTime = timestamp[0];
      json.endTime = timestamp[1];
    }
    form.patchValue(json);
    this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private _resetForm(current: IGraph) {
    const form = this._ticketListToForm(current) as unknown;

    // patch value
    this.editForm.form.patchValue(form);

    // update dropdowns
    this._updateDropdowns();
  }

  /**
   * @ignore
   */
  private _ticketListToForm(tl: IGraph) {
    const r = this.timeRangeService.computeTimestamp(tl.timeRange);
    const companies = _.map(tl.companies, this.companyService.createCompany);
    const jobs = _.map(tl.jobs, this.tix.createJob);
    const products = _.map(tl.products, this.tix.createProduct);
    const plants = _.map(tl.plants, this.plantService.findPlant);

    // fix for preventing weird group behavior
    _.each(plants, p => delete p.group);

    const form: IGraphForm = {
      plants: plants.length ? plants : [{ group: this.plantService.allPlants }],
      startTime: r[0],
      endTime: r[1],
      timeRange: this.timeRangeService.findTime(tl.timeRange),
      companies: companies.length ? companies : [
        { group: this.tix.allCompanies } as ICompany,
      ],
      jobs: jobs.length ? jobs : [
        { group: this.tix.allJobs } as IJob,
      ],
      graph: { key: tl.graphType },
      graphGroup: { key: tl.periodInHours },
      products: products.length ? products : [
        { group: this.tix.allProducts } as IProduct,
      ],
    };

    return form;
  }

  /**
   * @ignore
   */
  private async _updateDropdowns(onlyJobs?: boolean) {
    const perms = await this.userService.getPermissions(this.cognitoUser);

    if (perms.companies.length === 0 && perms.jobs.length === 0) {
      this._populateDropdowns(onlyJobs);
    } else {
      this.#companiesQuestion.options = perms.companies;
      this.#jobsQuestion.options = perms.jobs;
    }
  }

  /**
   * @ignore
   */
  private async _populateDropdowns(onlyJobs?: boolean) {
    const values = this.editForm.form.value;
    // const start = values.startTime;
    // const end = values.endTime;
    const options = {
    } as IGraphOptions & { timeStamp: Date[] };
    const tl = this.#toTicketList(values);

    options.periodInHours = tl.periodInHours;
    options.graphType = tl.graphType;
    options.plant = tl.plants;
    if (onlyJobs) {
      options.company = tl.companies;
    }
    options.timeStamp = this.timeRangeService.computeTimestamp(tl.timeRange);
    if (options.timeStamp.length === 2) {
      options.timestamp = options.timeStamp;

      try {
        const graphData = await this.tix.getGraph(options);
        const { products } = graphData;
        const castedProducts = products as unknown as {
          key: string;
          value: string;
        }[];

        this.#productsQuestion.options = castedProducts;
      } catch (e) {
        const m = _.join(_.map(e.error, (v, _k) => v.message), '\n');

        _.defer(() => {
          throw new Error(m);
        });
      } finally {

      }

      const c = await this.tix.populate(options);

      if (!onlyJobs) {
        this.#companiesQuestion.options = c.companies;
      }
      this.#jobsQuestion.options = c.jobs;
    }
  }

  #clearAll = () => {
    this._resetForm({
      //   byDay: false,
      graphType: this.savedService.graphTypes[0].key,
      periodInHours: 1,
      timeRange: 'pday',
      username: this.userService.getUsername(),
    });
  };

  #submit = () => {
    const tl = this.#toTicketList(this.editForm.form.value);
    const timestamp = this.timeRangeService.computeTimestamp(tl.timeRange);

    if (timestamp.length !== 2) {
      // error?
      return;
    }

    const hours = differenceInHours(timestamp[1], timestamp[0]);

    // TODO: add real validation
    if (tl.periodInHours === 0.25 && hours > 24) {
      this.invalidTimeRange.show();

      return;
    }

    this.createGraphModal.close();
    this.savedService.currentGraph = tl;
    this._区.run(() => this.router.navigate(['/graphs/_' + this.hashCode(this.canonicalize(tl))]));
  };

  #toTicketList = (form: IGraphForm): IGraph => {
    const jobs = this._keys(form.jobs);
    const c = this._keys(form.companies);
    const plants = this._keys(form.plants) as unknown[] as number[];
    const products = this._keys(form.products);
    const tl: IGraph = {
      graphType: form.graph && form.graph.key,
      jobs, // jobs.length ? jobs : this.keys(this.jobsQuestion.options),
      companies: c, // c.length ? c : this.keys(this.companiesQuestion.options),
      plants, // plants.length ? plants : this.keys(this.plantsQuestion.options),
      timeRange: this._convertTimeRange(form),
      username: this.userService.getUsername(),
      periodInHours: form.graphGroup && form.graphGroup.key,
      products,
    };

    return tl;
  };
}
