import {
  Component,
  ContentChild,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import * as _ from 'lodash-es';
import { DropDownLink } from '@pjd-development/pjd-dsc-lib';
import {
  DatatableFooterDirective,
} from '@swimlane/ngx-datatable';
import {
  DatatableHeaderComponent
} from '../datatable-header/datatable-header.component';

export interface IActivateEvent {
  type: 'keydown' | 'click' | 'dblclick';
  event: MouseEvent;
  row: unknown; // this.row
  // column; // this.column
  // value; // this.value
  // cellElement; // this._element
}

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit, OnDestroy {

  /**
   * The state of the checkbox.
   */
  @Input() autoUpdate: boolean;

  /**
   * sets the bulk downloading state
   */
  @Input() downloading = false;

  /**
   * The state of the checkbox.
   */
  @Input() multiSelectEnabled: boolean;

  /**
   * copy for ngx-datatable
   */
  @Input() columns: Array<unknown>;

  /**
   *
   */
  @Input() defaultSort: string;

  /**
   *
   */
  @Input() dropDownLinksTwo: Array<DropDownLink>;

  /**
   * Exposes parameter from ngx-datatable
   */
  @Input() footerHeight = 0;

  /**
   *
   */
  @Input() hideTopBar = false;

  /**
   *
   */
  @Input() loadingMessage: string;

  /**
   *
   */
  @Input() rowHeight: unknown;

  /**
   *
   */
  @Input() showAutoRefresh = true;

  /**
   *
   */
  @Input() showMultiSelect = true;

  /**
   *
   */
  @Input() tableSubmit = false;

  /**
   *
   */
  @Output() activate = new EventEmitter<IActivateEvent>();

  /**
   *
   */
  @Output() refresh = new EventEmitter();

  /**
   *
   */
  @Output() searchFilterUpdated = new EventEmitter();

  /**
   *
   */
  @Output() _select = new EventEmitter();

  /**
   *
   */
  @Output() autoUpdateChange = new EventEmitter();

  /**
   *
   */
  @Output() multiSelectEnabledChange = new EventEmitter();

  /**
   *
   */
  @ViewChild('header') header: DatatableHeaderComponent;

  /**
   * Footer template gathered from the ContentChild
   *
   * autofixed in update, not sure if actually working
   */
  @ContentChild(DatatableFooterDirective) footer: DatatableFooterDirective;

  /**
   * used by auto refresh
   */
  lastUpdatedTime;

  messages;

  bulkDownloadText;

  /**
   * used by ngx-datatable
   */
  rows = [];

  selected = [];

  private _sorts = [];
  private destroyed = false;
  private _transform: (x: unknown) => unknown; // object iterator

  /**
   * @ignore
   */
  private _loadingIndicator = true;

  /**
   * @ignore
   */
  private _allRows = [];

  /**
   * @ignore
   */
  private _datatable;

  /**
   * @ignore
   **/
  constructor(
  ) {
    this.noDataMessage = '';
  }

  get headerHeight() {
    if (!this.allRows) {
      return 0;
    }

    return this.allRows.length ? 33 : 0;
  }

  get allRows() {
    return this._allRows;
  }

  get transform() {
    return this._transform || _.identity;
  }

  get loadingIndicator() {
    return this._loadingIndicator;
  }

  get sorts() {
    return this._sorts;
  }

  /**
   *
   */
  @Input() set noDataMessage(s) {
    this.messages = {
      emptyMessage: `
<div class="w-100 p-4">
<p class="text-center">
<i
class="material-icons not-interested"
>
not_interested
</i>
<br>
${s}
</p>
</div>
`,
      totalMessage: 'total',
      selectedMessage: null, // selected
    };
  }

  /**
   * hooks to update the view on change
   */
  @Input() set searchFilter(s2) {
    const filtered = _.filter(this.allRows, (row) => {
      const s = JSON.stringify(_.values(row)).toLowerCase();

      return s.includes(_.toLower(s2));
    });

    this.rows = filtered;
    this.searchFilterUpdated.emit(filtered);
  }

  /**
   *
   */
  @Input() set sorts(s) {
    this._sorts = s;
    if (!s || s.length === 0 && this.allRows) {
      this.rows = [];
      _.defer(() => {
        this.searchFilter = '';
      });
    }
  }

  /**
   *
   */
  @Input() set loadingIndicator(b) {
    this._loadingIndicator = b;
  }

  @Input() set transform(t) {
    this._transform = t;
    this.allRows = this.allRows;
  }

  /**
   * Available rows to display in table
   */
  @Input()
  set allRows(r) {
    this._allRows = r;
    this.selected = [];
    this.lastUpdatedTime = new Date();
    _.each(r, this.transform);
    this.sorts = [];
    if (this.defaultSort) {
      this.sorts = [
        {
          prop: this.defaultSort,
          dir: 'asc'
        },
      ];
    } else {
      _.defer(this.redraw);
    }
  }

  getRowClass = (r) => {
    const selected = this._datatable?.selected;
    const match = _.find(selected, (s) => s.Number === r.Number);

    return {
      selected: !!match,
    };
  };

  ngOnDestroy() {
    this.destroyed = true;
  }

  /**
   * Lifecycle hook for angular.
   **/
  ngOnInit() {
    this._updateLoop();
  }

  /**
   * bubbles event from multi selection checkbox
   */
  onMulti(e) {
    if (!e) {
      this.selected = [];
    }
    this.multiSelectEnabledChange.emit(e);
  }

  searchEventListener(e) {
    this.searchFilter = e;
  }

  /**
   * Handles events from ngx-datatable by bubbling up.
   **/
  _onActivate($event: IActivateEvent) {
    this.activate.emit($event);
  }

  /**
   * Used by ngx-datatable to handle selection.
   *
   * @param options An options object with `selected` property.
   */
  _onSelect({ selected }, dt) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this._select.emit(this.selected);
    this.bulkDownloadText = `${this.selected.length} Tickets Selected`;
    this._datatable = dt;
  }

  /**
   * @ignore
   */
  private redraw = () => {
    if (this._datatable) {
      this._datatable.footer = this.footer;
    }
  };

  /**
   * @ignore
   */
  private _updateLoop = () => {
    _.delay(() => {
      if (this.header?.autoUpdate) {
        this.refresh.emit();
      }
      if (!this.destroyed) {
        this._updateLoop();
      }
    }, 30000);
  }
}
