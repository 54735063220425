import { Component, NgZone, } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash-es';
import { PasswordService } from '../password.service';

// local imports
import { SuccessService } from '../success.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  /**
   * set by component in template
   */
  email: string;

  constructor(
    private userService: PasswordService,
    private router: Router,
    private successService: SuccessService,
    private _notifier: NotifierService,
    private _区: NgZone,
  ) { }

  /**
   * invoked by button in template
   */
  resetPassword() {
    this._forgotPassword(this.email);
  }

  /**
   * @ignore
   */
  private async _forgotPassword(email: string) {
    try {
      await this.userService.forgotPassword(email);

      this.successService.showMessage('Password Reset Link Sent',
        'A password reset link has been sent to the email address provided.');
      this._区.run(() => this.router.navigate(['/reset-password'], {
        queryParams: {
          email,
        },
      }));
    } catch (e) {
      if (_.isError(e)) {
        const { message } = e;
        const FORCE_CHANGE_PASSWORD =
          'User password cannot be reset in the current state';
        const forceChangePasswordMessage =
          'Your account is locked. Please contact your system administrator.';

        if (_.isString(message)) {
          if (message.includes(FORCE_CHANGE_PASSWORD)) {
            return this._notifier.notify('error', forceChangePasswordMessage);
          } else {
            throw e;
          }
        }
      }

      throw new Error(ForgotPasswordComponent.name + ': unknown error occured');
    }
  }
}
