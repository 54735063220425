// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-accordion .list-accordion__item{
  padding: 16px;
  color: #4A4A4A;
}

.first-load-12-26-52 {
	color: #4A4A4A;
	font-family: "Open Sans";
	font-size: 11px;
	font-weight: bold;
	line-height: 12px;
	text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/app/report-collapse/report-collapse.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;CACC,cAAc;CACd,wBAAwB;CACxB,eAAe;CACf,iBAAiB;CACjB,iBAAiB;CACjB,iBAAiB;AAClB","sourcesContent":[".list-accordion .list-accordion__item{\n  padding: 16px;\n  color: #4A4A4A;\n}\n\n.first-load-12-26-52 {\n\tcolor: #4A4A4A;\n\tfont-family: \"Open Sans\";\n\tfont-size: 11px;\n\tfont-weight: bold;\n\tline-height: 12px;\n\ttext-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
