import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DatetimeQuestion } from '@pjd-development/pjd-dsc-lib';
import {
  QuestionBase
} from '@pjd-development/pjd-dsc-lib/lib/question/question-base';
import _ from 'lodash-es';
import { LodashService } from '../lodash.service';

@Component({
  selector: 'app-error-summary',
  templateUrl: './error-summary.component.html',
  styleUrls: ['./error-summary.component.css']
})
export class ErrorSummaryComponent {
  @Input() questions: QuestionBase<string>[];
  @Input() form: FormGroup;

  constructor(
    public lodash: LodashService,
  ) {

  }

  /**
   *
   */
  errors(control: AbstractControl) {
    return _.keys(control.errors);
  }

  /**
   *
   */
  findQuestion(key: string) {
    const q = _.find(_.flatten(this.questions) as QuestionBase<string>[], q => q.key === key);

    return q
  }

  shouldShowErrors() {
    const shouldShow = _.some(_.flatten(this.questions), (q: QuestionBase<string>) => {
      if (q.controlType === 'datetime') {
        const dtq = q as DatetimeQuestion;
        const { untouched } = dtq;

        if (untouched) {
          return false;
        }
      }

      const control = this.form.controls[q.key];

      return control.invalid && (control.dirty || control.touched);
    });

    return shouldShow;
  }
}
