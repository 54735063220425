import {
  Component, NgZone, OnDestroy,
  Renderer2, OnInit, Input,
} from '@angular/core';
import { HeaderBarService } from '../header-bar.service';
import _ from 'lodash-es';
import { NavBarService } from '../nav-bar.service';
import { Router } from '@angular/router';
import { ISummaryReportRequest, OrdersService } from '../orders.service';
import { format, parse } from 'date-fns';
import { PlantService } from '../plant.service';
import { NGXLogger } from 'ngx-logger';
import { LodashService } from '../lodash.service';

@Component({
  selector: 'app-plant-schedule-summary-page',
  templateUrl: './plant-schedule-summary-page.component.html',
  styleUrls: ['./plant-schedule-summary-page.component.css']
})
export class PlantScheduleSummaryPageComponent implements OnInit, OnDestroy {

  @Input() status;
  @Input() plants;
  @Input() shift;

  loading = true;
  loadingMessage = 'Generating Summary Report';
  html: string;

  oldHead: string;

  private _date;

  constructor(
    private header: HeaderBarService,
    private nav: NavBarService,
    private router: Router,
    private _区: NgZone,
    private _orders: OrdersService,
    private _plants: PlantService,
    private log: NGXLogger,
    private renderer: Renderer2,
    private lodash: LodashService,
  ) {
  }

  get date() {
    return this._date;
  }

  // hook to trigger load
  @Input() set date(d) {
    this._date = d;
    this._loadData();
  }

  /**
   * handles close button
   */
  beginClose() {
    /*
        const isDirty = true;

        if (isDirty) {
          this.currentAction = 'close';
          this.confirmationModal.open();
        } else { */
    this.onClose();
    // }
  }

  /**
   * handle print action
   */
  beginPrint() {
    window.print();
  }

  handleAfterPrint = () => this._区.run(() => this.onClose());

  /**
   * angular lifecycle hook
   */
  ngOnDestroy() {
    window.removeEventListener('afterprint', this.handleAfterPrint);
    this.renderer.removeClass(document.body, 'app-view-summary-report');
    this.renderer.setStyle(document.body, 'background-color', 'white');
    this.nav.restoreNav();
    if (this.oldHead) {
      this.setHeadHtml(this.oldHead, false);
    }
  }

  /**
   * angular lifecycle hook
   */
  ngOnInit() {
    window.addEventListener('afterprint', this.handleAfterPrint);
    this.renderer.setStyle(document.body, 'background-color', 'white');
    this.renderer.addClass(document.body, 'app-view-summary-report');
  }

  /**
   * @ignore
   */
  private async _loadData() {
    try {
      const params = await this.paramsToRequest();
      const { success, json, error } = await this._orders.generateSummaryReport(params);

      if (!(success === false)) {
        // clear nav bars
        _.defer(() => {
          this.nav.hideNav();
          this.header.clearAll(true);
        });

        const parts = json.split('<BODY>');
        const body = parts[1].split('</BODY>')[0];
        const head = parts[0].split('<HEAD>')[1].split('<title>')[0];

        // set body and head
        this.html = body;
        this.setHeadHtml(head, true);
        this.loading = false;
        _.defer(() => {
          // set print handler
          this.lodash.querySelector('.page-actions').addEventListener('click',
            () => window.print(),
            false,
          );
        });
      } else {
        this._区.run(() => this.router.navigate(['/response-code/404/' + error], { replaceUrl: true }));
      }
    } catch (e) {
      this.log.error(e);
    }
  }

  /**
   * @ignore
   */
  private onClose() {
    this.header.handleCloseNav();
  }

  /**
   * @ignore
   * converts route params to request for backend
   */
  private async paramsToRequest() {
    await this._plants.loadedPromise;

    this.log.debug('loaded');

    const { date, status, plants, shift } = this;
    const parsed = parse(date, 'yyyy-MM-dd', new Date());
    const _date = _.attempt(format, parsed, 'MM/dd/yyyy');
    const _status = _.map(status, s => this._orders.statuses[this._orders.getStatusIndex(s)]);
    const _shift = this._orders.findShift(shift).value;
    const _plants = _.map(_.isString(plants) ? [plants] : plants, p => {
      const pp = this._plants.findPlant(+p);

      return pp.plantNumber + ' ' + pp.plantShortName;
    });

    return {
      date: _date,
      status: _status,
      shift: _shift,
      selectedPlants: _plants,
    } as ISummaryReportRequest;
  }

  /**
   * @ignore
   * updates style tags
   */
  private setHeadHtml(html: string, append: boolean) {
    const h = this.lodash.querySelector('head');

    this.oldHead = h.innerHTML;

    if (append) {
      h.innerHTML += html;
    } else {
      h.innerHTML = html;
    }
  }
}
