import { Injectable } from '@angular/core';
import {
  Router, ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard {
  constructor(
    private _user: UserService,
    private _router: Router,
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this._user.getCurrentUser();

    return this._user.getIsAdmin(user).then((x) => {
      if (!x) {
        this._router.navigate(['/']);
      }
      return x;
    });
  }
}
