import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

/**
 *
 */
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  /**
   * @ignore
   */
  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  /**
   * Angular lifecycle hook.
   */
  ngOnInit() {
    this.userService.logout();
    this.router.navigate(['/']);
  }

}
