import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TicketsComponent } from './tickets/tickets.component';
import { ReportsComponent } from './reports/reports.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { SavedComponent } from './saved/saved.component';
import {
  ForgotPasswordComponent,
} from './forgot-password/forgot-password.component';
import {
  ResetPasswordComponent,
} from './reset-password/reset-password.component';
import { ResponseCodeComponent } from './response-code/response-code.component';
import { GraphsComponent } from './graphs/graphs.component';
import { OrdersComponent } from './orders/orders.component';
import { SchedulePageComponent } from './schedule-page/schedule-page.component';
import { AdminGuard } from './admin.guard';
// import { BetaGuard } from './beta.guard';
import { LindyGuard } from './lindy.guard';
import {
  NewOrderPageComponent
} from './new-order-page/new-order-page.component';
import {
  PlantScheduleSummaryPageComponent
} from './plant-schedule-summary-page/plant-schedule-summary-page.component';
import { EmailComponent } from './email/email.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'tickets',
    component: TicketsComponent,
  },
  {
    path: 'tickets/:item',
    component: TicketsComponent,
  },
  {
    path: 'schedules',
    component: SchedulePageComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'schedules/:item',
    component: SchedulePageComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'orders/:item',
    component: OrdersComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'order-details',
    component: NewOrderPageComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'order-details/:item',
    component: NewOrderPageComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'email',
    component: EmailComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'email/:item',
    component: EmailComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'plant-schedule-summary',
    component: PlantScheduleSummaryPageComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'reports/:item',
    component: ReportsComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'graphs',
    component: GraphsComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'graphs/:item',
    component: GraphsComponent,
    canActivate: [LindyGuard],
  },
  {
    path: 'saved',
    component: SavedComponent,
  },
  {
    path: 'saved/:item',
    component: SavedComponent,
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'response-code',
    component: ResponseCodeComponent,
  },
  {
    path: 'response-code/:code/:message',
    component: ResponseCodeComponent,
  },
  {
    path: '**',
    component: ResponseCodeComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
