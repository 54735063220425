// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep #void-checkbox label {
  position: relative;
  top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/app/orders/orders.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":[":host ::ng-deep #void-checkbox label {\n  position: relative;\n  top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
