import { Injectable } from '@angular/core';
import _ from 'lodash-es';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class UnloadService {

  private _blockUnload: boolean;
  private _cb: (...args: unknown[]) => unknown;

  constructor(
    private log: NGXLogger,
  ) {
    this.clearCallback();
    this.unblockUnload();
  }

  shouldBlockUnload() {
    return this._blockUnload;
  }

  blockUnload() {
    this._blockUnload = true;
  }

  unblockUnload() {
    this._blockUnload = false;
  }

  registerCallback(cb: (...args: unknown[]) => unknown) {
    this._cb = cb;
  }

  clearCallback() {
    this._cb = _.noop;
  }

  doCallback() {
    this.log.debug('doing callback');
    if (this._cb) {
      this._cb();
    }
  }
}
