import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

// local imports
import { UserService } from '../user.service';
import { HeaderBarService } from '../header-bar.service';
import * as _package from '../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /**
   * Needs to be public since it is model in html
   */
  email: string;

  /**
   * Needs to be public since it is model in html
   */
  password: string;

  /**
   * used in version label
   */
  version = _.get(_package, 'version');

  constructor(
    private router: Router,
    private userService: UserService,
    private headerBar: HeaderBarService,
    private _区: NgZone,
  ) {
  }

  /**
   * Handle login attempt
   */
  login() {
    this.userService.login(this.email, this.password).subscribe({
      next: (_x: CognitoUserSession) => {
        this._区.run(() => this.router.navigate(['tickets']));
      },
      error: (x: unknown) => {
        console.error(x);
        if (x === 'newPasswordRequired') {
          // new pass flow
          this._区.run(() => this.router.navigate(['reset-password']));
        }

        // error is displayed by service
      }
    });
  }

  /**
   * Angular lifecycle hook.
   */
  ngOnInit() {
    _.defer(() => {
      this.headerBar.clearAll();
    });
    if (this.userService.getCurrentUser()) {
      this._区.run(() => this.router.navigate(['/tickets']));
    }
  }
}
