// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.class {
  display: block;
  margin-top: 2px;
  margin-bottom: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/app/admin/admin.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["span.class {\n  display: block;\n  margin-top: 2px;\n  margin-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
