import {
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit, ViewChild,
} from '@angular/core';
import { HeaderBarService } from '../header-bar.service';
import _ from 'lodash-es';
import { NavBarService } from '../nav-bar.service';
import { MDCRipple } from '@material/ripple';
import {
  Router,
} from '@angular/router';
import {
  IEmail,
  IMaterial, IOrder, IPersonnel, OrdersService
} from '../orders.service';
import { PlantService } from '../plant.service';
import {
  ConfirmationModalComponent, QuestionControlService,
} from '@pjd-development/pjd-dsc-lib';
import { NotifierService } from 'angular-notifier';
import { NGXLogger } from 'ngx-logger';
import { isBefore, endOfTomorrow, format } from 'date-fns';
import { UnloadService } from '../unload.service';
import { FormGroup, UntypedFormGroup, } from '@angular/forms';
import { QuestionsService } from './questions.service';
import { UserService } from '../user.service';
import { LodashService } from '../lodash.service';
import { DeviceDetectorService } from 'ngx-device-detector';

interface IExtendedMaterial extends IMaterial {
  needsApproved?: boolean;
  //  id: string;
  form: FormGroup;
}

@Component({
  selector: 'app-new-order-page',
  templateUrl: './new-order-page.component.html',
  styleUrls: ['./new-order-page.component.css']
})
export class NewOrderPageComponent implements OnInit, OnDestroy {

  /**
   * @ignore
   */
  @ViewChild('confirmationModal', { static: true })
  private confirmationModal: ConfirmationModalComponent;

  form: FormGroup;
  generalForm: UntypedFormGroup;
  customerForm: UntypedFormGroup;

  /**
   * flag to control loading state
   */
  loading = true;

  questionsLoaded = false;

  /**
   * controls visibility of desktop actions
   */
  isDesktopDevice = true;

  /**
   * text for spinner loader
   */
  loadingMessage = 'Loading Order';

  /**
   * saved order data from server
   */
  order: IOrder;

  /**
   * more actions
   */
  dropDownLinksTwo = [];

  /**
   * styling for more actions dropdown
   */
  dropDownStylesTwo = [
    {
      type: 'more-base-green',
      size: 'normal',
      name: '',
      position: 'right',
      icon: 'more_horiz',
    },
  ];

  isSaving = false;

  isLocked = false;

  /**
   * object for tracking ui elements
   */
  materials = [] as IExtendedMaterial[];

  /**
   * parsed data for table
   */
  parsedEditHistory = [];

  /**
   * parsed data for table
   */
  parsedEmailHistory = [] as IEmail[];

  /**
   * parsed data for table
   */
  parsedPersonnel = [] as IPersonnel[];

  /**
   * action for confirmation modal
   */
  currentAction = '';

  /**
   * flag for suppressing error boxes
   */
  attemptedSave = false;

  /**
   * @ignore
   */
  private _lastDirty = false;

  /**
   * @ignore
   */
  private _isEditing = false;

  /**
   * @ignore
   */
  private _lastCanApprove = false;

  /**
   * @ignore
   * desktop actions
   */
  private desktopActions = [
    {
      name: 'Send Email',
      disabled: true,
      active: false,
      //   route: '',
      onClick: () => {
        if (!this.dropDownLinksTwo[0].disabled) {
          this.beginEmail();
        }
      },
    },
    /*
        {
          name: 'History',
          disabled: false,
          active: false,
          // route: ''
          onClick: () => {
            this.doHistory();
          },
        },
    */
    {
      name: 'Cancel Order',
      disabled: true,
      active: false,
      // route: '',
      onClick: () => {
        if (!this.dropDownLinksTwo[1].disabled) {
          this.beginCancel();
        }
      },
    },
  ];

  /**
   * @ignore
   * mobile actions
   */
  private mobileActions = [
    {
      name: 'Approve',
      disabled: true,
      active: false,
      onClick: () => {
        if (!this.dropDownLinksTwo[2].disabled) {
          this.beginApprove();
        }
      }
    },
    {
      name: 'Edit',
      disabled: true,
      active: false,
      onClick: () => {
        if (!this.dropDownLinksTwo[3].disabled) {
          this.beginEdit();
        }
      }
    },
    {
      name: 'Save',
      disabled: true,
      active: false,
      onClick: () => {
        if (!this.dropDownLinksTwo[4].disabled) {
          this.doSave();
        }
      }
    },
    {
      name: 'Save & New',
      disabled: true,
      active: false,
      onClick: () => {
        if (!this.dropDownLinksTwo[5].disabled) {
          this.doSaveAndNew();
        }
      }
    },
    {
      name: 'Copy Order',
      disabled: true,
      active: false,
      onClick: () => {
        if (!this.dropDownLinksTwo[6].disabled) {
          this.beginCopy();
        }
      }
    },
  ];

  /**
   * @ignore
   * holds order id
   */
  #item: string;

  constructor(
    private header: HeaderBarService,
    private nav: NavBarService,
    private router: Router,
    private _orders: OrdersService,
    public lodash: LodashService,
    private _plant: PlantService,
    private _notify: NotifierService,
    private log: NGXLogger,
    private unload: UnloadService,
    private _zone: NgZone,
    public q: QuestionsService,
    private user: UserService,
    private qcs: QuestionControlService,
    private deviceService: DeviceDetectorService,
  ) {
  }

  get emailSent() {
    const emails = (this.order || {}).emails;
    const first = _.first(emails);

    return first ? 'Email sent on ' + first.time + '.' : 'No';
  }

  get canApprove() {
    const can = (!this.isInternal || this.isUpcoming) && !this.someMaterialNeedsApproved;
    const next = can && !this.isLocked;

    //    if (next !== this._lastCanApprove) {
    //    this._updateDropdowns();
    // }
    this._lastCanApprove = next;

    return next;
  }

  get forms() {
    return _.map([this, ...this.materials], x => x.form);
  }

  get someMaterialNeedsApproved() {
    return _.some(this.materials, m => m.needsApproved);
  }

  get isUpcoming() {
    const date = new Date(this.order?.dateTime);

    return isBefore(date, endOfTomorrow());
  }

  get canSave() {
    const canSave = this.isDirty() && this.isValid();

    return canSave;
  }

  get isSaved() {
    return !!this.order;
  }

  get isDraft() {
    return this.status === this._orders.statuses[0];
  }

  get isSubmitted() {
    return this.status === this._orders.statuses[1];
  }

  get isApproved() {
    return this.status === this._orders.statuses[2];
  }

  get isCancelled() {
    const isCancelled = this.status === this._orders.statuses[3];

    return isCancelled;
  }

  get isInternal() {
    return !!this.order?.operationUNID;
  }

  // draft/submitted/approved/canceled
  get status() {
    if (this.order) {
      return this.order.status;
    }

    return this._orders.statuses[0];
  }

  /**
   * maintains current editing state
   */
  get isEditing() {
    return this._isEditing;
  }

  @Input() set item(x: string) {
    this.onEvent({ item: x });
  }

  /**
   * maintains current editing state
   */
  set isEditing(b) {
    this._isEditing = b;
    this._updateDropdowns();
  }

  /**
   * approves the ith material
   */
  approve(i: number) {
    this.materials[i].needsApproved = false;
    this._doNotifyMaterial(i);
  }

  /**
   * handles approve button
   */
  beginApprove() {
    this.currentAction = 'approve';
    this.confirmationModal.open();
  }

  /**
   * handles cancel action
   */
  beginCancel() {
    this.currentAction = 'cancel';
    this.confirmationModal.open();
  }

  /**
   * handles close button
   */
  beginClose() {
    const isDirty = this.isDirty();

    if (isDirty) {
      this.currentAction = 'close';
      this.confirmationModal.open();
    } else {
      this.onClose();
    }
  }

  /**
   * handles copy button
   */
  beginCopy() {
    this.currentAction = 'copy';
    this.confirmationModal.open();
  }

  /**
   * handles edit button
   */
  async beginEdit() {
    const isLocked = await this._checkLock();

    if (!isLocked) {
      await this._orders.lockDocument(this.#item);
      this._setUnloadHook();
    } else {
      return this._doNotifyLocked('edit');
    }

    // set edit flag
    this.isEditing = true;

    // enable form fields
    _.map(this.forms, f => f.enable());
  }

  /**
   * closing confirmation modal
   */
  dismissConfirmation() {
    // in case we need to do something on close
  }

  /**
   * handles button in confirmation modal
   */
  async doApprove() {
    const isLocked = await this._checkLock();

    if (!isLocked) {
      await this._orders.lockDocument(this.#item);
    } else {
      return this._doNotifyLocked('approve');
    }

    const order = this._getOrderFromForms();
    const { updatedOrder, success } = await this._orders.approveOrder(order);

    if (success) {
      const action = '𝗔𝗽𝗽𝗿𝗼𝘃𝗲𝗱';

      this._doNotify(action);
      this._loadExistingOrder(updatedOrder);
      this._releaseLock();
    }
  }

  /**
   * does cancel
   */
  async doCancel() {
    const isLocked = await this._checkLock();

    if (!isLocked) {
      await this._orders.lockDocument(this.#item);
    } else {
      return this._doNotifyLocked('cancel');
    }

    try {
      const { success, updatedOrder } = await this._orders.cancelOrder(this._getOrderFromForms());

      if (success) {
        const action = '𝗖𝗮𝗻𝗰𝗲𝗹𝗲𝗱';

        this._doNotify(action);
        this._loadExistingOrder(updatedOrder);
        this._releaseLock();
      }
    } catch (_e) {
      this.log.error(_e);
    }
  }

  /**
   * does copy
   */
  doCopy() {
    const action = '𝗖𝗼𝗽𝗶𝗲𝗱';
    const copy = _.omit(this.order, [
      'id', 'date', 'status',
      'emailSent', 'emails',
      'editHistory',
    ]);

    // provide sane defaults
    _.set(copy, 'emails', []);
    _.set(copy, 'emailSent', '');
    this.materials = [];

    // clear existing material ids
    _.each(copy.materials, m => {
      m.id = this.newId();
    });

    // show notif
    this._doNotify(action);

    // load forms
    _.defer(() => this._loadExistingOrder(copy as IOrder, true));
  }

  /**
   * handles history buttons
   */
  doHistory() {
    this.scrollTo({
      top: document.body.scrollHeight, behavior: 'smooth'
    });
  }

  /**
   * handles new material button
   */
  createMaterial() {
    this.materials.push({
      id: this.newId(),
      form: this.qcs.toFormGroup(_.flatten(this.q.materialsQuestions)),
    } as IExtendedMaterial);
    _.defer(() => {
      const f = this.generalForm.value;
      const { time } = f;

      if (!time) {
        return;
      }

      (_.last(this.materials).form as FormGroup).patchValue({
        time,
      });
    });
  }

  /**
   * handles save button
   */
  async doSave(andNew?: boolean) {
    // show error box if applicable
    this.attemptedSave = true;
    this.markTouched();

    // bail if form(s) invalid
    if (!this.canSave) {
      return false;
    }

    // try backend save
    try {
      const order = this._getOrderFromForms();

      this.isSaving = true;

      this._zone.run(() => {
        this.materials = [];
      });

      const { success, updatedOrder } = await this._orders.saveOrder(order);

      this.isSaving = false;

      if (success) {
        const action = '𝗦𝗮𝘃𝗲𝗱';

        this._doNotify(action);

        if (!this.order) {
          // mark lock as ours if new order
          this._setUnloadHook(updatedOrder.id);
        }

        if (!andNew) {
          this._loadExistingOrder(updatedOrder);
        } else {
          // nav to new form
          this._zone.run(() => {
            this.router.navigate(['/order-details']);
          });
        }
      }

      return success;
    } catch (e) {
      this.log.error(e);

      return false;
    }
  }

  /**
   * handles save and new button
   */
  doSaveAndNew() {
    this.doSave(true);
  }

  /**
   * checks forms statuses
   */
  isDirty() {
    const dirty = _.some(this.forms, c => c.dirty);

    // HACK: prevent changed after checked while saving new materials
    const next = this.isSaving ? false : dirty;

    //    if (next !== this._lastDirty) {
    //    this._updateDropdowns();
    // }

    this._lastDirty = next;

    return next;
  }

  /**
   * checks forms statuses
   */
  isValid() {
    return _.every(this.forms, c => c.valid);
  }

  /**
   * tracks materials
   */
  matTrack(_i: number, item: IMaterial) {
    return item.id;
  }

  /**
   * angular lifecycle hook
   */
  ngOnDestroy() {
    if (this.isEditing) {
      this.unload.unblockUnload();
      this.unload.doCallback();
      this.unload.clearCallback();
    }
    this.nav.restoreNav();
  }

  /**
   * angular lifecycle hook
   */
  ngOnInit() {
    this.initForms();
    _.defer(() => {
      this.nav.hideNav();
      this.header.clearAll(true);

      // for fabs
      new MDCRipple(document.querySelector('.mdc-fab'));
    });
  }

  /**
   * removes the ith material
   */
  async remove(i: number) {
    const saved = this._isSavedMaterial(this.materials[i]);

    if (!saved) {
      this.materials.splice(i, 1);
    } else {
      const order = this._getOrderFromForms();
      const material = order.materials[i];
      const { updatedOrder, success } = await this._orders.deleteMaterial(material.id);

      if (success) {
        this._loadExistingOrder(updatedOrder);
      }
    }
  }

  /**
   * handles to top fab. also used by history
   */
  scrollTo(x: unknown) {
    window.scrollTo(x);
  }

  /**
   * @ignore
   * handles email action. hook in case we need confirmation
   */
  private beginEmail() {
    this.doEmail();
  }

  /**
   * @ignore
   */
  private async doEmail() {
    const isLocked = await this._checkLock();

    if (!isLocked) {
      await this._orders.lockDocument(this.#item);
    } else {
      return this._doNotifyLocked('email');
    }

    try {
      const { success } = await this._orders.sendEmail(this.order?.id);

      if (success) {
        this._doNotifyEmail();
        this._releaseLock();
      }
    } catch (e) {
      this.log.error(e);
    }
  }

  /**
   * @ignore
   */
  private _doNotifyLocked(action: string) {
    this.isLocked = true;
    this._notify.notify('warning', 'Order is locked. Cannot  ' + action + '.');
  }

  /**
   * @ignore
   */
  private _doNotify(action: string) {
    this._notify.notify('success', 'Order has been ' + action + '.');
  }

  /**
   * @ignore
   */
  private _doNotifyEmail() {
    this._notify.notify('success', 'Email has been sent to customer');
  }

  /**
   * @ignore
   */
  private _doNotifyMaterial(i: number) {
    this._notify.notify('success', 'Material ' + (i + 1) + ' is confirmed');
  }

  /**
   * @ignore
   */
  private _formatDate(date: Date) {
    if (!_.isDate(date)) {
      return '01/01/1970';
    }

    return _.attempt(format, date, 'MM/dd/yyyy');
  }

  /**
   *
   */
  private async initForms() {
    await this.q.loadedPromise;

    this.generalForm = this.qcs.toFormGroup(_.flatten(this.q.generalQuestions));
    this.customerForm = this.qcs.toFormGroup(_.flatten(this.q.customerQuestions));
    this.form = new FormGroup({
      general: this.generalForm,
      customer: this.customerForm,
    });

    this.questionsLoaded = true;

    this._setFormValues();
  }

  /**
   * @ignore
   */
  private _isSavedMaterial(material: IMaterial) {
    return !material.id.startsWith('mat');
  }

  /**
   * @ignore
   */
  private _getOrderFromForms() {
    const materials = _.map(this.materials, (m, _i) => {
      const { form } = m;
      const value = form?.value || {};

      value.time = value?.time ? this.convertTimeFromForm(value?.time) : '';
      value.id = !this._isSavedMaterial(m) ? '' : m.id;

      return value;
    });
    const order = _.assign({ id: this.order?.id, materials },
      this.form.value.customer, this.form.value.general);

    order.date = this._formatDate(order.date);
    order.time = this.convertTimeFromForm(order.time);
    order.workType = order.workType?.key;
    order.shift = order.shift?.key;
    order.plantNumber = order.plant?.key + '';
    order.accountStatus = order.accountStatus?.key;

    return order;
  }

  /**
   * @ignore
   */
  private async _checkLock() {
    const res = await this._orders.isLocked(this.#item);
    const locked = res.locked && (res.user !== (await this.user.getName()));

    return this.isEditing ? false : locked;
  }

  /**
   * @ignore
   * handles route param
   */
  private async onEvent(routeParams: { item: string }) {
    const url = routeParams.item;

    if (!url) {
      this._zone.run(() => {
        this.router.navigate(['/order-details/new']);
      });

      return;
    }
    this.#item = url;

    await this.q.loadedPromise;

    if (url === 'new') {
      // do create
      _.defer(() => this._startNewOrder());
    } else {
      // do view
      this._loadExistingOrder();
    }
  }

  /**
   * @ignore
   */
  private async _releaseLock() {
    if (this.isEditing) {
      return;
    }
    return this._orders.unlockDocument(this.#item);
  }

  /**
   * @ignore
   */
  private async _loadExistingOrder(updatedOrder?: IOrder, asDraft?: boolean) {
    // load order
    this.order = updatedOrder || await this._orders.getOrderById(this.#item);

    // handle order not found
    if (!this.order) {
      this._zone.run(() => {
        this.router.navigate(['/response-code/404/Order not found']);
      });

      return;
    }

    // check locked state on loading
    if (!updatedOrder) {
      this.isLocked = await this._checkLock();
    }

    // create material forms
    const prematerials = _.clone(this.order.materials) as IExtendedMaterial[];

    _.each(prematerials, m => m.needsApproved = this.isSubmitted);
    _.each(prematerials, m => m.form = this.qcs.toFormGroup(_.flatten(this.q.materialsQuestions)));
    _.defer(() => { this.materials = prematerials });

    // parse order history
    this.parsedEditHistory = _.map(this.order.editHistory, event => {
      const s = event.split(' ');

      return {
        action: s[0],
        person: _.join([_.nth(s, -6), _.nth(s, -5)], ' '),
        date: _.join([_.nth(s, -3), _.nth(s, -2), ..._.nth(s, -1).split('.')], ' '),
      };
    });

    // parse email history
    this.parsedEmailHistory = this.order.emails;

    // parsed personnel
    this.parsedPersonnel = this.order.lindyPersonnel;

    if (asDraft) {
      this.isEditing = true;
    }

    // load data into forms and set editing to false
    await this._setFormValues();

    if (asDraft) {
      // pretend its draft and update dropdowns accordingly
      this.order = null;
    }

    // update dropdowns
    this._updateDropdowns();
  }

  /**
   * @ignore
   * mark all forms as touched
   */
  private markTouched() {
    // TODO: we should have a private copy of the question (maybe?)
    _.set(this.q.datetimeQuestion, 'untouched', false);

    this.form?.markAllAsTouched();
    _.each(this.materials, c => {
      c.form.markAllAsTouched();
    });
  }

  /**
   * @ignore
   * creates local id for unsaved materials
   */
  private newId() {
    return _.uniqueId('material_');
  }

  /**
   * @ignore
   */
  private async _setFormValues() {
    if (!this.order) {
      return;
    }

    try {
      await this._plant.loadedPromise;

      this.log.debug('setFormValues: phase 1');

      const gfc = _.keys(this.generalForm.controls);
      const cfc = _.keys(this.customerForm.controls);

      if (!gfc.length || !cfc.length) {
        //
      } else {
        this.log.debug('setFormValues: phase 2');

        // set forms
        this.generalForm?.setValue(this._orderToGeneralForm(this.order));
        this.customerForm?.setValue(this._orderToCustomerForm(this.order));

        this.log.debug('setFormValues: phase 3');

        return await this._setMaterialValues();
      }
    } catch (_e) {
      this.log.error(_e);
    }
  }

  /**
   * @ignore
   */
  private _setUnloadHook(_id?: string) {
    const id = _id || this.#item;

    this.unload.blockUnload();

    // NOTE: as of chrome 80 AJAX in onunload do not work
    this.unload.registerCallback(() => this._orders.unlockDocument(id));
  }

  /**
   * @ignore
   */
  private _orderToGeneralForm(order: IOrder) {
    this.log.debug('orderToGeneralForm');
    _.set(order, 'workType', { key: order.workType });

    return {
      plant: { key: +(order.plantNumber.split('.')[0]) },
      date: new Date(order.date),
      time: this.convertTimeToForm(order.time),
      shift: { key: order.shift },
      job: order.job,
      jobLocation: order.jobLocation,
      truckCount: order.truckCount,
      workType: order.workType,
      quote: order.quote,
      customerPO: order.customerPO,
    };
  }

  /**
   * @ignore
   */
  private async _setMaterialValues() {
    this.log.debug('begin setMaterialValues');
    const delay = (_time: number) => new Promise(res => _.defer(res)); // setTimeout(res, time));

    await delay(1000);

    const _controls = _.map(this.order.materials, (m, i) => {
      const { form } = this.materials[i];
      const c = _.keys(form);

      if (!c.length) {
        return false;
      }
      form.setValue(this._materialToForm(m));

      return true;
    });

    this.log.debug('update form status');
    _.map(this.forms, f => {
      if (!this.isEditing) {
        f.disable();
      } else {
        f.enable();
      }
      f.markAsPristine();
      f.markAsUntouched();
    });

    // disable loading state
    this.log.debug('set loading to false');
    this.loading = false;
  }

  /**
   * @ignore
   */
  private convertTimeToForm(time: string) {
    if (!_.isString(time)) {
      return null;
    }

    const s = time.split(':');
    const h = s[0].length === 2 ? s[0] : '0' + s[0];
    const m = s[1];

    return h + ':' + m;
  }

  /**
   * @ignore
   */
  private convertTimeFromForm(time: string) {
    if (!_.isString(time)) {
      return null;
    }

    const s = time.split(':');
    const h = +s[0];
    const m = +s[1];
    const suffix = h >= 12 ? 'PM' : 'AM';
    const mm = m;
    let hh = h;

    if (h === 0) {
      hh = 12;
    }
    if (h > 12) {
      hh -= 12;
    }

    return `${hh}:${mm}:00 ${suffix}`;
  }

  /**
   * @ignore
   */
  private _materialToForm(material: IMaterial) {
    const m = _.pick(material, [
      'comments', 'jmfNumber',
      'materialType', 'productCode',
      'time', 'tons',
      'truckCount',
    ]) as Record<string, unknown>;

    m.time = this.convertTimeToForm(m.time as string);

    return m;
  }

  /**
   * @ignore
   * handles close button
   */
  private onClose() {
    this.header.handleCloseNav();
  }

  /**
   * @ignore
   */
  private _orderToCustomerForm(order: IOrder) {
    const x = _.pick(order, [
      'customerCompanyName', 'customerCompanyNumber',
      'customerFirstName', 'customerLastName',
      'customerPhone', 'customerEmail',
      'accountStatus',
      'comments',
    ]) as Record<string, unknown>;

    x.accountStatus = { key: x.accountStatus };

    return x;
  }

  /**
   * @ignore
   */
  private _startNewOrder() {
    this.loading = true;
    _.defer(() => {
      this.loading = false;
      this.isEditing = true;
      this.order = null;
      this.materials = [];
      this._updateDropdowns();
    });
  }

  /**
   * @ignore
   */
  private _updateDropdowns() {
    const canApprove = this._lastCanApprove;

    this.isDesktopDevice = this.deviceService.isDesktop();

    // canapprove, isediting, isDirty

    if (this.isDesktopDevice) {
      // ensure only 2
      this.dropDownLinksTwo = [...this.desktopActions];
    } else {
      // add mobile actions
      this.dropDownLinksTwo = [...this.desktopActions, ...this.mobileActions];

      // approve
      this.dropDownLinksTwo[2].disabled = !canApprove || !(this.isSaved && !this.isApproved);

      // edit
      this.dropDownLinksTwo[3].disabled = !(this.isSaved && !this.isEditing && !this.isLocked);

      // save
      this.dropDownLinksTwo[4].disabled = !(!this.isSaved || this.isEditing) || !this._lastDirty;

      // save and new
      this.dropDownLinksTwo[5].disabled = !(!this.isSaved || this.isEditing) || !this._lastDirty;

      // copy order
      this.dropDownLinksTwo[6].disabled = !(!this.isEditing && (this.isApproved || this.isCancelled));
    }

    // set send email disabled state
    this.dropDownLinksTwo[0].disabled = !this.isApproved || !this.order.customerEmail || this.isLocked;

    // set cancel disabled state
    this.dropDownLinksTwo[1].disabled = this.isCancelled || !this.isSaved || this.isLocked;
  }
}
