import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TextboxQuestion } from '@pjd-development/pjd-dsc-lib';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.css']
})
export class TextboxComponent {

  @Input() form: FormGroup;
  @Input() textboxQuestion: TextboxQuestion;

  showRequiredStar = true;

  get isInvalid() {
    const c = this.control;

    return !c.valid && (c.dirty || c.touched);
  }

  get control() {
    return this.form.controls[this.textboxQuestion.key];
  }
}
