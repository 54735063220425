import {
  Component,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  AfterViewChecked,
  NgZone,
  Input,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, } from '@angular/router';
import * as _ from 'lodash-es';
import {
  IButton,
} from '@pjd-development/pjd-dsc-lib';

// local imports
import {
  IUserPreferences,
  // PreferencesService
} from '../preferences.service';
import {
  DatatableComponent, IActivateEvent,
} from '../datatable/datatable.component';
import { HeaderBarService } from '../header-bar.service';
import { UserService } from '../user.service';
import { TimeRangeService } from '../time-range.service';
import { NGXLogger } from 'ngx-logger';
import { IOrder, IOrderOptions, OrdersService } from '../orders.service';
import { IOrderList } from '../saved.service';
import { LodashService } from '../lodash.service';
import { TableColumn } from '@swimlane/ngx-datatable';

/**
 *
 */
@Component({
  selector: 'app-orders',
  providers: [DatePipe],
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements AfterViewInit, AfterViewChecked {

  /**
   * @ignore
   */
  @ViewChild('editTmpl', { static: true })
  private editTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('hdrTpl', { static: true })
  private hdrTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('voidTmpl', { static: true })
  private voidTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('numberTpl', { static: true })
  private numberTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('statusTpl', { static: true })
  private statusTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('timeTmpl', { static: true })
  private timeTmpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('checkboxTpl', { static: true })
  private checkboxTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('selectAllTpl', { static: true })
  private selectAllTpl: TemplateRef<unknown>;

  /**
   * @ignore
   */
  @ViewChild('datatable', { static: true })
  private datatable: DatatableComponent;

  invis = '⁣'; // NOTE: invisible character

  /**
   * rows in table
   */
  allRows: Array<IOrder>;

  /**
   * indicates if pdf is loading
   */
  loadingPdf: boolean;

  /**
   * buttons for edit columns modal
   */
  editColumnsButtons: Array<IButton>;

  /**
   * buttons for image modal
   */
  imageButtons: Array<IButton>;

  /**
   * multi select status
   */
  multi = false;

  /**
   * holder for disabled states
   */
  multiDisabled = {};

  /**
   * buttons for pdf modal
   */
  pdfButtons: Array<IButton>;

  /**
   * current selected row
   */
  selectedRow: unknown = {};

  /**
   * list of applied sorts
   */
  sorts = [
    {
      prop: 'PhysicalDate',
      dir: 'asc'
    },
  ];

  /**
   * current user prefs
   */
  userPrefs: IUserPreferences;

  /**
   * questions for search modal
   */
  questions: Array<Array<unknown>>;

  /**
   * @ignore
   */
  constructor(
    public lodash: LodashService,
    private cd: ChangeDetectorRef,
    private headerBar: HeaderBarService,
    private router: Router,
    private timeRangeService: TimeRangeService,
    private _orders: OrdersService,
    private userService: UserService,
    private log: NGXLogger,
    private _区: NgZone,
  ) {
    this.userPrefs = { autoUpdate: false };
  }

  /**
   * @ignore
   */
  private get currentTicketList() {
    return this.headerBar.currentTicketList;
  }

  /**
   * gets the route param from the router
   */
  @Input() set item(x: string) {
    this.onEvent({ item: x });
  }

  /**
   * Used by ngx-datatable to display the first part of a cell.
   *
   * @param s The string to be split.
   * @returns The first part of the string.
   */
  first(s: string) {
    return _.isString(s) ? s.split('\n')[0] : '';
  }

  /**
   * used to determine if cell is right or left aligned
   */
  isNumber(x: string) {
    return !x.includes(this.invis);
  }

  /**
   * Used by ngx-datatable to display the second part of a cell.
   *
   * @param s The string to be split.
   * @returns The second part of the string.
   */
  last(s: string) {
    return _.isString(s) ? s.split('\n')[1] : '';
  }

  /**
   *
   */
  createDefaultColumns() {
    const columns: Array<TableColumn> = [
      /*
            {
              name: 'Stat',
              sortable: false,
              width: 80,
              resizeable: false,
            },*/
      {
        name: 'Stat',
        prop: 'status',
        sortable: true,
        width: 52,
        resizeable: false,
      },
      //      { name: 'Ticket #', prop: 'Number', width: 80 },
      { prop: 'Plant', width: 100 },
      { prop: 'PhysicalDate', name: 'Date & Time', width: 100 },
      { name: 'Company', prop: 'customer', width: 275 },
      { name: 'Quote', prop: 'quote', width: 80 },
      { name: 'Job/Location', prop: 'order', width: 275 },
      { prop: 'trk', width: 50 },
      //    { prop: 'prod', width: 80 },
      { name: 'Product#', prop: 'products', width: 90 }, // product#
      { name: 'JMF#', prop: 'JMF', width: 90 },
      { name: 'Tons', prop: 'qty', width: 65 },
      { name: 'Total Tons', prop: 'Tons', width: 95 },
      { name: 'Total RAP', prop: 'TonsRAP', width: 90 },
    ];

    _.each(columns, (c) => {
      c.resizeable = false;
    });

    return columns;
  }

  /**
   * dynamic row heights
   */
  getRowHeight(row: IOrder) {
    if (!row) {
      return 72;
    }
    if (!row.materials) {
      return 72;
    }
    if (row.materials.length <= 3) {
      return 72;
    }

    return row.materials.length * 16 + 24;
  }

  /**
   * angular lifecycle hook
   */
  ngAfterViewChecked() {
    // HACK: prevent weird change after checked errors
    // something is broken somewhere, but who knows where?
    this.cd.detectChanges();
  }

  /**
   * Called after DOM is ready.
   */
  async ngAfterViewInit() {
    try {
      const columns = this.createDefaultColumns();

      this.userPrefs = {
        username: this.userService.getUsername(),
        columns,
      };
      this.#initColumns();
    } catch (e) {
      // probably not logged in, router will figure out and send to login page
      this.log.error(e, 'caught in ',
        OrdersComponent.name, this.ngAfterViewInit.name);
    }
  }

  /**
   * Handler for events from ngx-datatable.
   *
   * @param e The event dispatched from the datatable.
   */
  onActivate = (e: IActivateEvent) => {
    if (e.type === 'click' && _.get(e, 'cellIndex')) {
      const row = e.row as Record<string, string>;

      this.selectedRow = row;
      this._区.run(() => this.router.navigate(['/order-details/' + row.id]));
    }
  };

  /**
   * detect change in filter list
   */
  onListChanged(_list: IOrderList) {
    this.refreshData();
  }

  /**
   * handles updates to route parameters
   */
  onEvent(routeParams: { item: string }) {
    const url = routeParams.item;

    if (!url) {
      this._区.run(() => {
        this.router.navigate(['/orders/standard']);
      });

      return;
    }
    //  this.#item = url;

    //   this._initDropDownLinks();
  }

  onUpdateChange() {
    //
  }

  /**
   * Used by edit columns modal to display names of columns.
   *
   * @param  p The property name to be capitalized.
   * @returns The capitalized string.
   */
  prop(p: string) {
    return _.capitalize(p);
  }

  /**
   *
   */
  quickTransform(y: IOrder) {
    const invis = '⁣'; // NOTE: invisible character

    y.customer = y.customerCompanyNumber + '\n' + y.customerCompanyName;
    y.order = y.job + '\n' + y.jobLocation + invis;
    y.qty = _.join(_.map(y.materials, m => m.tons), '\n');

    return y;
  }

  /**
   * Performs a refresh of the table.
   */
  async refreshData() {
    const json: IOrderOptions = {} as IOrderOptions;
    const selectedPlants: Array<number> = this.currentTicketList.plants;
    const timestamp =
      this.timeRangeService.computeTimestamp(
        this.currentTicketList.timeRange);

    // prepare request body
    json.timestamp = timestamp;
    json.plant = selectedPlants;
    json.shift = this.currentTicketList.shift;
    json.status = this.currentTicketList.status;

    // clear table while loading
    this.datatable.loadingIndicator = true;
    this.datatable.allRows = [];

    try {
      const x = await this._orders.standardRequest(json);

      //      this.#allRows = _.clone(x);
      // this.allRows = _.reverse(_.sortBy(x, ['PhysicalDate']));
      this.allRows = x;
    } catch (e) {
      throw e;
    } finally {
      this.datatable.loadingIndicator = false;
    }
  }

  #initColumns = () => {
    const columns = this.userPrefs.columns;

    _.each(columns, (c, _i) => {
      if (_i === -1) {
        c.cellTemplate = this.checkboxTpl;
        c.headerTemplate = this.selectAllTpl;
      } else {
        const { prop } = c;
        const p = prop.toString();
        const p0 = p[0];
        const isUpperCase = !p0 || p0 === p0.toUpperCase();
        const isDate = _.includes(p, 'Date');
        const isQty = _.includes(p, 'qty');
        const isStat = _.includes(p, 'status');
        const isPlant = _.includes(p, 'lant');
        const isProducts = _.includes(p, 'products');
        const isProd = _.includes(p, 'prod');
        const isTrk = _.includes(p, 'trk');
        const isQuote = _.includes(p, 'uote');
        const isTotal = _.includes(p, 'Tons');
        const isJob = _.includes(p, 'rder');

        c.cellTemplate = isUpperCase ? this.editTmpl : this.hdrTpl;
        if (isPlant) {
          c.cellTemplate = this.hdrTpl;
        }
        if (isTrk) {
          c.cellTemplate = this.editTmpl;
        }
        if (isProd) {
          c.cellTemplate = this.editTmpl;
        }
        if (isProducts) {
          c.cellTemplate = this.editTmpl;
        }
        if (isDate) {
          c.cellTemplate = this.timeTmpl;
        }
        if (isQty) {
          c.cellTemplate = this.editTmpl;
        }
        if (isStat) {
          c.cellTemplate = this.statusTpl;
        }
        if (isQuote) {
          c.cellTemplate = this.editTmpl;
        }
        if (isTotal) {
          c.cellTemplate = this.numberTpl;
        }
        if (isJob) {
          c.cellTemplate = this.editTmpl;
        }
        if (c.prop === 'Number') {
          c.cellTemplate = this.voidTmpl;
        }
      }
    });

    const clone = _.clone(columns);

    this.datatable.columns = clone;
  };
}
