import { Injectable, isDevMode } from '@angular/core';
import * as _ from 'lodash-es';
import { environment } from '../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { BackendParams, BackendService } from './backend.service';

/**
 * @ignore
 */
const root = () => isDevMode() ? '' : environment.backendRoot;

/**
 * @ignore
 */
const ordersEndpoint = () => root() + '/api/orders';

/**
 * @ignore
 */
const orderEndpoint = () => root() + '/api/order';

/**
 * @ignore
 */
const materialEndpoint = () => root() + '/api/material';

/**
 * @ignore
 */
const emailEndpoint = () => root() + '/api/email';

/**
 * @ignore
 */
const plantScheduleEndpoint = () => root() + '/api/plantschedule';

/**
 * @ignore
 */
const sysDefaultsEndpoint = () => root() + '/api/system-defaults';

/**
 * @ignore
 */
const summaryReportEndpoint = () => root() + '/api/summary-report';

/**
 *
 */
const STATUSES = [
  // Draft (new Orders created by the Plant Managers)
  'Draft',
  // Submitted (Order Requests submitted from the Lindy Planning
  //   database and external customers)
  'Submitted',
  //  'In Review',
  'Approved',
  'Cancelled',
];

/**
 *
 */
const SHIFTS = [
  'All Shifts',
  'Day Only',
  'Night Only'
];

/**
 * @ignore
 */
const pad = (n: number) => {
  if (n < 10) {
    return '0' + n;
  }
  return n;
};

export interface ISummaryReportRequest {
  date: string; // "11/17/2023",
  shift: string; // "All",
  status: Array<string>; // ["Approved", "Submitted"],
  whichPlants?: string; // "Selected",
  selectedPlants?: Array<string>; // ["891.01 New Kensington", "993.01 Koppel"]

  /*
  Notes:
  The options for "shift" are "Day", "Night", or "All",
    The options for "status" are "Approved", "Submitted", "Draft", and "Cancelled".
    The options for "whichPlants" are "Selected", and "All".If the "All" option is sent,
    then the "selectedPlants" property is not necessary.
  */
}

export interface IPersonnel {
  title: string; // "title": "Foreman",
  firstName: string; // "firstName": "Ryan",
  lastName: string; // "lastName": "McKee",
  phone: string; // "phone": "(412) 894-6733",
  email: string; // "email": "ryan.mckee@lindypaving.com"
}

export interface IEmail {
  id: string;  // "id": "669875D1EA8CAC5585258AD1006947F2",
  time: string; // "time": "2/28/2024 2:09:56 PM",
  recipient: string[]; // "recipient": [
  //    "ryan.mckee@lindypaving.com",
  //  "Louis.Coppola@lindypaving.com",
  // "Jeff.Mckee@lindypaving.com"
  // ],
  subject: string; //"subject": "Approved Order from Lindy Paving  [DO NOT REPLY]",
  description: string; // "description": "Approval email sent on 02/28/2024 02:09:56 PM."
}

export interface IMaterial {
  comments: string;
  jmfNumber: string;
  materialType: string;
  productCode: string;
  rap: boolean;
  time: string; // 05:30:00 AM
  tons: number;

  // v5
  truckCount?: number;

  // V2
  id: string;
  reviewed?: string;
}

export interface ISystemDefaults {
  accountStatuses: string[];
  environment: string;
  rapProductCode: string;
  workTypes: string[];
}

/**
 * interface for returned orders
 */
export interface IOrder {
  customerCompanyName: string; // LINDY
  customerCompanyNumber: string; // 27106
  date: string; // 07/06/2022
  dateTime: string; // iso time
  job: string;
  jobLocation: string;
  materials: IMaterial[];
  plantName: string;
  plantNumber: string;
  productCount: number;
  quote: string;
  status: string;
  time: string; // 06:30:00 AM
  tons: number;
  tonsRAP: number;
  truckCount: number;

  // v2
  shift: string;
  editHistory: string[];

  // v3
  sortScore?: number;

  // v5
  customerFirstName?: string;
  customerLastName?: string;
  customerEmail?: string;
  customerPhone?: string;
  customerPO?: string;
  accountStatus?: string;
  workType?: string;
  comments?: string;
  emailSent?: string; // Array<string>;

  // V2
  id: string;
  emails: Array<IEmail>;
  operationUNID?: string;
  lindyPersonnel?: Array<IPersonnel>;

  // generated
  trk?: string;
  products?: string;
  customer?: string;
  // Customer: string;
  order?: string;
  qty?: string;
}

/**
 * Interface for calling orders endpoint.
 */
export interface IOrderOptions {
  timestamp: Array<unknown>; // sometimes date sometimes string
  plant?: Array<number>;
  shift?: string;
  status: string[];
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  /**
   * @ignore
   */
  private cachedSystemDefaults: Promise<ISystemDefaults>;

  constructor(
    private backend: BackendService,
    private log: NGXLogger,
  ) {
  }

  get statuses() {
    return _.clone(STATUSES);
  }

  get shifts() {
    return _.clone(SHIFTS);
  }


  getShiftIndex(shift: string) {
    return _.findIndex(this.shifts, s => s[4] === shift);
  }

  getStatusIndex(status: string) {
    return _.findIndex(this.statuses, s => s[0] === status);
  }

  /**
   * Utility function for looking up a status.
   *
   * @param s The value of the shift.
   * @returns The status object.
   */
  findStatus = (s: string) => ({ key: s[0], value: s, group: 'All Statuses' });

  /**
   * Utility function for looking up a shift.
   *
   * @param s The one character id of the shift.
   * @returns The shift object.
   */
  findShift = (s: string) => {
    const index = this.getShiftIndex(s);
    const shift = this.shifts[index] || this.shifts[0];

    return { key: shift[4], value: shift };
  };

  /**
   * for getting email
   */
  async getSystemDefaults() {
    if (!this.cachedSystemDefaults) {
      // prepare request
      this.cachedSystemDefaults =
        this.backend.genericGet(sysDefaultsEndpoint(), {}) as Promise<ISystemDefaults>;
    }

    return await this.cachedSystemDefaults;
  }

  /**
   * for getting email
   */
  async getEmail(id: string) {
    // prepare request
    return await this.backend.genericGet(emailEndpoint(), { id }) as Promise<{ html: string }>;
  }

  /**
   * for sending email
   */
  async sendEmail(id: string) {
    // prepare request
    const x = await this.backend.genericPost(emailEndpoint(), { id }) as { html: string };

    return _.attempt(JSON.parse, x.html) as { success: boolean };
  }

  /**
   * delete material
   */
  async deleteMaterial(id: string) {
    // prepare request
    const x = await this.backend.genericDelete(materialEndpoint(), { id }) as { html: string };

    return JSON.parse(x.html);
  }

  /**
   * saves order
   */
  async saveOrder(order: IOrder) {
    return this.postOrder(order);
  }

  /**
   * approves order
   */
  async approveOrder(order: IOrder) {
    _.each(order.materials, m => m.reviewed = 'Yes');

    return this.postOrder(order, 'approve');
  }

  /**
   * cancels order
   */
  async cancelOrder(order: IOrder) {
    return this.postOrder(order, 'cancel');
  }

  /**
   * submit order
   */
  async submitOrder(order: IOrder) {
    return this.postOrder(order, 'submit');
  }

  /**
   * for loading single order
   */
  async getOrderById(id: string) {
    // prepare request
    const x = await this.backend.genericGet(orderEndpoint(), { id }) as IOrder;

    // post processing
    this._postProcess(x);

    return x;
  }

  /**
   *
   */
  async standardRequest(json: IOrderOptions) {
    this.log.debug('orderopts', json);

    // tweak timestamp
    const dts = this._dateToString;
    const timeStamp = json.timestamp ? _.map(json.timestamp, dts) : null;
    const payload = _.set(json, 'timestamp', timeStamp);

    // prepare request
    const x = await this.backend.genericPost(ordersEndpoint(),
      this._sanitizeStandard(payload)) as IOrder[];

    // post processing
    _.each(x, y => this._postProcess(y));

    // filtering
    const shiftIndex = json.shift ? this.getShiftIndex(json.shift) : 0;
    let filtered = this.filterByShift(x, shiftIndex);

    if (json.status) {
      filtered = this.filterByStatus(filtered, json.status);
    }
    if (json.plant && json.plant.length) {
      filtered = this.filterByPlant(filtered, json.plant);
    }

    return filtered as IOrder[];
  }

  /**
   *
   */
  async generateSummaryReport(json: ISummaryReportRequest) {
    this.log.debug('orderopts', json);

    // prepare request
    const x = await this.backend.genericPost(summaryReportEndpoint(), {
      report: this._sanitizeSummaryReport(json),
    } as unknown as BackendParams);

    return x as { json?: string; success: boolean; error?: string };
  }

  /**
   * filters by status
   */
  filterByStatus(x: IOrder[], s: string[]) {
    if (!s.length) {
      return x;
    }

    return _.flatMap(s, ss => {
      const i = this.getStatusIndex(ss);
      const status = this.statuses[i];
      const f = _.filter(x, y => y.status === status);

      return f;
    });
  }

  /**
   * filters by plant
   */
  filterByPlant(x: IOrder[], p: number[]) {
    return _.filter(x, y => _.findIndex(p, pp => pp === +(y.plantNumber.split('.')[0])) !== -1);
  }

  /**
   * does shift filtering
   */
  filterByShift(results: IOrder[], shiftIndex: number) {
    this.log.debug('shift index is', shiftIndex);

    // night
    if (shiftIndex === 2) {
      return _.filter(results, r => !this.isDay(r));
    }
    // day
    if (shiftIndex === 1) {
      return _.filter(results, r => this.isDay(r));
    }

    // both
    return results;
  }

  /**
   * returns true if day shift
   */
  isDay(row: IOrder) {
    return row.shift === 'Day';
  }

  /**
   * for saving single material
   */
  async postMaterial(material: IMaterial) {
    // prepare request
    const x = await this.backend.genericPost(materialEndpoint(),
      { material } as unknown as BackendParams);

    return x as unknown; // { updatedOrder: IOrder; success: boolean; action: string };
  }

  /**
   * unlocks document
   */
  async unlockDocument(id: string) {
    return await this.genericGet('unlockDocument', id) as { success: boolean };
  }

  /**
   * locks document
   */
  async lockDocument(id: string) {
    return await this.genericGet('lockDocument', id) as { success: boolean };
  }

  /**
   * checks the locked status
   */
  async isLocked(id: string) {
    const res = await this.genericGet('isLocked', id);

    return res as { success: boolean; locked: boolean; user?: string };
  }

  /**
   * @ignore
   * for locking stuffs, or other new gets
   */
  private async genericGet(endpoint: string, id: string) {
    const res = await this.backend.genericGet(plantScheduleEndpoint(),
      { id, endpoint }) as { html: string };

    return JSON.parse(res.html);
  }

  /**
   * @ignore
   * for saving single order
   */
  private async postOrder(order: IOrder, action?: string) {
    // prepare request
    const x = await this.backend.genericPost(orderEndpoint(),
      { order, action } as unknown as BackendParams) as IOrder;

    // post processing
    this._postProcess(x);

    return x as unknown as { updatedOrder: IOrder; success: boolean; action: string };
  }

  /**
   * @ignore
   */
  private _sanitizeStandard(payload: IOrderOptions) {
    return _.omitBy(payload, p => _.isNil(p) || p.length === 0) as BackendParams;
  }

  /**
   * @ignore
   */
  private _dateToString(date: Date) {
    const r = date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      // 'T'
      ' ' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds());
    /* + '.' + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) + 'Z'*/

    return r;
  }

  /**
   * @ignore
   */
  private _postProcess(order: IOrder) {
    const y = order as unknown as Record<string, unknown>;
    const products = _.join(_.map(order.materials, m => m.productCode), '\n');
    const jmf = _.join(_.map(order.materials, m => m.jmfNumber), '\n');
    const invis = '⁣'; // NOTE: invisible character

    y.JMF = jmf + invis;
    y.products = products + invis;
    y.prod = products.split('\n').length + '';
    y.trk = y.truckCount + invis;
    // y.quote += invis;
    y.Plant = y.plantNumber + '\n' + y.plantName;
    y.PhysicalDate = y.dateTime;
    y.Tons = y.tons + '';
    y.TonsRAP = y.tonsRAP + '';

    // HACK: prevent app from dying while backend changes field names
    if (!y.userFirstName) {
      y.userFirstName = '';
    }
    if (!y.userLastName) {
      y.userLastName = '';
    }

    return y;
  }

  /**
   * @ignore
   */
  private _sanitizeSummaryReport(json: ISummaryReportRequest) {
    const { status, shift, selectedPlants } = json;

    if (!status.length) {
      json.status = this.statuses;
    }
    json.whichPlants = selectedPlants.length ? 'Selected' : 'All';
    json.shift = shift.split(' ')[0];

    return json;
  }
}
