import { Injectable, isDevMode } from '@angular/core';
import { environment } from '../environments/environment';
import * as _ from 'lodash-es';
import { BackendParams, BackendService } from './backend.service';

/**
 * @ignore
 */
const root = () => isDevMode() ? '' : environment.backendRoot;

/**
 * @ignore
 */
const prefsEndpoint = () => root() + '/api/user-prefs';

/**
 * @ignore
 */
const reportsEndpoint = () => root() + '/api/reports';

@Injectable({
  providedIn: 'root'
})
export class DynamodbService {

  constructor(
    private backend: BackendService,
  ) {
  }

  /**
   * User pref endpoint
   *
   * @param un The cognito username
   * @param d the timestamp of the object to get
   * @returns The userpref(s) requested.
   */
  async getUserPref(un: string, d?: number) {
    const data = {
      un,
    };
    if (d) {
      _.set(data, 'd', d);
    }

    return this.backend.genericGet(prefsEndpoint(), data);
  }

  /**
   * User pref endpoint
   *
   * @param data the data to put
   * @returns result
   */
  async putPref(data: unknown) {
    return this.backend.genericPut(prefsEndpoint(), { data } as BackendParams);
  }

  /**
   * reports endpoint
   *
   * @param un The cognito username
   * @param d the timestamp of the object to get
   * @returns The userpref(s) requested.
   */
  async getReports(un: string, d?: number) {
    const data = this._makeParams(un, d);

    return this.backend.genericGet(reportsEndpoint(), data);
  }

  /**
   * reports endpoint
   *
   * @param un The cognito username
   * @param d the timestamp of the object to get
   * @returns the result
   */
  async deleteReport(un: string, d: number) {
    const data = this._makeParams(un, d);

    return this.backend.genericDelete(reportsEndpoint(), data);
  }

  /**
   * report endpoint
   *
   * @param data the data to put
   * @returns result
   */
  async putReport(data: unknown) {
    return this.backend.genericPut(reportsEndpoint(), { data } as BackendParams);
  }

  /**
   * report endpoint
   *
   * @param data the data to post
   * @returns result
   */
  async postReport(data: unknown) {
    return this.backend.genericPost(reportsEndpoint(), { data } as BackendParams);
  }


  /**
   * @ignore
   */
  private _makeParams(un: string, d?: number) {
    const data = {
      un
    };

    if (d) {
      _.set(data, 'd', d);
    }

    return data;
  }
}
