import { Injectable, ErrorHandler, Inject } from '@angular/core';
import { environment } from '../environments/environment';
import { NotifierService } from 'angular-notifier';

import { Notifier } from '@airbrake/browser';
import * as _package from '../../package.json';
import * as _ from 'lodash-es';
import { UserService } from './user.service';
import { NGXLogger } from 'ngx-logger';
import { DOCUMENT } from '@angular/common';

const { airbrake } = environment;
const config = {
  projectId: airbrake.projectId,
  projectKey: airbrake.projectKey,
  environment: environment.environment,
};

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerServiceService implements ErrorHandler {

  // location missing in testing?
  // TODO: find better way to detect that
  isLocal = window.location.hostname === 'localhost' ||
    !window.location.hostname;

  private airbrake!: Notifier;

  constructor(
    private notifier: NotifierService,
    private userService: UserService,
    private log: NGXLogger,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.airbrake = this.isLocal ? null : new Notifier(config);
  }

  getMessage(error: Error & { prototype: { name: string } }) {
    return error?.message || (error?.prototype ? error.prototype?.name : 'Unknown Error Message');
  }

  handleError(error: Error & { code: string, prototype: { name: string } }) {
    const message = this.getMessage(error);

    // log the error to the console in non-prod envs
    this.log.debug('error caught by global handler');
    this.log.error(error);

    if (!message) {
      return;
    }

    this.log.debug(message);

    // attempt to auto refresh on missing token
    if (message === 'Local storage is missing an ID Token, Please authenticate' ||
      (_.isString(message) && message.includes('Local storage'))
      // || (_.isString(message.message) && message.message.startsWith('Access Token has expired'))
    ) {
      this.document.location.href = window.location.origin;

      return;
    }

    // always emit to user
    this.notifier.notify('error', message);

    // network error
    if (message === 'Network error') {
      return;
    }

    // password issue
    // if (_.isString(message.message) && message.message.startsWith('Password')) {
    // return;
    // }

    // wrong user
    if (error?.code === 'UserNotFoundException' || error?.name === 'UserNotFoundException') {
      return;
    }

    // no local airbrake
    if (!this.isLocal) {
      this.airbrake.notify({
        error,
        context: {
          version: _.get(_package, 'version'),
        },
        session: this.userService.getCurrentUser(),
      });
    }
  }
}
