import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { firstValueFrom } from 'rxjs';
import { BackendService } from './backend.service';
import { UserService } from './user.service';

const root = '';
const confirmForgotPasswordEndpoint = root + '/api/confirmForgotPassword';
const forgotPasswordEndpoint = root + '/api/forgotPassword';
const fcpEndpoint = root + '/api/forceChangePassword';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  message = `Password must be at least 12 characters, and
include uppercase, lowercase, and numbers.`;

  constructor(
    private http: HttpClient,
    private backend: BackendService,
    private user: UserService,
  ) { }

  /**
   * confirm forgot password
   */
  async confirmForgotPassword(email: string, code: string, password: string) {
    return await firstValueFrom(this.http.post(confirmForgotPasswordEndpoint, {
      email,
      password,
      code,
    }));
  }

  /**
   * force change password, needed for changing initial password
   */
  async forceChangePassword(cognitoUser: CognitoUser, password: string) {
    // TODO: check that session is correct? should be string, but seems like obj
    return await this.backend.genericPost(fcpEndpoint, {
      password,
      username: cognitoUser.getUsername(),
      /* eslint-disable  @typescript-eslint/naming-convention */

      // TODO: restore this
      Session: '', // (await this.user.getSession(cognitoUser)).,
      /* eslint-enable  @typescript-eslint/naming-convention */
    }, true);
  }

  /**
   * trigger forgot password email
   */
  async forgotPassword(email: string) {
    return firstValueFrom(this.http.post(forgotPasswordEndpoint, {
      email
    }));
  }

  /**
   *
   */
  validatePassword(_pass: string) {
    const pass = _pass || '';

    return pass.length >= 12 &&
      /\d/.test(pass) &&
      /[a-z]/.test(pass) &&
      /[A-Z]/.test(pass);
  }
}
