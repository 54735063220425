import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { DownloadService } from './download.service';
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor(
    private download: DownloadService
  ) {
    //
  }

  /**
   * triggers download of json as csv
   */
  generate(data: object[]) {
    const sanitized = [];

    _.each(data, (r) => {
      const keys = _.keys(r);
      let obj = null;

      _.remove(keys, k => k !== _.upperFirst(k));
      obj = _.pick(r, keys);
      sanitized.push(_.omitBy(obj, _.isNil));
    });
    if (sanitized.length) {
      const csv = this.convertToCSV(sanitized);
      const prefix = 'data:text/plain;base64,';
      const base64 = Base64.toBase64(csv);
      const url = prefix + base64;

      this.download.download(url, `${_.now()}.csv`);
    }
  }

  /**
   * @ignore
   * https://stackoverflow.com
   * /questions/8847766/how-to-convert-json-to-csv-format-and-store-in-a-variable
   */
  private convertToCSV(objArray: unknown[]) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    const newline = '\r\n';
    const keys = _.keys(array[0]);
    let str = '';

    // headers
    str += _.join(keys, ',') + newline;

    // rows
    _.each(_.range(array.length), (i) => {
      let line = '';

      _.each(keys, (index) => {
        if (line !== '') { line += ','; }

        line += array[i][index];
      });

      str += line + newline;
    });

    return str;
  }
}
