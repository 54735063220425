import {
  Component, Input,
  OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import * as shape from 'd3-shape';

import { colorSets, LineChartComponent, Tooltip } from '@swimlane/ngx-charts';
import * as _ from 'lodash-es';
import { IChartData } from '../chart.service';
import { addHours } from 'date-fns';

const chartGroups = [
  {
    name: 'Line/Area Charts',
    charts: [
      {
        name: 'Line Chart',
        selector: 'line-chart',
        inputFormat: 'multiSeries',
        options: [
          'animations',
          'colorScheme',
          'schemeType',
          'showXAxis',
          'showYAxis',
          'gradient',
          'showLegend',
          'legendTitle',
          'legendPosition',
          'showXAxisLabel',
          'xAxisLabel',
          'showYAxisLabel',
          'yAxisLabel',
          'autoScale',
          'timeline',
          'showGridLines',
          'curve',
          'rangeFillOpacity',
          'roundDomains',
          'tooltipDisabled',
          'showRefLines',
          'referenceLines',
          'showRefLabels',
          'xScaleMin',
          'xScaleMax',
          'yScaleMin',
          'yScaleMax',
          'trimXAxisTicks',
          'trimYAxisTicks',
          'rotateXAxisTicks',
          'maxXAxisTickLength',
          'maxYAxisTickLength'
        ],
        defaults: {
          yAxisLabel: 'Tons',
          xAxisLabel: 'Time',
          linearScale: true,
          roundDomains: true
        }
      },
    ]
  },
];

// const monthName = new Intl.DateTimeFormat('en-us', { month: 'short' });
// const weekdayName = new Intl.DateTimeFormat('en-us', { weekday: 'short' });

@Component({
  selector: 'app-chart',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./chart.component.css'],
  templateUrl: './chart.component.html'
})
export class ChartComponent implements OnInit {

  @Input() data: Array<IChartData>;
  @ViewChild('lineChart') private lineChart: LineChartComponent;
  theme = 'light'; // 'dark';
  chartType: string;
  chartGroups = chartGroups;
  chart: Record<string, unknown>;
  realTimeData = false;
  countries: unknown[];
  single: unknown[];
  multi: unknown[];
  fiscalYearReport: unknown[];
  dateData: unknown[];
  dateDataWithRange: unknown[];
  calendarData: unknown[];
  statusData: unknown[];
  sparklineData: unknown[];
  timelineFilterBarData: unknown[];
  graph: { links: unknown[]; nodes: unknown[] };
  bubble: unknown;
  linearScale = false;
  range = false;
  s = 'No Data Available';

  view: unknown[];
  width = 700;
  height = 300;
  fitContainer = true;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  tooltipDisabled = false;
  showText = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'GDP Per Capita';
  showGridLines = true;
  innerPadding = '10%';
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  showSeriesOnHover = true;
  roundEdges = true;
  animations = true;
  xScaleMin: unknown;
  xScaleMax: unknown;
  yScaleMin: number;
  yScaleMax: number;
  showDataLabel = false;
  noBarWhenZero = true;
  trimXAxisTicks = false;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;

  /* eslint-disable  @typescript-eslint/naming-convention */
  curves = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear
  };
  /* eslint-enable  @typescript-eslint/naming-convention */

  // line interpolation
  curveType = 'Monotone X'; // 'Catmull Rom';
  curve = this.curves[this.curveType];
  interpolationTypes = [
    'Basis',
    'Bundle',
    'Cardinal',
    'Catmull Rom',
    'Linear',
    'Monotone X',
    'Monotone Y',
    'Natural',
    'Step',
    'Step After',
    'Step Before'
  ];

  colorSets = colorSets;
  colorScheme: unknown;
  schemeType = 'ordinal';
  selectedColorScheme: string;
  rangeFillOpacity = 0.15;

  // Override colors for certain values
  customColors: unknown[] = [
    {
      name: 'Germany',
      value: '#a8385d'
    }
  ];

  // line, area
  autoScale = true;
  timeline = true;

  // margin
  margin = false;
  marginTop = 40;
  marginRight = 40;
  marginBottom = 40;
  marginLeft = 40;

  // Combo Chart
  // barChart: any[] = barChart;
  // lineChartSeries: any[] = lineChartSeries;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };

  showRightYAxisLabel = true;
  yAxisLabelRight = 'Utilization';

  // demos
  totalSales = 0;
  salePrice = 100;
  personnelCost = 100;

  mathText = '3 - 1.5*sin(x) + cos(2*x) - 1.5*abs(cos(x))';
  mathFunction: (o: unknown) => unknown;

  treemap: unknown[];
  treemapPath: unknown[] = [];
  sumBy = 'Size';

  // bubble chart interactive demo
  bubbleDemoTempData: unknown[] = [];

  // Reference lines
  showRefLines = true;
  showRefLabels = true;

  // Supports any number of reference lines.
  refLines = [
    { value: 42500, name: 'Maximum' },
    { value: 37750, name: 'Average' },
    { value: 33000, name: 'Minimum' }
  ];

  // data
  plotData: unknown;

  // Sidebar Controls:
  colorVisible = true;
  dataVisible = true;
  dimVisible = true;
  optsVisible = true;

  constructor(
  ) {
    _.set(window, 'chartComponent', this);
    // this.mathFunction = this.getFunction();

    // data goes here
    // this.dateData =
    // this.dateDataWithRange = generateData(2, true);

    this.setColorScheme('picnic');
    //   this.calendarData = this.getCalendarData();
    //    this.statusData = this.getStatusData();
    //   this.sparklineData = generateData(1, false, 30);
    //   this.timelineFilterBarData = timelineFilterBarData();
  }

  /**
   * main data linkage
   */
  get dateDataWithOrWithoutRange() {
    if (this.range) {
      return this.dateDataWithRange;
    } else {
      return this.dateData || this.data;
    }
  }

  ngOnInit() {
    this.selectChart('line-chart');

    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  applyDimensions() {
    this.view = [this.width, this.height];
  }

  select(data) {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  activate(data) {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  deactivate(data) {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  getInterpolationType(curveType) {
    return this.curves[curveType] || this.curves.default;
  }

  setColorScheme(name) {
    this.selectedColorScheme = name;
    this.colorScheme = this.colorSets.find(s => s.name === name);
  }

  onLegendLabelClick(entry) {
    console.log('Legend clicked', entry);
  }

  /**
   * default implementation of tooltip from ngx-charts
   */
  getToolTipText(tooltipItem: Tooltip): string {
    let result = '';

    if (tooltipItem.series !== undefined) {
      result += tooltipItem.series;
    } else {
      result += '???';
    }
    result += ': ';
    if (tooltipItem.value !== undefined) {
      result += tooltipItem.value.toLocaleString();
    }
    if (tooltipItem.min !== undefined || tooltipItem.max !== undefined) {
      result += ' (';
      if (tooltipItem.min !== undefined) {
        if (tooltipItem.max === undefined) {
          result += '≥';
        }
        result += tooltipItem.min.toLocaleString();
        if (tooltipItem.max !== undefined) {
          result += ' - ';
        }
      } else if (tooltipItem.max !== undefined) {
        result += '≤';
      }
      if (tooltipItem.max !== undefined) {
        result += tooltipItem.max.toLocaleString();
      }
      result += ')';
    }

    return result;
  }

  onFilter(event) {
    console.log('timeline filter', event);
  }

  onSelect(event) {
    console.log(event);
  }

  /**
   * custom formatting for x axis
   */
  xAxisTickFormatting = (val: Date) => {
    if (!this.lineChart) {
      return '';
    }

    const scale = this.lineChart.xScale;
    const tickArguments = [5];
    const f = scale.tickFormat(...tickArguments); // .apply(scale, tickArguments);

    // HACK: fix date object timezone
    const d = addHours(val, 4);

    return f(d);
  };

  /**
   * @ignore
   */
  private selectChart(chartSelector) {
    this.chartType = chartSelector = chartSelector.replace('/', '');

    for (const group of this.chartGroups) {
      this.chart = group.charts.find(x => x.selector === chartSelector);
      if (this.chart) {
        break;
      }
    }

    this.linearScale = false;
    this.yAxisLabel = 'GDP Per Capita';
    this.xAxisLabel = 'Country';

    this.width = 700;
    this.height = 300;

    Object.assign(this, this.chart.defaults);

    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }
}
