import { Component, Input } from '@angular/core';
import { LodashService } from '../lodash.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  @Input() dropDownQuestion;
  @Input() form;

  constructor(
    public lodash: LodashService,
  ) {

  }

  get isInvalid() {
    const c = this.control;

    return !c.valid && (c.dirty || c.touched);
  }

  get control() {
    return this.form.controls[this.dropDownQuestion.key];
  }
}
