import { Injectable, isDevMode } from '@angular/core';
import * as _ from 'lodash-es';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { TableColumn } from '@swimlane/ngx-datatable';
import { DynamodbService } from './dynamodb.service';

export interface IUserPreferences {
  username?: string;
  columns?: Array<TableColumn>;
  autoUpdate?: boolean;
  _version?: number;
}

// this needs incremented to auto update
const CURRENT_VERSION = 2;

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  private _devMode: boolean;

  constructor(
    // private log: NGXLogger,
    private dynamo: DynamodbService,
  ) {
    this._devMode = isDevMode();
  }

  get currentVersion() {
    return CURRENT_VERSION;
  }

  get devMode() {
    return this._devMode;
  }

  /**
   * finds preferences for user
   */
  async find(user: CognitoUser) {
    if (!user) {
      throw new Error('no user found');
    }

    const partition = user.getUsername();
    const result = await this.dynamo.getUserPref(partition);

    if (_.isArray(result)) {
      return null;
    }

    return result;
  }

  /**
   * for updating preferences
   */
  async insertOrReplace(data: IUserPreferences) {
    data.columns = this.sanitizeColumns(data.columns);

    return await this.dynamo.putPref(data);
  }

  /**
   * logic for checking if prefs are outdated
   */
  isPrefOutdated(pref: IUserPreferences) {
    if (this.devMode || pref._version !== this.currentVersion) {
      return true;
    }

    return false;
  }

  /**
   * @ignore
   */
  private sanitizeColumns(cols: Array<TableColumn>) {
    return _.map(cols, col => _.pick(col, 'name prop resizeable width'.split(' ')));
  }
}
