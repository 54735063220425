// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  //  backendRoot: 'https://ltwv-development.roket.tech',
  backendRoot: 'https://ticketing.lindypaving.com',

  logLevel: NgxLoggerLevel.TRACE,

  // PROD for now
  poolData: {
    UserPoolId: 'us-east-2_gHG6FqhI7',
    ClientId: '15gff4an9vd8vtjrsg0bs0u4rs'
  },

  // STAGING when ready
  /*
    poolData: {
    UserPoolId: 'us-east-2_YeOgdBBWE', // Your user pool id here
    ClientId: '1663qgftsvn6jejah4vsum02c6' // Your client id here
    },
  */

  airbrake: {
    projectId: 207164,
    projectKey: 'bf5d457ab3c7b098ea1907de5bd7da30',
  },
  environment: 'test',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
